import React, { useState, useEffect } from 'react';
import { InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import RenderPeriodDateRange from './RenderPeriodDateRange';
import { isSameDay } from 'date-fns';

const PeriodSelect = ({ reportingPeriods, selectedPeriods, onSetPeriod, isCompare = false }) => {
    const [period, setPeriod] = useState('');

    useEffect(() => {
        if (period === '') {
            // this would normally be the initial state, but it caused a circular re-render

            const index = isCompare ? 1 : 0;

            const currentlySelectedPeriod = (selectedPeriods.length === 0 || (selectedPeriods.length === 1 && isCompare))
                ? ''
                : reportingPeriods.find(e => isSameDay(new Date(e[0]), new Date(selectedPeriods[index][0]))) || '';

            setPeriod(currentlySelectedPeriod);

            return;
        }

        period && onSetPeriod(period);
    }, [period]);

    return (
        <FormControl size="small" margin="none">
            <InputLabel
                id="period-select-label"
                sx={period === '' ? {
                    fontSize: '0.875rem',
                    lineHeight: 1.5,
                    '&.MuiInputLabel-shrink': {
                        fontSize: '1rem'
                    }
                } : undefined}
            >
                Choose Reporting Period
            </InputLabel>
            <Select
                labelId="period-select-label"
                value={period}
                label="Choose Reporting Period"
                onChange={event => setPeriod(event.target.value)}
                size="small"
                margin="none"
                sx={{
                    minWidth: 210
                }}
                slotProps={{
                    input: {
                        sx: {
                            fontSize: '0.875rem'
                        }
                    }
                }}
            >
                {reportingPeriods.map((e, i) => (
                    <MenuItem
                        dense
                        key={i}
                        value={e}
                    >
                        <RenderPeriodDateRange
                            value1={e[0]}
                            value2={e[1]}
                        />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default PeriodSelect;