import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { ListItemButton } from '@mui/material';

const PseudoGroup = ({ text, count, icon, setSelectedItem, selectedItem, onLoad }) => {
    const [isLoading, setIsLoading] = useState(!Boolean(onLoad));

    useEffect(() => {
        if (count) {
            setIsLoading(false);
        }
    }, [count]);

    const RenderCount = () => {
        if (Boolean(onLoad)) {
            if (isLoading) {
                return <CircularProgress size={14} />;
            }

            if (!count) {
                return (
                    <Link
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsLoading(true);
                            onLoad();
                        }}
                        sx={{ cursor: 'pointer' }}
                    >
                        Load
                    </Link>
                );
            }
        }

        return count.toLocaleString();
    };

    return (
        <ListItem disablePadding>
            <ListItemButton
                dense
                selected={selectedItem}
                onClick={setSelectedItem}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >
                    <ListItemAvatar sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Avatar sx={{ my: 0.5 }}>
                            {icon}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={(
                            <Typography variant="subtitle2">
                                {text}
                            </Typography>
                        )}
                        secondary={(
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <PersonIcon sx={{ fontSize: 16, mr: 0.5 }} />
                                <Typography variant="subtitle2">
                                    <RenderCount />
                                </Typography>
                            </Box>
                        )}
                    />
                </Box>
            </ListItemButton>

        </ListItem>
    );
};

export default PseudoGroup;
