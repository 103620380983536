import React, { useEffect, useState, useRef } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import ReactECharts from 'echarts-for-react';
import usePrevious from '../../hooks/usePrevious';

const ChannelContentOverviewChart = ({ a, b, periodColours }) => {
    const ref = useRef(null);
    const theme = useTheme();
    const [series, setSeries] = useState(null);
    const prevSeries = usePrevious(series);
    const padding = theme.spacing(3);
    const chartHeight = 400;

    const options = {
        grid: {
            containLabel: true,
            left: padding,
            top: padding,
            right: padding,
            bottom: padding
        },
        xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
        },
        yAxis: {
            type: 'category',
            data: ['Emails Sent', 'Landing Pages Published', 'Surveys Published', 'Active QR Codes'],
            inverse: true
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        series
    };

    useEffect(() => {
        let series = [
            {
                name: 'Period A',
                type: 'bar',
                data: [
                    a.totalEmailsForPeriod,
                    a.totalPagesForPeriod,
                    a.totalSurveysForPeriod,
                    a.totalQRCodesForPeriod
                ],
                color: periodColours[0]
            }
        ];

        if (b) {
            const bData = {
                name: 'Period B',
                type: 'bar',
                data: [
                    b.totalEmailsForPeriod,
                    b.totalPagesForPeriod,
                    b.totalSurveysForPeriod,
                    b.totalQRCodesForPeriod
                ],
                color: periodColours[1]
            };

            series.push(bData);
        }

        setSeries(series);
    }, [a, b]);

    useEffect(() => {
        // replaceMerge param is necessary to remove the 2nd dataset

        if (ref?.current && series && (series.length < prevSeries?.length)) {
            ref.current?.getEchartsInstance().setOption(options, { replaceMerge: ['series'] });
        }
    }, [series]);

    return (
        <ReactECharts
            option={options}
            style={{ height: chartHeight }}
            opts={{ renderer: 'svg' }}
            ref={ref}
        />
    );
};

export default ChannelContentOverviewChart;