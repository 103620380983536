import React, { useState, useEffect } from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import { Paper, Typography } from '@mui/material';
import { pink, cyan } from '@mui/material/colors';
import NewDashboard from './NewDashboard';
import Emails from './Emails';
import NewEmail from './NewEmail';
import Pages from './Pages';
import NewPage from './NewPage';
import TitleBar from './TitleBar';
import WebFont from 'webfontloader';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import useApi from '../hooks/useApi';
import useAccount from '../hooks/useAccount';
import usePrevious from '../hooks/usePrevious';
import { startOfDay } from 'date-fns';

const NewReports = () => {
    const { path } = useRouteMatch();
    const location = useLocation();
    const { handleGet } = useApi();
    const [fonts, setFonts] = useState(null);
    const [selectedPeriods, setSelectedPeriods] = useState([]);
    const prevSelectedPeriods = usePrevious(selectedPeriods);
    const [compare, setCompare] = useState(false);
    const [tab, setTab] = useState('');
    const { user, reportingPeriods } = useAccount();
    const prevReportingPeriods = usePrevious(reportingPeriods);
    const periodColours = [pink[600], cyan[600]];

    const hasManageReportsPermission = user.permissions.includes('Manage Reports');

    const handleFetchCustomFonts = async () => {
        const response = await handleGet('fonts');

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setFonts(data);
    };

    const handleLoadFonts = font => {
        if (font.type === 'google') {
            WebFont.load({
                google: {
                    families: [font.name]
                }
            });
        }
    };

    const handleSetCurrentPeriod = () => {
        const today = startOfDay(new Date());

        const currentPeriod = reportingPeriods.find(e => e[0] <= today && e[1] >= today);

        setSelectedPeriods([currentPeriod, currentPeriod]);
    };

    useEffect(() => {
        (fonts && fonts.length > 0) && fonts.forEach((font) => handleLoadFonts(font));
    }, [fonts]);

    useEffect(() => {
        if (prevReportingPeriods !== reportingPeriods) {
            setSelectedPeriods([]);
            setCompare(false);
            handleSetCurrentPeriod();
        }
    }, [reportingPeriods]);

    useEffect(() => {
        handleSetCurrentPeriod();

        if (hasManageReportsPermission) {
            handleFetchCustomFonts();
        }
    }, []);

    if (!hasManageReportsPermission) {
        return (
            <Paper sx={{ padding: 6, textAlign: 'center' }}>
                <Typography>You don't have permission to manage Reports.</Typography>
                <Typography>Please contact your account administrator to have this enabled.</Typography>
            </Paper>
        );
    }

    if (!fonts) {
        return <LoadingOverlay />;
    }

    return (
        <>
            <TitleBar
                reportingPeriods={reportingPeriods}
                selectedPeriods={selectedPeriods}
                onSetSelectedPeriods={setSelectedPeriods}
                compare={compare}
                onSetCompare={setCompare}
                periodColours={periodColours}
                tab={tab}
                onSetTab={setTab}
                path={path}
                location={location}
            />
            <Switch>
                <Route exact path={`${path}`}>
                    <NewDashboard
                        selectedPeriods={selectedPeriods}
                        prevSelectedPeriods={prevSelectedPeriods}
                        compare={compare}
                        periodColours={periodColours}
                    />
                </Route>
                <Route exact path={`${path}/emails`}>
                    <Emails
                        reportingPeriods={reportingPeriods}
                        selectedPeriods={selectedPeriods}
                        prevSelectedPeriods={prevSelectedPeriods}
                        compare={compare}
                        periodColours={periodColours}
                    />
                </Route>
                <Route exact path={`${path}/emails/:id`}>
                    <NewEmail />
                </Route>
                <Route exact path={`${path}/pages`}>
                    <Pages
                        reportingPeriods={reportingPeriods}
                        selectedPeriods={selectedPeriods}
                        prevSelectedPeriods={prevSelectedPeriods}
                        compare={compare}
                    />
                </Route>
                <Route exact path={`${path}/pages/:id`}>
                    <NewPage />
                </Route>
            </Switch>
        </>
    );
};

export default NewReports;