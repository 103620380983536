import React, { useState, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import useSnackbar from '../hooks/useSnackbar';
import useApi from '../hooks/useApi';
import useAccount from '../hooks/useAccount';

const ManualList = ({ emailAddresses, setEmailAddresses, emailAddressesLimit = null }) => {
    const [value, setValue] = useState('');
    const [isValidating, setIsValidating] = useState(false);
    const [input, setInput] = useState(null);
    const { showSnackbar } = useSnackbar();
    const { handleGet } = useApi();
    const { isInternalComms, allowedRecipients } = useAccount();

    const onRefChange = useCallback(node => setInput(node), []);

    const handleValidateEmail = async value => {
        //html5 form validate pattern
        const isValidPattern = input.checkValidity();

        //pattern / empty string check
        if (value === '' || !isValidPattern) {
            showSnackbar('Please enter a valid email address', 'error');
            return null;
        }

        const emailAddress = value.toLowerCase();

        //check for match
        const match = emailAddresses.find(e => e.toLowerCase() === emailAddress);

        if (match) {
            showSnackbar('Duplicate email address not added', 'warning');
            return null;
        }

        // allowed recipients pattern match?
        if (isInternalComms && allowedRecipients.length > 0) {
            const mailbox = emailAddress.trim();
            const domain = mailbox.split('@')[1];

            const matches = allowedRecipients.filter(e => e.type === 'mailbox' ? mailbox === e.pattern ? true : false : domain === e.pattern ? true : false);

            if (matches.length === 0) {
                showSnackbar('You can only add recipients whose email address adheres to one of the allowed patterns in your account.', 'error');
                setValue('');
                return;
            }
        }

        setIsValidating(true);

        let isValid = false;

        //check if email is unsubscribed or suppressed
        const response = await handleGet(`contacts/email/${emailAddress}`);

        //if found, check if unsub/suppressed
        if (response.status === 200) {
            const contact = await response.json();

            if (contact.isSuppressed === false && contact.isUnsubscribed === false) {
                isValid = true;
            }
            else {
                showSnackbar(`${emailAddress} cannot be sent to. They might be unsubscribed or suppressed`, 'error');
            }
        }
        else if (response.status === 404) {
            //if 404, all good
            isValid = true;
        }
        else if (response.status === 400) {
            const json = await response.json();
            showSnackbar(json.message, 'error');
        }
        else {
            showSnackbar('An error occurred, pelase try again', 'error');
        }

        setValue('');
        setIsValidating(false);

        input.focus();

        if (isValid) {
            return emailAddress;
        }

        return null;
    };

    const handleAddEmails = newEmailAddresses => {
        setEmailAddresses([...emailAddresses, ...newEmailAddresses]);
    };

    const handleSubmit = async ({ event, value }) => {
        event && event.preventDefault();

        const email = event ? event.target.value : value;

        const validEmail = await handleValidateEmail(email);

        validEmail && handleAddEmails([validEmail]);
    };

    const handleRemoveEmailAddress = email => {
        setEmailAddresses(emailAddresses.filter(e => e !== email));
    };

    const handlePaste = async event => {
        //get data from clipboard and split by common delimiters
        //comma space semi-colon tab line-break
        const data = (event.clipboardData || window.clipboardData)
            .getData('text')
            .toLowerCase()
            .split(/,| |;|\t|\r\n/)
            .filter(e => e !== '');

        //validate by running each value through the input
        const validEmails = [];

        for (let i = 0; i < data.length; i++) {
            setValue(data[i]);
            const validEmail = await handleValidateEmail(data[i]);
            validEmail && validEmails.push(validEmail);
        }

        validEmails.length > 0 && handleAddEmails(validEmails);
    };

    const onTheLimit = emailAddressesLimit ? emailAddresses.length === emailAddressesLimit : false;
    const exceededLimit = emailAddressesLimit ? emailAddresses.length > emailAddressesLimit : false;
    const limitDifference = emailAddresses.length - emailAddressesLimit;
    const disabled = isValidating || onTheLimit || exceededLimit;

    return (
        <>
            <TextField
                error={exceededLimit}
                disabled={disabled}
                inputRef={onRefChange}
                label={exceededLimit ? `Limit exceeded, please remove ${limitDifference} address${limitDifference > 1 ? 'es' : ''}` : 'Click here to add email address'}
                type="email"
                fullWidth
                value={value}
                onChange={event => setValue(event.target.value)}
                onKeyPress={event => (event.key === 'Enter' || event.key === ',' || event.key === ' ' || event.key === ';') && handleSubmit({ event })}
                onPaste={event => handlePaste(event)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button
                                onClick={() => handleSubmit({ value: input.value })}
                                disabled={disabled}
                            >
                                {isValidating ? <CircularProgress size={16} /> : 'Add'}
                            </Button>
                        </InputAdornment>
                    )
                }}
                margin="none"
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderBottomStyle: 'dashed'
                        }
                    }
                }}
            />
            <TextField
                disabled
                fullWidth
                multiline
                margin="none"
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderTop: 0
                        }
                    }
                }}
                InputProps={{
                    startAdornment: (
                        <>
                            {
                                emailAddresses.map(e => (
                                    <InputAdornment
                                        key={e}
                                        position="start"
                                        sx={{
                                            height: 'unset',
                                            marginBottom: 1
                                        }}
                                    >
                                        <Chip label={e} onDelete={() => handleRemoveEmailAddress(e)} />
                                    </InputAdornment>
                                ))
                            }
                        </>
                    ),
                    sx: {
                        flexWrap: 'wrap'
                    }
                }}
            />
        </>
    );
};

export default ManualList;