import React from 'react';
import Chip from '@mui/material/Chip';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';

const Tag = ({ name, colour, className = null, onClick, onDelete }) => {
    const defaultTheme = createTheme();

    const theme = createTheme({
        palette: {
            neutral: defaultTheme.palette.augmentColor({
                color: {
                    main: colour || '#EEE'
                }
            })
        }
    });

    const handleMouseDown = (event) => {
        event.stopPropagation();
    };

    if (!colour) {
        return (
            <Chip
                label={name}
                className={className}
                clickable={Boolean(onClick)}
                onClick={onClick}
                onMouseDown={handleMouseDown}
                size="small"
                icon={<SellOutlinedIcon fontSize="small" />}
                onDelete={onDelete}
            />
        );
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Chip
                    label={name}
                    className={className}
                    clickable={Boolean(onClick)}
                    onClick={onClick}
                    onMouseDown={handleMouseDown}
                    size="small"
                    color="neutral"
                    icon={<SellOutlinedIcon fontSize="small" />}
                    onDelete={onDelete}
                />
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default Tag;