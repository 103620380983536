import React, { useState, useEffect } from 'react';
import { Box, List, ListItemText, Collapse, Typography, Divider, useTheme, useMediaQuery, Drawer, IconButton } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import ListItemButton from '@mui/material/ListItemButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../msalConfig';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import TeamsSendContentDialog from '../teams/TeamsSendContentDialog';
import useApi from '../../hooks/useApi';
import useSnackbar from '../../hooks/useSnackbar';
import useAccount from '../../hooks/useAccount';
import MessageCard from '../teams/MessageCard';
import UserAvatar from '../../UserAvatar';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import AppBar from '@mui/material/AppBar';
import NavigationContainer from '../../navigation/NavigationContainer';
import { v4 as uuidv4 } from 'uuid';
import TeamsFullScreenDialog from '../teams/TeamsFullScreenDialog';
import Tooltip from '@mui/material/Tooltip';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import TeamsSummary from '../teams/TeamsSummary';
import DisconnectDialog from '../teams/DisconnectDialog';
import useTour from '../../hooks/useTour';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const NoTeams = ({ handleLogin, handleRunTour }) => {
    return (
        <Paper sx={{ py: 4, textAlign: 'center', display: 'flex', justifyContent: 'center' }} square>
            <Box
                className="teams-login"
                sx={{
                    display: 'inline-block',
                    textAlign: 'center'
                }}
            >
                <Typography>Click below to connect to your organisation's MS Teams.</Typography>
                <Button
                    startIcon={<VerifiedIcon />}
                    sx={{ mt: 1 }}
                    onClick={handleLogin}
                >
                    Connect to MS Teams
                </Button>
                <IconButton sx={{ mt: 1, ml: 2 }} onClick={() => handleRunTour({ path: 'teams-login-tour' })}>
                    <HelpOutlineIcon />
                </IconButton>
            </Box>
        </Paper>
    );
};

const NoMessage = ({ setShowAssignContentDialog }) => {
    return (
        <Paper sx={{ padding: 6, textAlign: 'center' }} square>
            <Typography>You have not posted to this Teams channel.</Typography>
            <Typography>Click below to get started.</Typography>
            <Button
                sx={{ mt: 2 }}
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setShowAssignContentDialog(true)}
            >
                New Post
            </Button>
        </Paper>
    );
};

const Teams = () => {
    const { instance, accounts } = useMsal();
    const [isAuthorized, setIsAuthorized] = useState(false);
    const { userPermissions, handleRefresh } = useAccount();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const [openTeams, setOpenTeams] = useState({});
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [teams, setTeams] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showAssignContentDialog, setShowAssignContentDialog] = useState(false);
    const { handleGet, handlePost } = useApi();
    const { showSnackbar } = useSnackbar();
    const [messages, setMessage] = useState([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [noConfiguredTeam, setNoConfiguredTeam] = useState(false);
    const [teamsFullScreenDialog, setTeamsFullScreenDialog] = useState(false);
    const [showDisconnectDialog, setShowDisconnectDialog] = useState(false);
    const { handleRunTour } = useTour();

    const fetchTeams = async () => {
        try {
            setIsLoading(true);

            const response = await handleGet('teams?status=Active');

            if (!response.ok) {
                console.error('Failed to fetch teams');
                return;
            }

            const data = await response.json();

            const teamsWithChannels = data.reduce((acc, item) => {
                let team = acc.find(team => team.teamsId === item.teamsId);

                if (!team) {
                    team = {
                        name: item.teamsName,
                        teamsId: item.teamsId,
                        channels: []
                    };
                    acc.push(team);
                }

                team.channels.push({
                    connectorId: item.id,
                    id: item.channelId,
                    createdDateTime: item.created,
                    displayName: item.channelName,
                    description: item.channelName
                });

                return acc;
            }, []);

            if (teamsWithChannels.length > 0) {
                setTeams(teamsWithChannels);
                setNoConfiguredTeam(false);
            }
            else {
                setNoConfiguredTeam(true);
            }

            setIsLoading(false);
        }
        catch (error) {
            console.error('Error loading teams');
            setIsLoading(false);
        }
    };

    const handleDialogClose = async () => {
        setTeamsFullScreenDialog(false);
        await fetchTeams();
    };

    const handleLogin = async () => {
        try {
            const response = await instance.loginPopup(loginRequest);
            const accessToken = response.accessToken;

            fetchTeams(accessToken);
        }
        catch (e) {
            console.error(e);
        }
    };

    const handleLogout = async () => {
        instance.logoutPopup().catch(e => {
            console.error(e);
        });

        setShowDisconnectDialog(false);
        await handleRefresh();
    };

    const handleTeamClick = (team) => {
        setOpenTeams((prevOpen) => ({
            ...prevOpen,
            [team]: !prevOpen[team]
        }));
    };

    const handleChannelClick = async (team, channel) => {
        setIsLoading(true);

        const response = await handleGet(`teams/${team.teamsId}/channel/${channel.id}/messages`);

        if (!response.ok) {
            showSnackbar('Failed to load message', 'error');
            setIsLoading(false);
            return;
        }

        const data = await response.json();
        setMessage(data);

        setSelectedChannel({ teamId: team.teamsId, teamName: team.name, channel: channel });
        setIsLoading(false);
    };

    const handleSendContent = async (campaign, type, item, formData) => {
        setIsLoading(true);
        const attachmentId = uuidv4();

        const url = type === 'survey' ? campaign.copyLink
            : type === 'email' ? campaign.shareLink
                : type === 'page' ? campaign.viewLink
                    : null;

        let finalUrl = url ? `${url}${url.includes('?') ? '&' : '?'}type=teams` : null;

        const requestUrl = `https://graph.microsoft.com/beta/teams/${selectedChannel.teamId}/channels/${selectedChannel.channel.id}/messages`;

        const tokenResponse = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        });

        const accessToken = tokenResponse.accessToken;

        try {
            const teamsPingResponse = await fetch(requestUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!teamsPingResponse.ok) {
                showSnackbar('You do not have permission to send messages in this channel. Contact the channel owner.', 'error');
                setIsLoading(false);
                return;
            }

            const backendResponse = await handlePost(`teams/${selectedChannel.teamId}/channel/${selectedChannel.channel.id}/messages`, {
                connectorId: selectedChannel.channel.connectorId,
                type,
                shareLink: finalUrl,
                cardTitle: formData.title,
                cardSubtitle: formData.subtitle,
                cardImage: formData.image,
                cardBody: formData.body,
                ...(type === 'survey' ? { surveyId: campaign.id }
                    : type === 'email' ? { campaignId: campaign.id }
                        : type === 'page' ? { landingPageId: campaign.id }
                            : {})
            });

            if (backendResponse.ok) {
                const updatedShareLink = await backendResponse.json();

                const message = {
                    subject: null,
                    body: {
                        contentType: 'html',
                        content: `<attachment id='${attachmentId}'></attachment>`
                    },
                    attachments: [{
                        id: attachmentId,
                        contentType: 'application/vnd.microsoft.card.adaptive',
                        contentUrl: null,
                        content: JSON.stringify({
                            '$schema': 'http://adaptivecards.io/schemas/adaptive-card.json',
                            'type': 'AdaptiveCard',
                            'version': '1.2',
                            'body': [
                                {
                                    'type': 'Container',
                                    'items': [
                                        {
                                            'type': 'ColumnSet',
                                            'columns': [
                                                {
                                                    'type': 'Column',
                                                    'width': 'auto',
                                                    'items': [
                                                        {
                                                            'type': 'Image',
                                                            'url': formData.image,
                                                            'size': 'Medium'
                                                        }
                                                    ]
                                                },
                                                {
                                                    'type': 'Column',
                                                    'width': 'stretch',
                                                    'items': [
                                                        {
                                                            'type': 'TextBlock',
                                                            'text': formData.title,
                                                            'weight': 'Bolder'
                                                        },
                                                        {
                                                            'type': 'TextBlock',
                                                            'text': formData.subtitle,
                                                            'isSubtle': true,
                                                            'wrap': true
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            'type': 'TextBlock',
                                            'text': `Message: ${formData.body}`,
                                            'wrap': true
                                        }
                                    ]
                                }
                            ],
                            'actions': [
                                {
                                    'type': 'Action.OpenUrl',
                                    'title': 'View Content',
                                    'url': updatedShareLink.shareLink
                                }
                            ]
                        }),
                        name: null,
                        thumbnailUrl: null
                    }]
                };

                const teamsResponse = await fetch(requestUrl, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(message)
                });

                if (!teamsResponse.ok) {
                    showSnackbar('Error sending content to teams channel', 'error');
                    setIsLoading(false);
                    return;
                }

                const team = { teamsId: selectedChannel.teamId, name: selectedChannel.teamName };
                await handleChannelClick(team, selectedChannel.channel);
                showSnackbar('Content sent successfully', 'success');
            }
            else {
                showSnackbar('Error while sending content', 'error');
            }
        }
        catch (error) {
            showSnackbar('Error occurred while sending content', 'error');
        }
        finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const canManageTeams = userPermissions.includes('Manage Teams');

        if (canManageTeams) {
            setIsAuthorized(true);
        }
    }, [userPermissions]);

    useEffect(() => {
        const fetchData = async () => {
            if (accounts.length > 0) {
                try {
                    const response = await instance.acquireTokenSilent({
                        ...loginRequest,
                        account: accounts[0]
                    });
                    const accessToken = response.accessToken;
                    await fetchTeams(accessToken);
                }
                catch (error) {
                    console.error(error);
                }
            }
        };

        fetchData();
    }, [accounts, instance]);

    useEffect(() => {
        if (teams.length > 0) {
            setOpenTeams({ [teams[0].name]: true });
        }
    }, [teams]);

    if (!isAuthorized) {
        return (
            <Paper sx={{ padding: 6, textAlign: 'center' }} square>
                <Typography>You don't have permission to access MS Teams.</Typography>
                <Typography>Please contact your account administrator to have this enabled.</Typography>
            </Paper>
        );
    }

    if (accounts.length === 0) {
        return <NoTeams handleLogin={handleLogin} handleRunTour={handleRunTour} />;
    }

    if (isLoading) {
        return <LoadingOverlay />;
    }

    return (
        <>
            {noConfiguredTeam ? (
                <Paper sx={{ py: 4, textAlign: 'center', display: 'flex', justifyContent: 'center' }} square>
                    <Box
                        className="add-teams"
                        sx={{ display: 'inline-block', textAlign: 'center' }}
                    >
                        <Typography>You have not configured any teams yet.</Typography>
                        <Typography>Click below to get started.</Typography>
                        <Button sx={{ mt: 1 }} variant="contained" startIcon={<AddIcon />} onClick={() => setTeamsFullScreenDialog(true)}>
                            Add teams
                        </Button>
                        <IconButton sx={{ mt: 1, ml: 2 }} onClick={() => handleRunTour({ path: 'add-teams-dialog-tour' })}>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Box>
                </Paper>
            ) : (
                <Box sx={{ display: 'flex', height: 'calc(100vh - 137px)' }}>
                    {isSmallScreen ? (
                        <Drawer
                            className="full-screen-left-drawer"
                            anchor="left"
                            open={drawerOpen}
                            onClose={() => setDrawerOpen(false)}
                            PaperProps={{
                                sx: { width: 350, top: 64 }
                            }}
                        >
                            <List
                                sx={{ width: 350, bgcolor: 'background.paper' }}
                                component="nav"
                                subheader={(
                                    <>
                                        <ListSubheader component="div" sx={{ height: 64, display: 'flex', alignItems: 'center' }}>
                                            <Typography variant="h6" sx={{ flexGrow: 1 }}>Teams</Typography>
                                            <Tooltip title="Configure" arrow>
                                                <IconButton onClick={() => setTeamsFullScreenDialog(true)}>
                                                    <AddIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Disconnect" arrow>
                                                <IconButton onClick={() => setShowDisconnectDialog(true)}>
                                                    <PowerOffIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </ListSubheader>
                                        <Divider />
                                    </>
                                )}
                            >
                                {teams.map((team) => (
                                    <div key={team.id}>
                                        <ListItemButton sx={{ mt: 1 }} dense onClick={() => handleTeamClick(team.name)}>
                                            <ListItemIcon>
                                                <UserAvatar name={team.name} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="button">{team.name}</Typography>
                                            </ListItemText>
                                            {openTeams[team.name] ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                        <Collapse in={openTeams[team.name]} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {team.channels.map((channel) => (
                                                    <ListItemButton
                                                        dense={true}
                                                        key={channel.id}
                                                        sx={{ ml: 6, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                                        onClick={() => {
                                                            handleChannelClick(team, channel);
                                                            setDrawerOpen(false);
                                                        }}
                                                        selected={selectedChannel && selectedChannel.teamName === team.name && selectedChannel.channel.id === channel.id}
                                                    >
                                                        <ListItemText sx={{ flexGrow: 1 }}>
                                                            <Typography variant="subtitle2">{channel.displayName}</Typography>
                                                        </ListItemText>
                                                        {channel.membershipType === 'private' && (
                                                            <ListItemIcon sx={{ minWidth: 'auto' }}>
                                                                <LockIcon fontSize="small" />
                                                            </ListItemIcon>
                                                        )}
                                                    </ListItemButton>
                                                ))}
                                            </List>
                                        </Collapse>
                                    </div>
                                ))}
                            </List>
                        </Drawer>
                    ) : (
                        <>
                            <NavigationContainer width={400} top={137}>
                                <Box className="full-screen-left-drawer">
                                    <AppBar
                                        position="sticky"
                                        color="inherit"
                                        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    >
                                        <Toolbar sx={{ px: 2, display: 'flex' }} disableGutters>
                                            <Typography sx={{ flexGrow: 1 }} variant="h6">Teams</Typography>
                                            <Tooltip title="Summary" arrow>
                                                <IconButton onClick={() => setSelectedChannel(null)} className="summary-icon">
                                                    <ClearAllIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Configure" arrow>
                                                <IconButton onClick={() => setTeamsFullScreenDialog(true)} className="configure-icon">
                                                    <AddIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Disconnect" arrow>
                                                <IconButton onClick={() => setShowDisconnectDialog(true)} className="disconnect-icon">
                                                    <PowerOffIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Help" arrow>
                                                <IconButton onClick={() => handleRunTour({ path: 'teams-left-pannel' })}>
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Toolbar>
                                    </AppBar>
                                    <List
                                        sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper' }}
                                        component="nav"
                                    >
                                        {teams.length > 0 && teams.map((team) => (
                                            <div key={team.id}>
                                                <ListItemButton sx={{ mt: 1 }} dense onClick={() => handleTeamClick(team.name)}>
                                                    <ListItemIcon>
                                                        <UserAvatar name={team.name} />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <Typography variant="button">{team.name}</Typography>
                                                    </ListItemText>
                                                    {openTeams[team.name] ? <ExpandLess /> : <ExpandMore />}
                                                </ListItemButton>
                                                <Collapse in={openTeams[team.name]} timeout="auto" unmountOnExit>
                                                    <List component="div" disablePadding>
                                                        {team.channels.map((channel) => (
                                                            <ListItemButton
                                                                dense={true}
                                                                key={channel.id}
                                                                sx={{ ml: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                                                onClick={() => handleChannelClick(team, channel)}
                                                                selected={selectedChannel && selectedChannel.teamName === team.name && selectedChannel.channel.id === channel.id}
                                                            >
                                                                <ListItemText sx={{ flexGrow: 1 }}>
                                                                    <Typography variant="subtitle2">{channel.displayName}</Typography>
                                                                </ListItemText>
                                                                {channel.membershipType === 'private' && (
                                                                    <ListItemIcon sx={{ minWidth: 'auto' }}>
                                                                        <LockIcon fontSize="small" />
                                                                    </ListItemIcon>
                                                                )}
                                                            </ListItemButton>
                                                        ))}
                                                    </List>
                                                </Collapse>
                                            </div>
                                        ))}
                                    </List>
                                </Box>
                            </NavigationContainer>
                        </>
                    )}
                    <Box sx={{ width: '100%', overflowY: 'auto' }}>
                        {selectedChannel ? (
                            <>
                                <AppBar
                                    position="sticky"
                                    color="inherit"
                                    sx={{ top: 0 }}
                                >
                                    <Toolbar>
                                        {isSmallScreen && (
                                            <IconButton
                                                edge="start"
                                                color="inherit"
                                                onClick={() => setDrawerOpen(true)}
                                                sx={{ mr: 2 }}
                                            >
                                                <MenuIcon />
                                            </IconButton>
                                        )}
                                        <Typography variant="subtitle2" color="text.primary" sx={{ fontSize: '18px', flexGrow: 1 }}>
                                            {selectedChannel.channel.displayName}
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            startIcon={<AddIcon />}
                                            onClick={() => setShowAssignContentDialog(true)}
                                            className="new-post-button"
                                        >
                                            New Post
                                        </Button>
                                        <Tooltip title="Help" arrow>
                                            <IconButton onClick={() => handleRunTour({ path: 'teams-post-pannel' })}>
                                                <HelpOutlineIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Toolbar>
                                </AppBar>
                                <Box sx={{ marginTop: 2, px: 3, alignContent: 'center', width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    {messages.length > 0 ? (
                                        messages.map((message, index) => (
                                            <MessageCard key={index} message={message} />
                                        ))
                                    ) : (
                                        <NoMessage setShowAssignContentDialog={setShowAssignContentDialog} />
                                    )}
                                </Box>
                            </>
                        ) : (
                            <>
                                <AppBar
                                    position="sticky"
                                    color="inherit"
                                    sx={{ top: 0 }}
                                >
                                    <Toolbar>
                                        {isSmallScreen && (
                                            <IconButton
                                                edge="start"
                                                color="inherit"
                                                onClick={() => setDrawerOpen(true)}
                                                sx={{ mr: 2 }}
                                            >
                                                <MenuIcon />
                                            </IconButton>
                                        )}
                                        <Typography component="h1" variant="h6" sx={{ flexGrow: 1 }}>Teams Summary</Typography>
                                        <Tooltip title="Help" arrow>
                                            <IconButton onClick={() => handleRunTour({ path: 'teams-summary-pannel' })}>
                                                <HelpOutlineIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Toolbar>
                                </AppBar>
                                <TeamsSummary />
                            </>
                        )}
                    </Box>
                </Box>
            )}
            {showAssignContentDialog && (
                <TeamsSendContentDialog
                    onClose={() => setShowAssignContentDialog(false)}
                    onSubmit={handleSendContent}
                    item={selectedChannel}
                />
            )}
            {teamsFullScreenDialog && (
                <TeamsFullScreenDialog
                    open={teamsFullScreenDialog}
                    handleClose={handleDialogClose}
                />
            )}
            {showDisconnectDialog && (
                <DisconnectDialog
                    open={showDisconnectDialog}
                    onClose={() => setShowDisconnectDialog(false)}
                    handleLogout={handleLogout}
                />
            )}
        </>
    );
};

export default Teams;