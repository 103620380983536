import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const DisconnectDialog = ({ open, onClose, handleLogout }) => {
    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>Disconnect Teams</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ marginBottom: 2 }}>
                    Are you sure you want to disconnect NewZapp from your Teams?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleLogout}>
                    Yes, I'm Sure
                </Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DisconnectDialog;