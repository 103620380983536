import React, { useState, useEffect, useRef } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CampaignsTable from './CampaignsTable';
import PagesTable from './PagesTable';
import useApi from '../hooks/useApi';
import { colours } from '../theme/constants';
import Chart from 'chart.js/auto';
import { useLocation, useHistory } from 'react-router-dom';

const MonthlySendsChart = () => {
    const [months, setMonths] = useState();
    const canvas = useRef();
    const { handleGet } = useApi();

    const handleBuildChart = () => {

        const data = {
            labels: months.map(u => u.label),
            datasets: [{
                label: 'Emails Sent',
                data: months.map(u => u.total),
                backgroundColor: colours.secondary
            }]
        };

        const options = {
            plugins: {
                legend: {
                    display: false
                }
            },
            maintainAspectRatio: false,
            scales: {
                y: {
                    ticks: {
                        beginAtZero: true,
                        precision: 0
                    }
                }
            }
        };

        new Chart(canvas.current, {
            type: 'bar',
            data,
            options
        });
    };

    const handleLoadMonthlySends = async () => {
        const response = await handleGet('reports/sends/monthly');
        const data = await response.json();
        setMonths(data);
    };

    useEffect(() => {
        handleLoadMonthlySends();
    }, []);

    useEffect(() => {
        months && handleBuildChart();
    }, [months]);

    return (
        <Box sx={{ padding: 3, height: 300 }}>
            <canvas ref={canvas} />
        </Box>
    );
};

const Dashboard = () => {
    const location = useLocation();
    const history = useHistory();

    const queryParams = new URLSearchParams(location.search);
    const initialTab = queryParams.get('tab') || 'email';
    const [type, setType] = useState(initialTab);

    const handleChangeType = (e, value) => {
        setType(value);

        const params = new URLSearchParams(location.search);
        params.set('tab', value);
        history.push({ pathname: '/reports', search: params.toString() });
    };

    return (
        <Box sx={{ overflow: 'hidden' }}>
            <Paper sx={{ margin: 2 }}>
                <Toolbar variant="dense" sx={{ px: 2 }}>
                    <Typography component="h2" variant="subtitle2">Send History</Typography>
                </Toolbar>
                <Divider />
                <MonthlySendsChart />
            </Paper>
            <Paper sx={{ margin: 2 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={type} onChange={handleChangeType}>
                        <Tab label="Emails" value="email" />
                        <Tab label="Landing Pages" value="page" />
                    </Tabs>
                </Box>
                {type === 'email' ? <CampaignsTable /> : <PagesTable />}
            </Paper>
        </Box>
    );
};

export default Dashboard;