import { eachDayOfInterval, eachMonthOfInterval, eachQuarterOfInterval, addDays, addMonths, addQuarters, addYears, isBefore, set, getDate, getMonth, getYear, isAfter, startOfDay, isEqual } from 'date-fns';

const createWeeklyPeriods = (start, origin) => {
    let firstYearOrigin = set(new Date(start), { month: getMonth(new Date(origin)), date: getDate(new Date(origin)) });

    if (isBefore(start, firstYearOrigin)) {
        firstYearOrigin = set(new Date(firstYearOrigin), { year: getYear(new Date(firstYearOrigin)) - 1 });
    }

    let yearStart = new Date(firstYearOrigin);
    let yearStarts = [];

    do {
        yearStarts.push(yearStart);
        yearStart = addYears(new Date(yearStart), 1);
    }
    while (isBefore(yearStart, new Date()));

    let periods = [];

    yearStarts.forEach(e => {
        const yearStart = new Date(e);
        const yearEnd = addDays(addYears(new Date(yearStart), 1), -1);

        const allDays = eachDayOfInterval({ start: yearStart, end: yearEnd });

        let i = 0;
        let yearPeriods = [];

        while (i < allDays.length && yearPeriods.length < 13) {
            // make the last period bigger and account for leap years
            const sliceSize = yearPeriods.length <= 12 ? 28 : allDays.length === 365 ? 29 : 30;

            const period = allDays.slice(i, i + sliceSize);

            periods.push(period);
            yearPeriods.push(period);

            i = i + sliceSize;
        }
    });

    const periodStartsAndEnds = periods.map(e => [e[0], e[e.length - 1]]);

    return periodStartsAndEnds;
};

const createMonthlyPeriods = (start, end) => {
    const periodStarts = eachMonthOfInterval({ start, end });

    const periodStartsAndEnds = periodStarts.map(start => {
        const startOfNextPeriod = addMonths(start, 1);
        const end = addDays(startOfNextPeriod, - 1);

        return [start, end];
    });

    return periodStartsAndEnds;
};

const createQuarterlyPeriods = (start, end) => {
    const periodStarts = eachQuarterOfInterval({ start, end });

    const periodStartsAndEnds = periodStarts.map(start => {
        const startOfNextPeriod = addQuarters(start, 1);
        const end = addDays(startOfNextPeriod, - 1);

        return [start, end];
    });

    return periodStartsAndEnds;
};

const generatePeriods = (reportingPeriod, accountStartDate, includeAllFourWeeklyPeriods = false) => {
    const today = startOfDay(new Date());

    const type = reportingPeriod?.type ?? 'monthly';

    const origin = reportingPeriod?.startDate ? startOfDay(new Date(reportingPeriod.startDate)) : today;

    const start = startOfDay(new Date(accountStartDate));

    let toOrigin = [];
    let fromOrigin = [];

    switch (type) {
        case '4weekly':
            toOrigin = createWeeklyPeriods(start, origin);

            if (!includeAllFourWeeklyPeriods) {
                toOrigin = toOrigin.slice(toOrigin.findIndex(e => isAfter(new Date(e[0]), start)) - 1, toOrigin.findIndex(e => isAfter(new Date(e[0]), today)));
            }

            break;

        case 'quarterly':
            toOrigin = createQuarterlyPeriods(start, origin);

            if (origin < today) {
                fromOrigin = createQuarterlyPeriods(origin, today);
            }

            break;

        //case 'monthly'
        default:
            toOrigin = createMonthlyPeriods(start, origin);

            if (origin < today) {
                fromOrigin = createMonthlyPeriods(origin, today);
            }
    }

    const periods = [...toOrigin, ...fromOrigin];

    const uniquePeriods = periods.filter((a, i, arr) => arr.findIndex(b => (isEqual(b[0], a[0]))) === i);

    const sortedPeriods = uniquePeriods.sort((a, b) => b[0] - a[0]);

    return sortedPeriods;
};

export {
    generatePeriods,
    createWeeklyPeriods,
    createMonthlyPeriods,
    createQuarterlyPeriods
};