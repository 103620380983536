import React, { useState, useEffect } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import buildUrl from '../buildUrl';
import useApi from '../hooks/useApi';
import NoRows from '../dataTable/NoRows';
import usePrevious from '../hooks/usePrevious';

const rowsPerPageOptions = [10, 20, 50];

const columns = [
    { field: 'name', headerName: 'Name', orderDesc: false, type: 'string', flex: 0.75 },
    { field: 'title', headerName: 'Page Title', orderDesc: false, type: 'string', flex: 1 },
    { field: 'publishedDateTime', headerName: 'Publish Date', orderDesc: true, type: 'dateTime', flex: 0.25, valueGetter: ({ value }) => value && new Date(value) }
];

const PageSelect = ({ onSelect, currentlySelected }) => {
    const { handleGet } = useApi();
    const [landingPages, setLandingPages] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [orderDesc, setOrderDesc] = useState(true);
    const [orderBy, setOrderBy] = useState('publishedDateTime');
    const [isLoading, setIsLoading] = useState(true);
    const [selectedIds, setSelectedIds] = useState([]);
    const prevRowsPerPage = usePrevious(rowsPerPage);
    const prevOrderBy = usePrevious(orderBy);
    const prevOrderDesc = usePrevious(orderDesc);
    const prevPage = usePrevious(page);

    const handleSelectPage = (e, page) => {
        e.preventDefault();
        onSelect(page);
    };

    const handleFetchPages = async () => {
        const params = {
            skip: page * rowsPerPage,
            take: rowsPerPage,
            orderBy,
            isDescending: orderDesc,
            published: true
        };

        const url = buildUrl('pages', params);

        const response = await handleGet(url);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const data = await response.json();

        setLandingPages(data);
        setIsLoading(false);
    };

    const handleFetchPagesCount = async () => {
        const url = buildUrl('pages/count');

        const response = await handleGet(url);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const count = await response.json();

        setCount(count);
    };

    const handleSort = props => {
        if (props?.length === 0) {
            return;
        }

        const { field, sort } = props[0];

        if (field === orderBy) {
            setOrderDesc(!orderDesc);
        }
        else {
            setOrderDesc(sort === 'desc');
            setOrderBy(field);
        }
    };

    useEffect(() => {
        if (currentlySelected && landingPages.length > 0) {
            setSelectedIds([currentlySelected.id]);
        }
    }, [landingPages, currentlySelected]);

    useEffect(() => {
        handleFetchPagesCount();
    }, []);

    useEffect(() => {
        if (orderBy !== prevOrderBy ||
            orderDesc !== prevOrderDesc ||
            rowsPerPage !== prevRowsPerPage) {
            setPage(0);
            setIsLoading(true);
        }
        else if (page !== prevPage) {
            setIsLoading(true);
        }
    }, [orderBy, orderDesc, rowsPerPage, page]);

    useEffect(() => {
        if (isLoading) {
            handleFetchPages();
            handleFetchPagesCount();
        }
    }, [isLoading]);

    const sort = orderDesc ? 'desc' : 'asc';

    return (
        <DataGridPro
            autoHeight
            disableColumnMenu
            disableColumnFilter
            disableMultipleColumnsSorting
            disableMultipleSelection
            hideFooterSelectedRowCount
            pagination
            paginationMode="server"
            page={page}
            sortingMode="server"
            rows={landingPages}
            rowCount={count}
            rowHeight={32}
            columns={columns}
            loading={isLoading}
            pageSize={rowsPerPage}
            onPageSizeChange={rowsPerPage => setRowsPerPage(rowsPerPage)}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageChange={page => setPage(page)}
            onSortModelChange={props => handleSort(props)}
            onRowClick={(value, e) => handleSelectPage(e, value.row)}
            selectionModel={selectedIds}
            sortingOrder={['desc', 'asc']}
            initialState={{
                sorting: {
                    sortModel: [{ field: orderBy, sort }]
                }
            }}
            components={{
                NoRowsOverlay: NoRows
            }}
            sx={{
                '& .MuiDataGrid-cell:focus': { outline: 'none' }
            }}
        />
    );
};

export default PageSelect;