import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ListItemText, Menu, MenuItem, ListItemIcon, AppBar, Tab, Tabs, Stack } from '@mui/material';
import { CalendarMonth, Settings, Email, Web, Insights } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import ReportingPeriod from '../account/reportingPeriod/ReportingPeriod';
import FullScreenSettingsDialog from '../shared/FullScreenSettingsDialog';
import DatesSelector from './DatesSelector';

const SettingsMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState(null);
    const open = Boolean(anchorEl);

    const handleDialogOpen = (menuItem) => {
        setIsDialogOpen(true);
        setSelectedMenuItem(menuItem);
        setAnchorEl(null);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        setAnchorEl(null);
        setSelectedMenuItem(null);
    };

    const menuItems = {
        managePeriod: { label: 'Reporting Period', icon: <CalendarMonth fontSize="small" />, component: <ReportingPeriod onClose={handleDialogClose} /> }
    };

    return (
        <>
            <IconButton
                onClick={(event) => setAnchorEl(event.currentTarget)}
                variant="text"
            >
                <Settings />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                {Object.keys(menuItems).map((menuItem) => (
                    <MenuItem
                        key={menuItem}
                        onClick={() => handleDialogOpen(menuItem)}
                    >
                        <ListItemIcon>
                            {menuItems[menuItem].icon}
                        </ListItemIcon>
                        <ListItemText>{menuItems[menuItem].label}</ListItemText>
                    </MenuItem>
                ))}
            </Menu>
            {isDialogOpen && (
                <FullScreenSettingsDialog
                    handleDialogClose={handleDialogClose}
                    title={menuItems[selectedMenuItem]?.label}
                >
                    {menuItems[selectedMenuItem]?.component}
                </FullScreenSettingsDialog>
            )}
        </>
    );
};

const TitleBar = ({ reportingPeriods, selectedPeriods, onSetCompare, onSetSelectedPeriods, compare, periodColours, tab, onSetTab, path, location }) => {
    const isItem = Boolean(location.pathname.match('\\d'));

    return (
        <AppBar
            position="sticky"
            color="inherit"
            elevation={tab === '' ? 4 : 0}
            sx={{
                top: 64,
                display: 'flex',
                flexDirection: 'row'
            }}
        >
            <Tabs
                value={tab}
                onChange={(e, value) => onSetTab(value)}
            >
                <Tab
                    component={Link}
                    to={`${path}`}
                    icon={isItem ? undefined : <Insights />}
                    iconPosition="start"
                    label="Overview"
                    value=""
                />
                <Tab
                    component={Link}
                    to={`${path}/emails`}
                    icon={isItem ? undefined : <Email />}
                    iconPosition="start"
                    label="Emails"
                    value="emails"
                />
                <Tab
                    component={Link}
                    to={`${path}/pages`}
                    icon={isItem ? undefined : <Web />}
                    iconPosition="start"
                    label="Landing Pages"
                    value="pages"
                />
            </Tabs>
            {!isItem && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        flexGrow: 1,
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        pr: 1
                    }}
                >
                    <DatesSelector
                        reportingPeriods={reportingPeriods}
                        selectedPeriods={selectedPeriods}
                        onSetSelectedPeriods={onSetSelectedPeriods}
                        compare={compare}
                        onSetCompare={onSetCompare}
                        periodColours={periodColours}
                    />
                    <SettingsMenu />
                </Stack>
            )}
        </AppBar>
    );
};

export default TitleBar;