/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../msalConfig';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CardActions from '@mui/material/CardActions';
import UserAvatar from '../../UserAvatar';
import Collapse from '@mui/material/Collapse';
import LinearProgress from '@mui/material/LinearProgress';
import Checkbox from '@mui/material/Checkbox';
import AlertBar from '../../alertBar/AlertBar';
import useApi from '../../hooks/useApi';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import useSnackbar from '../../hooks/useSnackbar';
import Drawer from '@mui/material/Drawer';
import useTheme from '@mui/material/styles/useTheme';
import Grid from '@mui/material/Grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import useTour from '../../hooks/useTour';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TeamsFullScreenDialog = ({ open, handleClose }) => {
    const { instance, accounts } = useMsal();
    const [isLoading, setIsLoading] = useState(true);
    const [isChannelLoading, setIsChannelLoading] = useState(false);
    const [teams, setTeams] = useState([]);
    const [channels, setChannels] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [originalConfiguredChannels, setOriginalConfiguredChannels] = useState([]);
    const { handleGet, handlePost } = useApi();
    const [configuredChannels, setConfiguredChannels] = useState([]);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const { showSnackbar } = useSnackbar();
    const theme = useTheme();
    const { handleRunTour } = useTour();

    const toggleDrawer = (newOpen) => () => {
        setDrawerOpen(newOpen);
    };

    const handleMouseEnter = (event, setTooltip) => {
        const element = event.currentTarget;
        setTooltip(element.scrollWidth > element.clientWidth);
    };

    const areArraysDeepEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;

        const set1 = new Set(arr1.map(e => e.channelId));
        const set2 = new Set(arr2.map(e => e.channelId));

        if (set1.size !== set2.size) return false;

        for (let id of set1) {
            if (!set2.has(id)) return false;
        }

        return true;
    };

    const handleToggle = (channel) => {
        setConfiguredChannels((prevConfigured) => {
            let updatedConfigured;

            if (prevConfigured.some(c => c.channelId === channel.channelId)) {
                updatedConfigured = prevConfigured.filter(c => c.channelId !== channel.channelId);
            }
            else {
                updatedConfigured = [...prevConfigured, channel];
            }

            return updatedConfigured;
        });
    };

    const fetchTeams = async (accessToken) => {
        try {
            setIsLoading(true);

            const fetchWithAuth = (url) => {
                return fetch(url, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }).then(response => response.json());
            };

            const teamsResponse = await fetchWithAuth('https://graph.microsoft.com/v1.0/me/joinedTeams');
            const teamsData = teamsResponse.value;

            // eslint-disable-next-line no-undef
            const teamsWithChannels = await Promise.all(teamsData.map(async (team) => {
                const configuredChannelResponse = await handleGet(`teams?teamId=${team.id}`);
                const configuredChannels = await configuredChannelResponse.json();

                const activeChannels = configuredChannels.filter(channel => channel.status === 'Active');

                return {
                    ...team,
                    configuredChannels: activeChannels
                };
            }));

            setTeams(teamsWithChannels);
            setIsLoading(false);
        }
        catch (error) {
            showSnackbar('Error loading teams', 'error');
            setIsLoading(false);
        }
    };

    const fetchChannels = async (teamId, accessToken) => {
        setDrawerOpen(true);
        setChannels([]);
        setConfiguredChannels([]);

        try {
            const configuredChannelResponse = await handleGet(`teams?teamId=${teamId}`);
            const configuredChannelsFromDb = await configuredChannelResponse.json();

            setConfiguredChannels(configuredChannelsFromDb);
            setOriginalConfiguredChannels(configuredChannelsFromDb);

            setIsChannelLoading(true);

            const fetchWithAuth = (url) => {
                return fetch(url, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }).then(response => response.json());
            };

            const channelsResponse = await fetchWithAuth(`https://graph.microsoft.com/v1.0/teams/${teamId}/channels`);
            const channelsData = channelsResponse.value;

            const channelsList = channelsData.map(channel => {
                const isChecked = configuredChannelsFromDb.some(
                    (configuredChannel) => configuredChannel.channelId === channel.id && configuredChannel.status === 'Active'
                );

                return {
                    channelId: channel.id,
                    channelDisplayName: channel.displayName,
                    description: channel.description,
                    membershipType: channel.membershipType,
                    createdDateTime: channel.createdDateTime,
                    isChecked: isChecked
                };
            });

            setChannels(channelsList);
            setIsChannelLoading(false);
        }
        catch (error) {
            showSnackbar('Error loading channels', 'error');
            setIsChannelLoading(false);
        }
    };

    const handleTeamClick = async (team) => {
        setSelectedTeam(team);

        const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        });

        const accessToken = response.accessToken;

        await fetchChannels(team.id, accessToken);
    };

    const onSubmit = async () => {
        const channelsToSave = configuredChannels
            .map(channel => ({
                teamsId: selectedTeam.id,
                channelId: channel.channelId,
                teamsName: selectedTeam.displayName,
                channelName: channel.channelDisplayName
            }));

        const response = await handlePost('teams', {
            teamsId: selectedTeam.id,
            channelsToSave: channelsToSave
        });

        if (!response.ok) {
            showSnackbar('Error saving changes', 'error');
            return;
        }

        setOriginalConfiguredChannels(configuredChannels);
        setHasUnsavedChanges(false);

        const tokenResponse = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        });
        const accessToken = tokenResponse.accessToken;

        await fetchTeams(accessToken);

        setDrawerOpen(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (accounts.length > 0) {
                try {
                    const response = await instance.acquireTokenSilent({
                        ...loginRequest,
                        account: accounts[0]
                    });
                    const accessToken = response.accessToken;
                    await fetchTeams(accessToken);
                }
                catch (error) {
                    console.error(error);
                }
            }
        };

        if (open) {
            fetchData();
        }
    }, [accounts, instance, open]);

    useEffect(() => {
        const initialChannels = channels.filter(channel => channel.isChecked);
        setConfiguredChannels(initialChannels);
        setOriginalConfiguredChannels(initialChannels);
    }, [channels]);

    useEffect(() => {
        const hasChanges = !areArraysDeepEqual(configuredChannels, originalConfiguredChannels);
        setHasUnsavedChanges(hasChanges);
    }, [originalConfiguredChannels, configuredChannels]);

    return (
        <>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Teams Configuration
                        </Typography>
                        <IconButton color="inherit" sx={{ mt: 1, ml: 2 }} onClick={() => handleRunTour({ path: 'teams-configure-dialog' })}>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                {isLoading && <LoadingOverlay />}

                <Box sx={{ display: 'flex', height: '100%', position: 'relative' }}>
                    <Box sx={{ width: '100%', p: 2, overflowY: 'auto' }}>
                        <Grid container spacing={4}>
                            {teams.map((team) => (
                                <Grid item key={team.id} xs={12} sm={6} md={4} lg={3} xl={2} className="teams-list">
                                    <Card className="teams" sx={{ height: 250, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <CardContent sx={{ pb: 0, textAlign: 'center' }}>
                                            <Box sx={{ height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <UserAvatar name={team.displayName} sx={{ height: 80, width: 80 }} />
                                            </Box>
                                            <Tooltip title={team.displayName} arrow disableHoverListener={!showTooltip}>
                                                <Typography
                                                    className="teams-name"
                                                    gutterBottom
                                                    variant="h6"
                                                    component="div"
                                                    sx={{ maxWidth: 200, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', mx: 2 }}
                                                    onMouseEnter={(e) => handleMouseEnter(e, setShowTooltip)}
                                                >
                                                    {team.displayName}
                                                </Typography>
                                            </Tooltip>
                                            <Typography className="no-channels" gutterBottom variant="subtitle2" component="div">
                                                Channels selected: {team.configuredChannels.length}
                                            </Typography>
                                        </CardContent>
                                        <CardActions className="choose-channel" sx={{ mt: 0, display: 'flex', justifyContent: 'center' }}>
                                            <Button onClick={() => handleTeamClick(team)} size="small">Choose Channels</Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    <Drawer
                        anchor="right"
                        open={drawerOpen}
                        onClose={toggleDrawer(false)}
                        sx={{ zIndex: theme.zIndex.modal + 1 }}
                        PaperProps={{
                            sx: { width: 450 }
                        }}
                    >
                        <AppBar
                            position="sticky"
                            color="primary"
                            sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
                        >
                            <Toolbar sx={{ px: 2, display: 'flex' }} disableGutters>
                                <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
                                    <Typography
                                        sx={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                        }}
                                        variant="h6">{selectedTeam?.displayName}
                                    </Typography>
                                    <IconButton sx={{ ml: 1 }} color="inherit" onClick={() => handleRunTour({ path: 'teams-channel-configure-drawer' })}>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </Box>
                                <IconButton color="inherit">
                                    <CloseIcon onClick={() => setDrawerOpen(false)} />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <Collapse in={isChannelLoading}>
                            <LinearProgress />
                        </Collapse>
                        <AlertBar
                            shown={hasUnsavedChanges}
                            action={(
                                <Button
                                    variant="outlined"
                                    size="small"
                                    disabled={isChannelLoading}
                                    onClick={onSubmit}
                                >
                                    Save Changes
                                </Button>
                            )}
                        >
                            {isLoading ? 'Updating...' : 'You have unsaved changes.'}
                        </AlertBar>
                        <List dense sx={{ px: 1, width: '100%' }} className="channel-list">
                            {channels.map((channel, index) => {
                                const labelId = `checkbox-list-secondary-label-${index}`;

                                return (
                                    <ListItem
                                        className="channel-info"
                                        key={index}
                                        secondaryAction={(
                                            <Checkbox
                                                className="channel-checkbox"
                                                edge="end"
                                                onChange={() => handleToggle(channel)}
                                                checked={Boolean(configuredChannels.find(ch => ch.channelId === channel.channelId))}
                                                onClick={(e) => e.stopPropagation()}
                                            />
                                        )}
                                        disablePadding
                                    >
                                        <ListItemButton onClick={() => handleToggle(channel)}>
                                            <ListItemText
                                                id={labelId}
                                                primary={channel.channelDisplayName}
                                                secondary={channel.description || 'No description available'}
                                            />
                                        </ListItemButton>
                                    </ListItem>

                                );
                            })}
                        </List>
                    </Drawer>
                </Box>
            </Dialog>
        </>
    );
};

export default TeamsFullScreenDialog;