import React from 'react';
import { Chip } from '@mui/material';
import { TrendingUp, TrendingDown } from '@mui/icons-material';
import { lighten } from '@mui/system';
import useTheme from '@mui/material/styles/useTheme';

const percentageDifference = (x, y, fixed = 2) => {
    const percent = ((x - y) / y) * 100;

    if (!isFinite(percent)) {
        return 100;
    }
    else if (isNaN(percent)) {
        return 0;
    }

    return Number(percent.toFixed(fixed));
};

const ChangeChip = ({ a, b, change }) => {
    const theme = useTheme();

    const changePercent = `${percentageDifference(b, a, 1)}%`;

    if (change === 0) {
        return (
            <Chip size="small" label="No Change" />
        );
    }

    return (
        <Chip
            icon={change > 0 ? <TrendingUp /> : <TrendingDown />}
            label={changePercent}
            size="small"
            sx={{
                backgroundColor: change > 0 ? lighten(theme.palette.success.light, 0.7) : lighten(theme.palette.error.light, 0.7),
                '& .MuiChip-icon': {
                    color: change > 0 ? theme.palette.success.main : theme.palette.error.main
                }
            }}
        />
    );
};

export default ChangeChip;