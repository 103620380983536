import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import Alert from '@mui/material/Alert';

const DeleteItemDialog = ({ item, onSubmit, onClose }) => {

    if (item.isFolder) {
        return (
            <Dialog onClose={onClose} open={true}>
                <DialogTitle>
                    Delete Folder
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Delete Folder {item.name} and all of its contents.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onSubmit}>Delete</Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        );
    }

    const subtitle = item.status === 'Scheduled' ? 'Please confirm you wish to cancel the scheduled send for this campaign. The email will revert to a draft state.' : 'Please confirm you wish to delete this report. The email will revert to a draft state.';
    const actionText = item.status === 'Scheduled' ? 'Cancel Campaign' : 'Delete';

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>
                {item.status === 'Scheduled' ? 'Cancel' : 'Delete'} & Revert to Draft: {item.name}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {subtitle}
                </DialogContentText>
                <Alert severity="warning" sx={{ mt: 2 }}>
                    {item.status === 'Scheduled' ? (
                        <span>This campaign has not yet been sent, it is scheduled for a time in the future. This action <strong>WILL STOP</strong> the campaign from sending.</span>
                    ) : (
                        <span>This campaign has <strong>already been sent</strong>. This action will <strong>NOT</strong> stop the campaign from sending.</span>
                    )}
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={onSubmit}>{actionText}</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteItemDialog;
