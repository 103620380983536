import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Item = ({ data, title, showTitle }) => {
    return (
        <Box sx={{ width: '100%' }}>
            {showTitle && (
                <Typography sx={{ pt: 2 }}>{title}</Typography>
            )}
            <List sx={{ listStyleType: 'disc', pl: 2 }}>
                {data.map((item) => (
                    <ListItem
                        sx={{ display: 'list-item', p: 0, width: 'auto' }}
                        key={item}
                        disableGutters
                    >
                        {item}
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

const GroupDeleteErrorDialog = ({ data, onClose }) => {

    if (!data || data.length === 0) {
        return null;
    }

    const automations = data.automations?.length > 0;
    const segments = data.segments?.length > 0;
    const [showTitle, setShowTitle] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (automations && !segments) {
            setMessage('This group cannot be deleted because it is the audience of the following automation(s):');
            setShowTitle(false);
        }

        if (!automations && segments) {
            setMessage('This group cannot be deleted because it is the basis of the following segment(s):');
            setShowTitle(false);
        }

        if (automations && segments) {
            setMessage('This group cannot be deleted because it is the basis of the following automation(s) and segment(s):');
            setShowTitle(true);
        }
    }, [automations, segments]);

    return (
        <Dialog open={true}>
            <DialogTitle>Error</DialogTitle>
            <DialogContent>
                <Alert severity="error">
                    {message}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        {automations && (
                            <Item
                                data={data.automations}
                                title="Automations"
                                showTitle={showTitle}
                            />
                        )}
                        {segments && (
                            <Item
                                data={data.segments}
                                title="Segments"
                                showTitle={showTitle}
                            />
                        )}
                    </Box>
                </Alert>
                <Typography sx={{ pt: 2 }}>
                    To delete a group you must first remove it from any automations or segments that are using it.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default GroupDeleteErrorDialog;
