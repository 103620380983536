import React, { useEffect, useState, useCallback } from 'react';
import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { useParams, useLocation } from 'react-router-dom';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';

const SurveyView = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type');
    const contentId = searchParams.get('contentId');
    const preview = searchParams.get('preview');
    const { id, verifierId, logId, contactId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [survey, setSurvey] = useState(null);
    const [surveyTheme, setSurveyTheme] = useState(null);
    const [surveyForm, setSurveyForm] = useState(null);
    const [error, setError] = useState(null);

    const STORAGE_ITEM_KEY = `survey-${id}`;

    const handleFetch = async (url, request) => {
        const response = await fetch(url, request);

        if (!response.ok) {
            const errorMessage = await response.json();
            setError(errorMessage.message);
            setIsLoading(false);
            return null;
        }

        return response;
    };

    const handleGet = async url => {
        return handleFetch(url, { method: 'get' });
    };

    const handlePost = async (url, data) => {
        return handleFetch(url, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
    };

    const handleLoadSurvey = async () => {
        let url = `contact-surveys/${id}/${verifierId}`;

        const queryParams = [];

        if (logId !== undefined && contactId !== undefined) {
            queryParams.push(`logId=${logId}`);
            queryParams.push(`contactId=${contactId}`);
        }

        if (type) {
            queryParams.push(`type=${type}`);
        }

        if (contentId) {
            queryParams.push(`contentId=${contentId}`);
        }

        if (preview) {
            queryParams.push('preview=true');
        }

        if (queryParams.length > 0) {
            url += `?${queryParams.join('&')}`;
        }

        const response = await handleGet(url);

        if (!response) return;

        const data = await response.json();
        setSurvey(data.json);
        setSurveyTheme(data.theme);
        setIsLoading(false);
    };

    const saveSurveyData = (survey) => {
        const data = survey.data;
        data.pageNo = survey.currentPageNo;
        window.localStorage.setItem(STORAGE_ITEM_KEY, JSON.stringify(data));
    };

    const restoreSurveyData = (survey) => {
        const prevData = window.localStorage.getItem(STORAGE_ITEM_KEY) || null;

        if (prevData) {
            const data = JSON.parse(prevData);
            survey.data = data;

            if (data.pageNo) {
                survey.currentPageNo = data.pageNo;
            }
        }
    };

    const submitSurveyData = (data) => {
        let url = `contact-surveys/${id}/${verifierId}`;

        const queryParams = [];

        if (logId !== undefined && contactId !== undefined) {
            queryParams.push(`logId=${logId}`);
            queryParams.push(`contactId=${contactId}`);
        }

        if (queryParams.length > 0) {
            url += `?${queryParams.join('&')}`;
        }

        handlePost(url, { Result: JSON.stringify(data) })
            .then(() => {
                window.localStorage.removeItem(STORAGE_ITEM_KEY);
            })
            .catch(error => console.error(error));
    };

    const surveyComplete = useCallback((survey) => {
        submitSurveyData(survey.data);
    }, [id, verifierId, logId, contactId]);

    const addSurveyFooter = (_) => {
        const container = document.querySelector('.sd-body');
        const footer = document.createElement('div');

        footer.className = 'sv-brand-info';
        footer.innerHTML = `
            <div class="sv-brand-info__text" style="font-family: Arial; font-size: 12px; color: #666666; margin-bottom: 5px; letter-spacing: 0.8px;">
                POWERED BY
            </div>
            <a class="sv-brand-info__logo" href="https://newzapp.co.uk/" target="_blank">
                <img src="https://cdn.newzapp.co.uk/assets/NewZapp-logo-150px-20px.png" alt="NewZapp Communications" style="display: block; width: 150px;">
            </a>`;
        footer.style.marginTop = '50px';
        container.appendChild(footer);
    };

    useEffect(() => {
        handleLoadSurvey();
    }, [id, verifierId, logId, contactId]);

    useEffect(() => {
        if (survey) {
            const form = new Model(survey);

            if (surveyTheme) {
                form.applyTheme(JSON.parse(surveyTheme));
            }

            restoreSurveyData(form);

            form.onValueChanged.add(() => saveSurveyData(form));
            form.onCurrentPageChanged.add(() => saveSurveyData(form));
            form.onComplete.add(surveyComplete);
            form.onAfterRenderSurvey.add(addSurveyFooter);

            setSurveyForm(form);
        }
    }, [survey, surveyTheme, surveyComplete]);

    if (isLoading) {
        return <LoadingOverlay />;
    }

    if (error) {
        return (
            <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Paper sx={{ width: '100%', height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h6">{error}</Typography>
                </Paper>
            </Box>
        );
    }

    if (!surveyForm) {
        return (
            <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Paper sx={{ width: '100%', height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h6">This survey is no longer available.</Typography>
                </Paper>
            </Box>
        );
    }

    return (
        <Box sx={{ height: 'calc(100vh - 1px)', overflowY: 'auto' }}>
            <Survey model={surveyForm} />
        </Box>
    );
};

export default SurveyView;
