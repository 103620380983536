import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import FlagIcon from '@mui/icons-material/Flag';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const getFirstNode = doc => {
    const elements = doc.querySelectorAll('body, body *');

    let results = [];
    let child = null;

    for (let i = 0; i < elements.length; i++) {
        child = elements[i].childNodes[0];

        // all text nodes are nodeType 3
        if (elements[i].hasChildNodes() && child.nodeType === 3) {
            results.push(child.nodeValue);
        }
    }

    return results[0];
};

const InboxPreviewDialog = ({ open, onClose, fromName, fromEmail, subject, html }) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');

    const johnsonText = getFirstNode(doc);

    const cell = {
        verticalAlign: 'top'
    };

    const controls = {
        width: '10%',
        maxWidth: 40
    };

    const subjectCell = {
        width: '45%'
    };

    const opacity = {
        opacity: 0.5
    };

    return (
        <Dialog
            onClose={onClose}
            open={open}
            fullWidth={true}
            maxWidth="md"
            TransitionComponent={Transition}
        >
            <DialogTitle>Inbox Preview</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    If recipients are using 2 line preview in their inbox, you can maximise its impact. <Link target="_blank" href="https://help.newzapp.co.uk/whats-preview-text-and-how-do-i-use-the-johnson-box">Read how</Link> and then try this technique in your email content on the previous screen.
                </DialogContentText>
            </DialogContent>
            <DialogContent>
                <Paper>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell sx={{ ...cell, ...controls }}>
                                        <CheckBoxOutlineBlankIcon color="disabled" />
                                        <FlagIcon color="warning" />
                                    </TableCell>
                                    <TableCell sx={cell}>
                                        {fromName} ({fromEmail})
                                        <Box>
                                            {johnsonText}
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={{ ...cell, ...subjectCell }}>
                                        {subject}
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={opacity}>
                                    <TableCell sx={{ ...cell, ...controls }}>
                                        <CheckBoxOutlineBlankIcon color="disabled" />
                                        <FlagIcon color="warning" />
                                    </TableCell>
                                    <TableCell sx={cell}>
                                        John Smith (john.smith@email.com)
                                        <Box>
                                            <Typography variant="body2">
                                                In this edition we look at ways to increase ROI
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={{ ...cell, ...subjectCell }}>
                                        Figures you asked for
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={opacity}>
                                    <TableCell sx={{ ...cell, ...controls }}>
                                        <CheckBoxOutlineBlankIcon color="disabled" />
                                        <FlagIcon color="warning" />
                                    </TableCell>
                                    <TableCell sx={cell}>
                                        Mary Cooper (mary.cooper@company.com)
                                        <Box>
                                            Offers inside are available across all our ranges
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={{ ...cell, ...subjectCell }}>
                                        This week's deals
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InboxPreviewDialog;
