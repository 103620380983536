import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { format } from 'date-fns';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import buildUrl from '../buildUrl';
import useSearch from '../hooks/useSearch';
import DataColumn from '../dataTable/DataColumn';
import DatePicker from '../DatePicker';
import useApi from '../hooks/useApi';
import CenteredProgress from '../loadingOverlay/CenteredProgress';
import useSnackbar from '../hooks/useSnackbar';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';

const rowsPerPageOptions = [10];

const pageColumns = [
    { name: 'name', label: 'Name', orderDesc: false, dataType: 'text', width: '25%' },
    { name: 'title', label: 'Title', orderDesc: false, dataType: 'text', width: '25%' },
    { name: 'published', label: 'Status', orderDesc: false, dataType: 'pageChip', width: 120 },
    { name: 'publishedDateTime', label: 'Published', align: 'right', orderDesc: false, dataType: 'dateTime' },
    { name: 'totalOpens', label: 'Opens', align: 'right', orderDesc: true, dataType: 'number' },
    { name: 'totalClicks', label: 'Clicks', align: 'right', orderDesc: true, dataType: 'number' },
    { name: 'numberOfLinks', label: 'Links', align: 'right', orderDesc: true, dataType: 'number' }
];

const PagesTable = () => {
    const history = useHistory();
    const [isInitialising, setIsInitialising] = useState(true);
    const [reports, setReports] = useState([]);
    const [count, setCount] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [page, setPage] = useState(0);
    const [orderBy, setOrderBy] = useState('publishedDateTime');
    const [orderDesc, setOrderDesc] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [tempSearchValue, setTempSearchValue] = useSearch(setSearchValue);
    const { handleGet } = useApi();
    const { showSnackbar } = useSnackbar();

    const handleFetchCount = async () => {
        const params = {
            search: searchValue,
            ...(fromDate && { fromDate: format(fromDate, 'yyyy-MM-dd') }),
            ...(toDate && { toDate: format(toDate, 'yyyy-MM-dd') })
        };

        const url = buildUrl('pages/reports/count', params);
        const response = await handleGet(url);

        if (!response.ok) {
            showSnackbar('Error fetching count', 'error');
            return;
        }

        const data = await response.json();
        setCount(data);
    };

    const handleFetchReports = async () => {
        setIsLoading(true);

        const params = {
            search: searchValue,
            ...(fromDate && { fromDate: format(fromDate, 'yyyy-MM-dd') }),
            ...(toDate && { toDate: format(toDate, 'yyyy-MM-dd') }),
            skip: page * rowsPerPage,
            take: rowsPerPage,
            orderBy,
            isDescending: orderDesc
        };

        const url = buildUrl('pages/reports', params);
        const response = await handleGet(url);

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const data = await response.json();

        setReports(data);
        setIsLoading(false);
    };

    const handleSort = (field, defaultOrder) => {
        if (field === orderBy) {
            setOrderDesc(!orderDesc);
        }
        else {
            setOrderBy(field);
            setOrderDesc(defaultOrder);
        }
    };

    const handleInit = async () => {
        await handleFetchReports();
        await handleFetchCount();
        setIsInitialising(false);
    };

    useEffect(() => {
        if (isInitialising) {
            return;
        }

        handleFetchCount();
    }, [searchValue, fromDate, toDate]);

    useEffect(() => {
        setPage(0);
    }, [searchValue, fromDate, toDate, orderBy, orderDesc, rowsPerPage]);

    useEffect(() => {
        setIsLoading(true);
    }, [searchValue, fromDate, toDate, orderBy, orderDesc, rowsPerPage]);

    useEffect(() => {
        if (isInitialising) {
            return;
        }

        handleFetchReports();
    }, [searchValue, fromDate, toDate, orderBy, orderDesc, rowsPerPage, page]);

    useEffect(() => {
        handleInit();
    }, []);

    if (isInitialising) {
        return <LoadingOverlay />;
    }

    return (
        <Box sx={{ overflow: 'hidden' }}>
            <Paper>
                <Toolbar disableGutters sx={{ mx: 1.5, paddingTop: 0.5, paddingBottom: 0.5 }}>
                    <OutlinedInput
                        value={tempSearchValue}
                        onChange={e => setTempSearchValue(e.target.value)}
                        placeholder="Search..."
                        size="small"
                        sx={{ mr: 1 }}
                        endAdornment={(
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setTempSearchValue('')}
                                    edge="end"
                                    disabled={!Boolean(tempSearchValue)}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        )}
                    />
                    <Box sx={{ display: 'inline-flex' }} mr={1}>
                        <DatePicker value={fromDate} onChange={setFromDate} label="From Date" />
                    </Box>
                    <Box sx={{ display: 'inline-flex' }} mr={1}>
                        <DatePicker value={toDate} onChange={setToDate} label="To Date" />
                    </Box>
                </Toolbar>
                <Divider />
                {isLoading ? (
                    <Box py={4}>
                        <CenteredProgress colour="primary" />
                    </Box>
                ) : (
                    <>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        {pageColumns.map(col => (
                                            <TableCell
                                                key={col.name}
                                                component="th"
                                                scope="row"
                                                align={col.align}
                                                width={col.width || 'auto'}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === col.name}
                                                    direction={orderDesc ? 'desc' : 'asc'}
                                                    onClick={() => handleSort(col.name, col.orderDesc)}
                                                >
                                                    {col.label}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reports.map(report => (
                                        <TableRow
                                            key={report.id}
                                            hover={true}
                                            onClick={() => history.push(`/reports/page/${report.id}`)}
                                        >
                                            {pageColumns.map(col => (
                                                <DataColumn
                                                    key={col.name}
                                                    align={col.align}
                                                    dataType={col.dataType}
                                                    value={report[col.name]}
                                                />
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(e, newPage) => setPage(newPage)}
                            onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
                        />
                    </>
                )}
            </Paper>
        </Box>
    );
};

export default PagesTable;