import React, { useEffect, useState } from 'react';
import useApi from '../hooks/useApi';
import usePrevious from '../hooks/usePrevious';
import buildUrl from '../buildUrl';
import ContactsNavigation from './ContactsNavigation';
import Paper from '@mui/material/Paper';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import Button from '@mui/material/Button';
import AlertBar from '../alertBar/AlertBar';
import TitleBar from '../titleBar/TitleBar';
import ContactsTable from './ContactsTable';
import Contact from './Contact';
import Filters from '../filters/Filters';
import AddContactDialog from './dialogs/AddContactDialog';
import ImportContactsDialog from './dialogs/ImportContactsDialog';
import useSnackbar from '../hooks/useSnackbar';
import ExportDialog from './dialogs/ExportDialog';
import CopyContactsDialog from './dialogs/CopyContactsDialog';
import MoveContactsDialog from './dialogs/MoveContactsDialog';
import DeleteContactsDialog from './dialogs/DeleteContactsDialog';
import UnsubscribeSuppressContactsDialog from './dialogs/UnsubscribeSuppressContactsDialog';
import NoContactsSelectedDialog from './dialogs/NoContactsSelectedDialog';
import BulkOperationInProgressDialog from './dialogs/BulkOperationInProgressDialog';
import ImportHistoryDialog from './dialogs/ImportHistoryDialog';
import useNotifications from '../hooks/useNotifications';
import GroupDeleteErrorDialog from '../dialogs/GroupDeleteErrorDialog';
import parseFilters from '../parseFilters';
import Box from '@mui/material/Box';
import useAccount from '../hooks/useAccount';
import AddContacts from './menus/AddContacts';
import Settings from './menus/Settings';
import baseColumns from './baseColumns';
import useTheme from '@mui/material/styles/useTheme';
import { useMediaQuery } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { filters as availableFilters } from '../filters/contactFilters';
import { format } from 'date-fns';
import Typography from '@mui/material/Typography';

const drawerWidth = 400;
const rowsPerPageOptions = [10, 20, 50];

// an old segment's filters will always be all unordered but we can assume order from index

const orderFilters = filters => {
    const unorderedFilters = filters.find(e => !e.order);

    if (unorderedFilters) {
        return filters.sort((a, b) => a.id - b.id).map((filter, index) => {
            return {
                ...filter,
                order: index
            };
        });
    }
    else {
        return filters;
    }
};

const Contacts = () => {
    const { customFields, user } = useAccount();
    const { handleGet, handlePut, handlePost, handleDelete } = useApi();
    const { showSnackbar } = useSnackbar();
    const { notifications } = useNotifications();
    const previousNotifications = usePrevious(notifications);
    const [dialog, setDialog] = useState(null);

    const [allContactsCount, setAllContactsCount] = useState(0);
    const [suppressedGroupCount, setSuppressedGroupCount] = useState(0);
    const [ungroupedCount, setUngroupedCount] = useState(0);

    const [groupsTempSearchValue, setGroupsTempSearchValue] = useState('');
    const [segmentsTempSearchValue, setSegmentsTempSearchValue] = useState('');

    const [isProcessing, setIsProcessing] = useState(false);
    const [bulkOperation, setBulkOperation] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [count, setCount] = useState(0);
    const [orderBy, setOrderBy] = useState('emailAddress');
    const [orderDesc, setOrderDesc] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingNavigation, setIsLoadingNavigation] = useState(true);
    const [title, setTitle] = useState('');
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedSegment, setSelectedSegment] = useState(null);
    const [unmodifiedSegment, setUnmodifiedSegment] = useState(null);
    const [selectedPseudoGroup, setSelectedPseudoGroup] = useState('allContacts');
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [filtersType, setFiltersType] = useState('group');
    const [filters, setFilters] = useState(null);
    const [condition, setCondition] = useState('and');
    const [showFilters, setShowFilters] = useState(false);
    const [searchValue, setSearchValue] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
    const [page, setPage] = useState(0);
    const [allContactsSelected, setAllContactsSelected] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [selectedContactId, setSelectedContactId] = useState(null);
    const [status, setStatus] = useState('subscribed');
    const [tab, setTab] = useState(0);
    const [suppressedCount, setSuppressedCount] = useState(0);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [savedFilters, setSavedFilters] = useState(null);
    const [groupsSort, setGroupsSort] = useState('alphaAsc');
    const [groups, setGroups] = useState(null);
    const [unsortedGroups, setUnsortedGroups] = useState(null);
    const [unsortedSegments, setUnsortedSegments] = useState(null);
    const [filterTagIds, setFilterTagIds] = useState([]);
    const [groupType, setGroupType] = useState(null);
    const [segmentsSort, setSegmentsSort] = useState('alphaAsc');
    const [segments, setSegments] = useState(null);
    const [groupsSearchValue, setGroupsSearchValue] = useState('');
    const [segmentsSearchValue, setSegmentsSearchValue] = useState('');
    const [columns, setColumns] = useState(baseColumns);
    const [open, setOpen] = useState(false);
    const [mountDrawer, setMountDrawer] = useState(false);
    const [openContact, setOpenContact] = useState(false);
    const theme = useTheme();

    const hasManageContactsPermission = user.permissions.includes('Manage Contacts');

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const isInitialising = isLoading && isLoadingNavigation;

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const handleShowErrorDialog = (data) => {
        setDialog(
            <GroupDeleteErrorDialog data={data} onClose={() => setDialog(null)} />
        );
    };

    const handleFetchContacts = async () => {
        const parsedFilters = parseFilters(filters);
        const groupId = selectedSegment ? selectedSegment?.groupId : selectedGroup?.id;

        const params = {
            skip: page * rowsPerPage,
            take: rowsPerPage,
            field: orderBy,
            isDescending: orderDesc,
            search: searchValue,
            groupId: groupId || null,
            filters: parsedFilters,
            suppressed: selectedPseudoGroup === 'suppressed',
            ungrouped: selectedPseudoGroup === 'ungrouped',
            unsubscribed: status === 'bounced' ? true : status === 'unsubscribed',
            bounced: status === 'bounced',
            condition: selectedSegment?.condition ?? condition
        };

        const url = buildUrl('contacts', params);
        const response = await handleGet(url);

        if (!response.ok) {
            setIsLoading(false);
            console.error(response?.error);
            return;
        }

        let data = await response.json();

        // Un-nesting customFields
        data = data?.map(contact => {
            contact.customFields?.forEach(field => {
                contact[field.name] = field.value;
            });

            return contact;
        });

        setContacts(data);
        setIsLoading(false);
    };

    const handleFetchContactsCountBase = async (params = {}) => {
        const url = buildUrl('contacts/count', params);
        const response = await handleGet(url);

        if (!response.ok) {
            console.error(response?.error);
            showSnackbar('Error fetching contacts', 'error');
            setShowFilters(false);
            return null;
        }

        return await response.json();
    };

    const handleFetchContactsCount = async () => {
        const parsedFilters = parseFilters(filters);
        const groupId = selectedSegment ? selectedSegment?.groupId : selectedGroup?.id;

        const params = {
            search: searchValue,
            groupId: groupId || null,
            filters: parsedFilters,
            suppressed: selectedPseudoGroup === 'suppressed',
            ungrouped: selectedPseudoGroup === 'ungrouped',
            unsubscribed: status === 'bounced' ? true : status === 'unsubscribed',
            bounced: status === 'bounced',
            condition: selectedSegment?.condition ?? condition
        };

        const count = await handleFetchContactsCountBase(params);
        setCount(count);
    };

    const handleFetchTotalContactsCount = async () => {
        const groupId = selectedSegment ? selectedSegment?.groupId : selectedGroup?.id;

        const params = {
            search: searchValue,
            groupId: groupId || null,
            suppressed: selectedPseudoGroup === 'suppressed',
            ungrouped: selectedPseudoGroup === 'ungrouped',
            unsubscribed: status === 'bounced' ? true : status === 'unsubscribed',
            bounced: status === 'bounced',
            ...(selectedSegment && { condition: selectedSegment.condition })
        };

        const count = await handleFetchContactsCountBase(params);
        setTotalCount(count);
    };

    const handleFetchSuppressedCount = async () => {
        const parsedFilters = parseFilters(filters);
        const groupId = selectedSegment ? selectedSegment?.groupId : selectedGroup?.id;

        const params = {
            groupId: groupId || null,
            filters: parsedFilters,
            suppressed: true,
            ungrouped: selectedPseudoGroup === 'ungrouped',
            unsubscribed: status === 'bounced' ? true : status === 'unsubscribed',
            bounced: status === 'bounced',
            condition: selectedSegment?.condition ?? condition
        };

        const count = await handleFetchContactsCountBase(params);

        setSuppressedCount(count);
    };

    const handleFetchGroups = async () => {
        const response = await handleGet('groups');

        if (!response.ok) {
            setIsLoadingNavigation(false);
            console.error(response?.error);
            return;
        }

        const data = await response.json();

        setUnsortedGroups(data);
        setIsLoadingNavigation(false);
    };

    const handleFetchSegments = async () => {
        const response = await handleGet('segments');

        if (!response.ok) {
            setIsLoadingNavigation(false);
            console.error(response?.error);
            return;
        }

        const data = await response.json();

        setUnsortedSegments(data);
        setIsLoadingNavigation(false);
    };

    const handleSortContacts = props => {
        const { field, sort } = props[0];

        const orderDesc = sort === 'desc';

        if (field === orderBy && (!sort || (orderDesc !== orderDesc))) {
            setOrderDesc(!orderDesc);
        }
        else {
            setOrderBy(field);
            setOrderDesc(orderDesc);
        }
    };

    const handleFetchPseudoGroupCountAll = async () => {
        const count = await handleFetchContactsCountBase();
        setAllContactsCount(count);
    };

    const handleFetchPseudoGroupCountSuppressed = async () => {
        const count = await handleFetchContactsCountBase({ suppressed: true });
        setSuppressedGroupCount(count);
    };

    const handleFetchPseudoGroupCountUngrouped = async () => {
        const count = await handleFetchContactsCountBase({ ungrouped: true });
        setUngroupedCount(count);
    };

    const handleFetchNavItems = async (refresh = false) => {
        if (tab === 0) {
            //reset search values
            setSegmentsTempSearchValue('');

            await handleFetchContactsCount();

            if (isInitialising || refresh) {
                await handleFetchTotalContactsCount();
                await handleFetchPseudoGroupCountAll();
                await handleFetchPseudoGroupCountSuppressed();
                await handleFetchPseudoGroupCountUngrouped();
                handleFetchGroups();
            }
            else {
                setIsLoadingNavigation(false);
            }
        }
        else {
            //reset search values
            setGroupsSearchValue('');
            setSegmentsSearchValue('');
            setGroupsTempSearchValue('');

            if (isInitialising || refresh || !segments) {
                handleFetchSegments();
            }
            else {
                setIsLoadingNavigation(false);
            }
        }
    };

    const handleRefresh = () => {
        handleFetchNavItems(true);
        handleFetchContacts();
    };

    const handleAddContact = async (emailAddress, addToCurrentGroup = false) => {
        const response = await handlePost('contacts', {
            emailAddress,
            ...((selectedGroup && addToCurrentGroup) && { groupId: selectedGroup.id })
        });

        if (!response.ok) {
            if (response.status === 400) {
                const json = await response.json();
                showSnackbar(json.message, 'error');
                return;
            }

            showSnackbar('Error adding contact', 'error');

            return;
        }

        const result = await response.json();

        handleRefresh();

        setSelectedContactId(result?.contact.id);

        if (result?.contact.isNew === true) {
            showSnackbar(`Contact created: ${result?.contact.emailAddress}`, 'success');
            return;
        }

        if (result?.contact.hasBounced || result?.contact.isUnsubscribed) {
            showSnackbar(`Contact already in account, Status: ${result.result}`, 'warning');
            return;
        }

        showSnackbar(`Contact already in account: ${result?.contact.emailAddress}`, 'warning');

    };

    const handleUpdateSegmentDetails = async segment => {
        const response = await handlePut(`segments/${segment.id}`, segment);

        if (!response.ok) {
            showSnackbar('Error: Segment not saved', 'error');
            console.error(response?.error);
            return;
        }

        showSnackbar('Changes saved', 'success');

        handleFetchSegments();
    };

    const handleUpdateSegment = async () => {
        const stringifyFilterValues = () => {
            const parsedFilters = parseFilters(filters);
            return parsedFilters?.map(e => ({ ...e, value: e.value.toString() }));
        };

        const stringifiedFilters = stringifyFilterValues();

        const params = {
            ...selectedSegment,
            filters: stringifiedFilters?.filter(f => f.value) || null,
            groupId: selectedSegment?.groupId
        };

        const response = await handlePut(`segments/${selectedSegment.id}`, params);

        if (!response.ok) {
            showSnackbar('Error: Segment not saved', 'error');
            return;
        }

        const data = await response.json();

        showSnackbar('Segment updated', 'success');

        await handleFetchSegments();

        setSavedFilters(data.filters);
        setSelectedSegment(data);
        setUnmodifiedSegment(data);
    };

    const handleSelectSegment = segment => {
        setSelectedSegment(segment);
        setUnmodifiedSegment(segment);
        setOpen(false);

        //translate any old filter data - remove once all data converted
        const translatedFilters = segment?.filters ? orderFilters(segment.filters) : null;

        if (translatedFilters) {
            setFilters(translatedFilters);
            setSavedFilters(translatedFilters);
        }
        else {
            setFilters(null);
            setSavedFilters(null);
        }
    };

    const handleCreateSegment = async segment => {
        const params = {
            name: segment.name,
            groupId: segment.groupId,
            ...(segment.description && { description: segment.description }),
            condition: 'and'
        };

        const response = await handlePost('segments', params);

        if (!response.ok) {
            showSnackbar('Error: Segment not created', 'error');
            return;
        }

        // if the segment was created with a new group we need to refresh the list of groups

        const newGroup = groups.find(g => g.id === segment.groupId);

        if (!newGroup) {
            await handleFetchGroups();
        }

        const data = await response.json();

        showSnackbar('Segment created', 'success');

        await handleFetchSegments();

        handleSelectSegment(data);
        setShowFilters(true);
    };

    const handleRevertSegmentChanges = () => {
        setFilters(savedFilters);
        setSelectedSegment(unmodifiedSegment);
    };

    const handleDeleteGroup = async group => {
        const response = await handleDelete(`groups/${group.id}`);

        if (!response.ok) {
            const data = await response.json();
            console.error(data);
            handleShowErrorDialog(data);
            return;
        }

        showSnackbar('Group deleted', 'success');

        handleFetchGroups();
    };

    const handleDeleteSegment = async segment => {
        const response = await handleDelete(`segments/${segment.id}`);

        if (!response.ok) {
            showSnackbar('Error: Segment not found', 'error');
            console.error(response?.error);
            return;
        }

        showSnackbar('Segment deleted', 'success');

        if (segment.id === selectedSegment?.id) {
            handleSelectSegment(null);
        }

        handleFetchSegments();
    };

    const handleUpdateGroup = async group => {
        const response = await handlePut(`groups/${group.id}`, group);

        if (!response.ok) {
            showSnackbar('Error: Group not saved', 'error');
            console.error(response?.error);
            return;
        }

        showSnackbar('Changes saved', 'success');

        handleFetchGroups();
    };

    const handleCreateGroup = async newGroup => {
        await handleFetchGroups();
        showSnackbar(`Group created: ${newGroup.name}`, 'success');
    };

    const handleFilterByTag = tagId => {
        if (filterTagIds.includes(tagId)) {
            setFilterTagIds(filterTagIds.filter(e => e !== tagId));
        }
        else {
            setFilterTagIds([...filterTagIds, tagId]);
        }
    };

    const handleSetGroup = group => {
        setSelectedPseudoGroup(null);
        setSelectedGroup(group);
        setOpen(false);
    };

    const handleSetPseudoGroup = group => {
        setSelectedGroup(null);
        setSelectedPseudoGroup(group);
        setOpen(false);
    };

    const handleCheckForUnsavedChanges = () => {
        if (selectedSegment === null) {
            return;
        }

        if (unmodifiedSegment?.id !== selectedSegment.id) {
            setUnsavedChanges(false);
            return;
        }

        const mapFn = e => {
            const filter = availableFilters.find(f => f.name.toLowerCase() === e.field.toLowerCase());

            return {
                field: e.field,
                operator: e.operator,
                value: filter?.category === 'behaviour' ? e.value.toString() : e.value
            };
        };

        const sortFn = (a, b) => a.field.localeCompare(b.field) || a.operator.localeCompare(b.operator) || a.value.localeCompare(b.value);

        //comparable equality
        const mapAndSort = filters => filters?.map(mapFn).sort(sortFn) ?? [];

        const appliedFilters = mapAndSort(filters);
        const segmentSavedFilters = mapAndSort(savedFilters);

        //converted null/undefined check in groupMatch for easier understanding
        const groupMatch = (selectedSegment.groupId ?? 'allContacts') === (unmodifiedSegment?.groupId ?? 'allContacts');
        const filtersMatch = JSON.stringify(appliedFilters) === JSON.stringify(segmentSavedFilters);
        const conditionMatch = selectedSegment.condition === unmodifiedSegment.condition;

        setUnsavedChanges(!groupMatch || !filtersMatch || !conditionMatch);
    };

    const handleExport = async () => {
        setIsProcessing(true);

        const parsedFilters = parseFilters(filters);
        const groupId = selectedSegment ? selectedSegment?.groupId : selectedGroup?.id;

        const params = {
            filters: parsedFilters,
            search: searchValue,
            field: orderBy,
            isDescending: orderDesc,
            suppressed: selectedPseudoGroup === 'suppressed',
            ungrouped: selectedPseudoGroup === 'ungrouped',
            bounced: status === 'bounced',
            unsubscribed: status === 'bounced' ? true : status === 'unsubscribed',
            ...(groupId && { groupId }),
            ...((!allContactsSelected && selectedContacts.length > 0) && { selectedContacts }),
            ...(selectedSegment && { condition: selectedSegment.condition })
        };

        const url = buildUrl('contacts/export', params);

        try {
            const response = await handleGet(url);
            const data = await response.json();

            setDialog(
                <ExportDialog
                    jobId={data.jobId}
                    url={data.downloadPath}
                    isComplete={data.completed}
                    onClose={() => setDialog(null)}
                />
            );
        }
        catch (e) {
            console.error(e);
            showSnackbar('Export failed', 'error');
        }
        finally {
            setIsProcessing(false);
        }
    };

    const handleUpdateContacts = async ({ operation, destinationGroupIds = null, suppressUntilDate }) => {
        setIsProcessing(true);
        const parsedFilters = parseFilters(filters);

        // todo if IDs are selected we don't need to pass filters
        const params = {
            operation,
            suppressed: selectedPseudoGroup === 'suppressed',
            // orinigin change
            // ...(suppressUntilDate && { suppressUntilDate: format(suppressUntilDate, 'yyyy-MM-dd') }),
            // testing change
            ...(suppressUntilDate && { suppressUntilDate: format(suppressUntilDate, 'yyyy-MM-dd HH:mm') }),
            ungrouped: selectedPseudoGroup === 'ungrouped',
            bounced: status === 'bounced',
            unsubscribed: status === 'bounced' ? true : status === 'unsubscribed',
            filters: parsedFilters,
            search: searchValue,
            ...(!allContactsSelected && { contactIds: selectedContacts }),
            ...(destinationGroupIds && { destinationGroupIds }),
            ...(selectedGroup && { sourceGroupId: selectedGroup.id }),
            ...(selectedSegment && { condition: selectedSegment.condition })
        };

        const url = buildUrl('contacts', params);

        try {
            const response = await handlePut(url);

            if (response.ok) {
                const data = await response.json();

                if (data.completed) {
                    handleRefresh();
                    showSnackbar('Contacts Updated', 'success');
                    setSelectedContacts([]);
                    setAllContactsSelected(false);
                }
                else {
                    setBulkOperation(data);
                }
            }
        }
        catch (e) {
            console.error(e);
            showSnackbar('Failed to Update Contacts', 'error');
        }
        finally {
            setIsProcessing(false);
        }

        handleFetchSuppressedCount();
    };

    useEffect(() => {
        setIsLoadingNavigation(true);
    }, [tab]);

    useEffect(() => {
        if (!isLoadingNavigation && hasManageContactsPermission) {
            handleFetchContactsCount();
        }
    }, [selectedGroup, selectedPseudoGroup, selectedSegment, searchValue, status, filters, condition]);

    useEffect(() => {
        if (!isLoadingNavigation && hasManageContactsPermission) {
            handleFetchTotalContactsCount();
        }
    }, [selectedGroup, selectedPseudoGroup, selectedSegment, searchValue, status]);

    useEffect(() => {
        if (hasManageContactsPermission) {
            handleFetchSuppressedCount();
        }
    }, [selectedGroup, selectedPseudoGroup, selectedSegment, status, filters, condition]);

    useEffect(() => {
        if (allContactsSelected) {
            const selectedContacts = contacts.map(e => e.id);
            setSelectedContacts(selectedContacts);
        }
    }, [contacts]);

    useEffect(() => {
        if (isLoadingNavigation && hasManageContactsPermission) {
            handleFetchNavItems();
        }
    }, [isLoadingNavigation]);

    useEffect(() => {
        if (hasManageContactsPermission) {
            handleCheckForUnsavedChanges();
        }
    }, [selectedSegment, unmodifiedSegment, savedFilters]);

    useEffect(() => {
        if (isInitialising || isLoadingNavigation) {
            return;
        }

        const importNotificationsLength = notifications?.filter(({ type }) => type === 'job' || type === 'import')?.length ?? 0;
        const previousImportNotificationsLength = previousNotifications?.filter(({ type }) => type === 'job' || type === 'import')?.length ?? 0;

        if (importNotificationsLength > previousImportNotificationsLength) {
            handleRefresh();
        }
    }, [notifications, isLoadingNavigation]);

    useEffect(() => {
        if (!bulkOperation) {
            return;
        }

        setDialog(
            <BulkOperationInProgressDialog
                onClose={() => {
                    setDialog(null);
                    setBulkOperation(null);
                }}
                jobId={bulkOperation.jobId}
            />
        );
    }, [bulkOperation]);

    useEffect(() => {
        if (customFields) {
            const customFieldColumns = customFields.map(field => ({
                field: field.name,
                headerName: field.name,
                sortable: false,
                type: field.type === 'date' ? 'dateTime' : field.type,
                headerAlign: 'left',
                align: 'left',
                ...(field.type === 'date' ? { valueGetter: ({ value }) => value && new Date(value) } : null)
            }));

            setColumns([...baseColumns, ...customFieldColumns]);
        }
    }, [customFields]);

    useEffect(() => {
        setFiltersType(tab === 0 ? 'group' : 'segment');

        setSelectedGroup(null);
        setSelectedSegment(null);
        setUnmodifiedSegment(null);
        setSelectedContacts([]);
        setFilters(null);
        setGroupType(null);
        setUnsavedChanges(false);
        setFilterTagIds([]);
        setSearchValue([]);

        if (tab === 0) {
            setSelectedPseudoGroup('allContacts');
        }
        else {
            setSelectedPseudoGroup(null);
            //reset table defaults
            setOrderBy('emailAddress');
            setOrderDesc(false);
        }
    }, [tab]);

    useEffect(() => {
        if (hasManageContactsPermission) {
            setPage(0);
        }
    }, [selectedGroup, selectedPseudoGroup, selectedSegment, searchValue, status, filters, condition, orderBy, orderDesc, rowsPerPage]);

    useEffect(() => {
        if (hasManageContactsPermission) {
            setIsLoading(true);
            handleFetchContacts();
        }
    }, [selectedGroup, selectedPseudoGroup, selectedSegment, searchValue, status, filters, condition, orderBy, orderDesc, rowsPerPage, page]);

    useEffect(() => {
        !allContactsSelected && setSelectedContacts([]);
    }, [allContactsSelected]);

    useEffect(() => {
        if (tab === 1 && selectedSegment && (selectedSegment?.id === unmodifiedSegment?.id)) {
            handleCheckForUnsavedChanges();
        }
    }, [selectedSegment, filters, condition]);

    useEffect(() => {
        if (selectedGroup) {
            setSelectedPseudoGroup(null);
            setSelectedContacts([]);
            setAllContactsSelected(false);
            setTitle(selectedGroup.name);
        }

        selectedPseudoGroup && setSelectedGroup(null);

        switch (selectedPseudoGroup) {
            case 'ungrouped':
                setTitle('Ungrouped');
                break;
            case 'suppressed':
                setTitle('Suppressed');
                break;
            case 'allContacts':
                setTitle('All Contacts');
                break;
        }

        selectedSegment && setTitle(selectedSegment.name);

        if (!selectedPseudoGroup && !selectedGroup && !selectedSegment) {
            const itemName = `${filtersType.charAt(0).toUpperCase()}${filtersType.slice(1)}`;

            setTitle(`No ${itemName} Selected`);
        }
    }, [selectedPseudoGroup, selectedGroup, selectedSegment]);

    useEffect(() => {
        if (!unsortedGroups || unsortedGroups.length === 0) {
            setGroups(unsortedGroups);
            return;
        }

        let sortedGroups = [...unsortedGroups];

        if (groupsSort === 'alphaDesc') {
            sortedGroups.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                return nameB.localeCompare(nameA);
            });
        }

        if (groupsSort === 'alphaAsc') {
            sortedGroups.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                return nameA.localeCompare(nameB);
            });
        }

        if (groupsSort === 'dateDesc') {
            sortedGroups.sort((a, b) => b.id - a.id);
        }

        if (groupsSort === 'dateAsc') {
            sortedGroups.sort((a, b) => a.id - b.id);
        }

        if (groupsSearchValue) {
            sortedGroups = sortedGroups.filter(e => e.name.toLowerCase().includes(groupsSearchValue.toLowerCase()));
        }

        if (groupType) {
            sortedGroups = sortedGroups.filter(e => e.type === groupType);
        }
        else {
            sortedGroups = sortedGroups.filter(e => e.type !== 'archive');
        }

        if (filterTagIds?.length > 0) {
            sortedGroups = sortedGroups.filter(e => filterTagIds.every((tagId) => e.tags.map(t => t.id).includes(tagId)));
        }

        setGroups(sortedGroups);
    }, [groupsSort, groupType, groupsSearchValue, filterTagIds, unsortedGroups]);

    useEffect(() => {
        if (!unsortedSegments || unsortedSegments.length === 0) {
            setSegments(unsortedSegments);
            return;
        }

        let sortedSegments = [...unsortedSegments];

        if (segmentsSort === 'alphaDesc') {
            sortedSegments = unsortedSegments.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();

                if (nameA > nameB) {
                    return -1;
                }

                if (nameA < nameB) {
                    return 1;
                }

                return 0;
            });
        }

        if (segmentsSort === 'alphaAsc') {
            sortedSegments = unsortedSegments.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();

                if (nameA < nameB) {
                    return -1;
                }

                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            });
        }

        if (segmentsSort === 'dateDesc') {
            sortedSegments = unsortedSegments.sort((a, b) => new Date(b.createdDateTime) - new Date(a.createdDateTime));
        }

        if (segmentsSort === 'dateAsc') {
            sortedSegments = unsortedSegments.sort((a, b) => new Date(a.createdDateTime) - new Date(b.createdDateTime));
        }

        if (segmentsSearchValue) {
            sortedSegments = sortedSegments.filter(e => e.name.toLowerCase().includes(segmentsSearchValue.toLowerCase()));
        }

        if (filterTagIds?.length > 0) {
            sortedSegments = sortedSegments.filter(e => filterTagIds.every((tagId) => e.tags.map(t => t.id).includes(tagId)));
        }

        setSegments([...sortedSegments]);
    }, [segmentsSort, segmentsSearchValue, filterTagIds, unsortedSegments]);

    useEffect(() => {
        if (selectedContactId) {
            setMountDrawer(true);
            return;
        }

        setOpenContact(false);

        handleFetchSuppressedCount();

        const timeout = setTimeout(() => {
            setMountDrawer(false);
        }, theme.transitions.duration.leavingScreen);

        return () => clearTimeout(timeout);
    }, [selectedContactId]);

    if (!hasManageContactsPermission) {
        return (
            <Paper sx={{ padding: 6, textAlign: 'center' }}>
                <Typography>You don't have permission to manage Contacts.</Typography>
                <Typography>Please contact your account administrator to have this enabled.</Typography>
            </Paper>
        );
    }

    return (
        <>
            {!isSmallScreen && (
                <ContactsNavigation
                    drawerWidth={drawerWidth}
                    selectedGroup={selectedGroup}
                    setSelectedGroup={handleSetGroup}
                    selectedSegment={selectedSegment}
                    setSelectedSegment={handleSelectSegment}
                    selectedPseudoGroup={selectedPseudoGroup}
                    setSelectedPseudoGroup={handleSetPseudoGroup}
                    tab={tab}
                    setTab={tab => setTab(tab)}
                    isLoadingNavigation={isLoadingNavigation}
                    setIsLoadingNavigation={isLoadingNavigation => setIsLoadingNavigation(isLoadingNavigation)}
                    onCreateSegment={handleCreateSegment}
                    allContactsCount={allContactsCount}
                    suppressedCount={suppressedGroupCount}
                    ungroupedCount={ungroupedCount}
                    onFilterByTag={handleFilterByTag}
                    groupsSearchValue={groupsSearchValue}
                    groupsTempSearchValue={groupsTempSearchValue}
                    setGroupsTempSearchValue={setGroupsTempSearchValue}
                    segmentsTempSearchValue={segmentsTempSearchValue}
                    setSegmentsTempSearchValue={setSegmentsTempSearchValue}
                    setGroupsSearchValue={searchValue => setGroupsSearchValue(searchValue)}
                    setSegmentsSearchValue={searchValue => setSegmentsSearchValue(searchValue)}
                    onCreateGroup={handleCreateGroup}
                    onUpdateGroup={handleUpdateGroup}
                    onDeleteSegment={handleDeleteSegment}
                    onDeleteGroup={handleDeleteGroup}
                    onUpdateSegmentDetails={handleUpdateSegmentDetails}
                    groups={groups}
                    segments={segments}
                    groupsSort={groupsSort}
                    setGroupsSort={sort => setGroupsSort(sort)}
                    groupType={groupType}
                    setGroupType={type => setGroupType(type)}
                    segmentsSort={segmentsSort}
                    setSegmentsSort={sort => setSegmentsSort(sort)}
                    filterTagIds={filterTagIds}
                />
            )}
            <Box sx={{ marginLeft: isSmallScreen ? '0' : `${drawerWidth}px`, flexGrow: 1 }}>
                <TitleBar
                    title={title}
                    showHamburger={isSmallScreen}
                    onClickHamburger={toggleDrawer(true)}
                    actions={(
                        <>
                            {tab === 0 && (
                                <>
                                    <Button sx={{ marginRight: 2 }} onClick={() => setDialog(
                                        <ImportHistoryDialog
                                            onClose={() => setDialog(null)}
                                        />
                                    )}>
                                        Import History
                                    </Button>
                                    <AddContacts
                                        onImport={() => setDialog(
                                            <ImportContactsDialog
                                                onCreateGroup={handleCreateGroup}
                                                onClose={() => setDialog(null)}
                                                onOpenImportHistory={() => setDialog(
                                                    <ImportHistoryDialog
                                                        onClose={() => setDialog(null)}
                                                    />
                                                )}
                                                {...(selectedGroup !== null && { preSelectedGroups: [selectedGroup.id] })}
                                            />
                                        )}
                                        onManualEntry={() => setDialog(
                                            <AddContactDialog
                                                onClose={() => setDialog(null)}
                                                onSubmit={handleAddContact}
                                                groupName={selectedGroup?.name}
                                            />
                                        )}
                                    />
                                </>
                            )}
                            <Box sx={{ marginLeft: tab === 0 ? 1 : 0 }}>
                                <Settings />
                            </Box>
                        </>
                    )}
                />
                <AlertBar
                    shown={selectedSegment && unsavedChanges}
                    positionTop={128}
                    action={(
                        <Button
                            onClick={handleUpdateSegment}
                            size="small"
                            disabled={isLoading}
                        >
                            Save changes
                        </Button>
                    )}
                    secondaryAction={(
                        <Button
                            onClick={handleRevertSegmentChanges}
                            size="small"
                            disabled={isLoading}
                            variant="outlined"
                        >
                            Revert
                        </Button>
                    )}
                >
                    {isLoading ? 'Updating...' : 'You have unsaved changes.'}
                </AlertBar>
                <Paper sx={{ margin: 2 }}>
                    <ContactsTable
                        data={contacts}
                        selectedPseudoGroup={selectedPseudoGroup}
                        count={count}
                        columns={columns}
                        rowsPerPageOptions={rowsPerPageOptions}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={rowsPerPage => setRowsPerPage(rowsPerPage)}
                        page={page}
                        setPage={page => setPage(page)}
                        onSort={props => handleSortContacts(props)}
                        orderBy={orderBy}
                        orderDesc={orderDesc}
                        onChangeSelection={contacts => setSelectedContacts(contacts)}
                        selectedContacts={selectedContacts}
                        allContactsSelected={allContactsSelected}
                        onToggleAllContactsSelected={allContactsSelected => setAllContactsSelected(allContactsSelected)}
                        filters={filters}
                        setFilters={filters => setFilters(filters)}
                        selectedGroup={selectedGroup}
                        onShowFilters={() => setShowFilters(true)}
                        onSearch={searchValue => setSearchValue(searchValue)}
                        onOpenContact={id => setSelectedContactId(id)}
                        status={status}
                        setStatus={status => setStatus(status)}
                        tab={tab}
                        groups={groups}
                        selectedSegment={selectedSegment}
                        setSelectedSegment={setSelectedSegment}
                        onExport={handleExport}
                        allContactsCount={allContactsCount}
                        searchValue={searchValue}
                        onCopy={() => setDialog(
                            <CopyContactsDialog
                                onClose={() => setDialog(null)}
                                onConfirm={destinationGroupIds => handleUpdateContacts({ operation: 'copy', destinationGroupIds })}
                                onCreateGroup={handleCreateGroup}
                            />
                        )}
                        onMove={() => setDialog(
                            <MoveContactsDialog
                                onClose={() => setDialog(null)}
                                onConfirm={destinationGroupIds => handleUpdateContacts({ operation: 'move', destinationGroupIds })}
                                sourceGroupName={selectedGroup?.name}
                                onCreateGroup={handleCreateGroup}
                            />
                        )}
                        onDeleteFromGroup={() => setDialog(
                            <DeleteContactsDialog
                                group={selectedGroup}
                                deleteFromGroup={true}
                                onClose={() => setDialog(null)}
                                onConfirm={() => handleUpdateContacts({ operation: 'deleteFromGroup' })}
                            />
                        )}
                        onDeleteFromMultipleGroups={() => setDialog(
                            <DeleteContactsDialog
                                groups={groups}
                                deleteFromMultipleGroups={true}
                                onClose={() => setDialog(null)}
                                onConfirm={(destinationGroupIds) => handleUpdateContacts({ operation: 'deleteFromMultipleGroups', destinationGroupIds })}
                            />
                        )}
                        onDeleteFromAccount={() => setDialog(
                            <DeleteContactsDialog
                                group={selectedGroup}
                                deleteFromGroup={false}
                                onClose={() => setDialog(null)}
                                onConfirm={() => handleUpdateContacts({ operation: 'deleteFromAccount' })}
                            />
                        )}
                        onSuppress={() => setDialog(
                            <UnsubscribeSuppressContactsDialog
                                onClose={() => setDialog(null)}
                                onConfirm={(suppressUntilDate) => handleUpdateContacts({ operation: 'suppress', suppressUntilDate })}
                                titleText="Suppress"
                                bodyText="Suppress these contacts? This action is only temporary."
                                operation="Suppress"
                            />
                        )}
                        onUnsuppress={() => setDialog(
                            <UnsubscribeSuppressContactsDialog
                                onClose={() => setDialog(null)}
                                onConfirm={() => handleUpdateContacts({ operation: 'unsuppress' })}
                                titleText="Unsuppress"
                                bodyText="Unsuppressed contacts can receive campaigns from your account."
                            />
                        )}
                        onUnsubscribe={() => setDialog(
                            <UnsubscribeSuppressContactsDialog
                                onClose={() => setDialog(null)}
                                onConfirm={() => handleUpdateContacts({ operation: 'unsubscribe' })}
                                titleText="Unsubscribe"
                                bodyText="Unsubscribed contacts are permanently prevented from receiving campaigns from your account."
                            />
                        )}
                        onWarnNoSelection={() => setDialog(
                            <NoContactsSelectedDialog
                                onClose={() => setDialog(null)}
                            />
                        )}
                        onResubscribe={() => setDialog(
                            <UnsubscribeSuppressContactsDialog
                                onClose={() => setDialog(null)}
                                onConfirm={() => handleUpdateContacts({ operation: 'resubscribe' })}
                                titleText="Resubscribe"
                                bodyText="Resubscribing a contact allows you to send campaigns to them. If the contact themselves initiated the unsubscribe action in the first instance, you could be in breach of UK law if you choose to resubscribe them. Please note that NewZapp cannot be held accountable for your actions."
                            />
                        )}
                        onCreateGroup={handleFetchGroups} // TODO replace with hook
                    />
                </Paper>
            </Box>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                <ContactsNavigation
                    drawerWidth={drawerWidth}
                    selectedGroup={selectedGroup}
                    setSelectedGroup={handleSetGroup}
                    selectedSegment={selectedSegment}
                    setSelectedSegment={handleSelectSegment}
                    selectedPseudoGroup={selectedPseudoGroup}
                    setSelectedPseudoGroup={handleSetPseudoGroup}
                    tab={tab}
                    setTab={tab => setTab(tab)}
                    isLoadingNavigation={isLoadingNavigation}
                    setIsLoadingNavigation={isLoadingNavigation => setIsLoadingNavigation(isLoadingNavigation)}
                    onCreateSegment={handleCreateSegment}
                    allContactsCount={allContactsCount}
                    suppressedCount={suppressedGroupCount}
                    ungroupedCount={ungroupedCount}
                    onFilterByTag={handleFilterByTag}
                    groupsSearchValue={groupsSearchValue}
                    groupsTempSearchValue={groupsTempSearchValue}
                    setGroupsTempSearchValue={setGroupsTempSearchValue}
                    segmentsTempSearchValue={segmentsTempSearchValue}
                    setSegmentsTempSearchValue={setSegmentsTempSearchValue}
                    setGroupsSearchValue={searchValue => setGroupsSearchValue(searchValue)}
                    setSegmentsSearchValue={searchValue => setSegmentsSearchValue(searchValue)}
                    onCreateGroup={handleCreateGroup}
                    onUpdateGroup={handleUpdateGroup}
                    onDeleteSegment={handleDeleteSegment}
                    onDeleteGroup={handleDeleteGroup}
                    onUpdateSegmentDetails={handleUpdateSegmentDetails}
                    groups={groups}
                    segments={segments}
                    groupsSort={groupsSort}
                    setGroupsSort={sort => setGroupsSort(sort)}
                    groupType={groupType}
                    setGroupType={type => setGroupType(type)}
                    segmentsSort={segmentsSort}
                    setSegmentsSort={sort => setSegmentsSort(sort)}
                    filterTagIds={filterTagIds}
                />
            </Drawer>
            <Filters
                open={showFilters}
                isSegment={filtersType === 'segment'}
                columns={columns}
                contactsCount={count}
                contactsTotalCount={totalCount}
                filters={filters}
                setFilters={setFilters}
                onClose={() => setShowFilters(false)}
                suppressedCount={suppressedCount}
                customFields={customFields}
                unsavedChangesToSegment={selectedSegment && unsavedChanges}
                isLoading={isLoading}
                onUpdateSegment={handleUpdateSegment}
                onRevertSegmentChanges={handleRevertSegmentChanges}
                condition={selectedSegment?.condition ?? condition}
                onChangeCondition={selectedSegment ? condition => setSelectedSegment({ ...selectedSegment, condition }) : setCondition}
            />
            {mountDrawer && (
                <Contact
                    onClose={() => setSelectedContactId(null)}
                    id={selectedContactId}
                    open={openContact}
                    onRefresh={handleRefresh}
                    onMount={() => setOpenContact(true)}
                />
            )}
            {(isLoading || isLoadingNavigation || isProcessing) && (
                <LoadingOverlay />
            )}
            {dialog}
        </>
    );
};

export default Contacts;