import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import MuiGrid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import CampaignHeatmap from '../CampaignHeatmap';
import buildUrl from '../../buildUrl';
import Chart from 'chart.js/auto';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { colours } from '../../theme/constants';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ShareIcon from '@mui/icons-material/Share';
import useInterval from '../../hooks/useInterval';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';
import Metric from '../Metric';
import renderPercentage from '../renderPercentage';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import generateChartColours from '../../generateChartColours';
import useApi from '../../hooks/useApi';
import IconTooltip from './../IconTooltip';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import GroupsIcon from '@mui/icons-material/Groups';

const recentActivityFilter = { hour: 1 };

const borderedMetric = {
    backgroundColor: 'transparent',
    textAlign: 'center',
    padding: 2,
    border: '1px solid rgba(255, 255, 255, 0.4)',
    color: '#FFF'
};

const SummaryCardHeader = ({ title, reverse = false, tooltip = null }) => {
    return (
        <>
            <CardHeader
                sx={{ py: 1 }}
                title={<Typography variant="body1">{title}</Typography>}
                action={tooltip ? (
                    <Tooltip
                        title={(
                            <Typography
                                component="span"
                                variant="body2"
                            >
                                {tooltip}
                            </Typography>
                        )}
                        arrow
                    >
                        <HelpIcon color="info" fontSize="small" sx={{ verticalAlign: 'middle' }} />
                    </Tooltip>
                ) : null}
            />
            <Divider sx={reverse ? { borderColor: 'rgba(255, 255, 255, 0.4)' } : null} />
        </>
    );
};

const SocialIcon = ({ platform }) => {
    if (platform === 'Facebook') {
        return <FacebookIcon fontSize="small" sx={{ verticalAlign: 'middle', color: '#4267B2' }} />;
    }

    if (platform === 'LinkedIn') {
        return <LinkedInIcon fontSize="small" sx={{ verticalAlign: 'middle', color: '#0077B5' }} />;
    }

    if (platform === 'Twitter') {
        return <TwitterIcon fontSize="small" sx={{ verticalAlign: 'middle', color: '#1DA1F2' }} />;
    }

    if (platform === 'Teams') {
        return <GroupsIcon fontSize="small" sx={{ verticalAlign: 'middle', color: '#4b53bc' }} />;
    }

    return <ShareIcon fontSize="small" sx={{ verticalAlign: 'middle', color: '#666' }} />;
};

const SocialMetrics = ({ platform, data }) => {
    const cellStyle = { border: 0 };

    const filterSocialData = type => data.find(e => e.type === platform && e.label.includes(type))?.total ?? 0;

    return (
        <Grid item xs={6}>
            <TableContainer>
                <Table size="small" padding="none">
                    <TableBody>
                        {platform !== 'Share' && platform !== 'Teams' && (
                            <TableRow>
                                <TableCell sx={cellStyle}>
                                    <SocialIcon platform={platform} />
                                </TableCell>
                                <TableCell sx={cellStyle}>
                                    {platform === 'Twitter' ? 'Tweets:' : 'Posts:'}
                                </TableCell>
                                <TableCell sx={cellStyle} align="right">
                                    {filterSocialData('Post')}
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            <TableCell sx={cellStyle}>
                                <SocialIcon platform={platform} />
                            </TableCell>
                            <TableCell sx={cellStyle}>
                                Opens:
                            </TableCell>
                            <TableCell sx={cellStyle} align="right">
                                {filterSocialData('Open')}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={cellStyle}>
                                <SocialIcon platform={platform} />
                            </TableCell>
                            <TableCell sx={cellStyle}>
                                Clicks:
                            </TableCell>
                            <TableCell sx={cellStyle} align="right">
                                {filterSocialData('Click')}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

const StyledGrid = styled(MuiGrid)(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& [role="separator"]': {
        margin: theme.spacing(0, 2)
    }
}));

const SubMetric = ({ figure, text, onChangeSubscriberStatusFilter = null }) => {
    const colour = figure > 0 ? 'error' : 'success';

    return (
        <Chip
            label={`${figure} ${text}`}
            color={colour}
            sx={{ mt: 2 }}
            clickable={onChangeSubscriberStatusFilter ? true : false}
            onClick={onChangeSubscriberStatusFilter}
        />
    );
};

const SelectedDelivered = ({ selected, failed, sent, bounced, delivered, onChangeSubscriberStatusFilter }) => {
    return (
        <StyledGrid container>
            <StyledGrid item xs>
                <Box sx={{ textAlign: 'center' }}>
                    <Metric
                        figure={selected}
                        text="Selected"
                    />
                </Box>
            </StyledGrid>
            <Divider orientation="vertical" flexItem>
                <ArrowForwardIcon sx={{ fontSize: 48, color: colours.primary }} />
            </Divider>
            <StyledGrid item xs>
                <Box sx={{ textAlign: 'center' }}>
                    <Metric
                        figure={sent}
                        text="Sent"
                    />
                    <SubMetric
                        figure={failed}
                        text="failed"
                        onChangeSubscriberStatusFilter={() => onChangeSubscriberStatusFilter('failed')}
                    />
                </Box>
            </StyledGrid>
            <Divider orientation="vertical" flexItem>
                <ArrowForwardIcon sx={{ fontSize: 48, color: colours.primary }} />
            </Divider>
            <StyledGrid item xs>
                <Box sx={{ textAlign: 'center' }}>
                    <Metric
                        figure={delivered}
                        text="Delivered"
                    />
                    <SubMetric
                        figure={bounced}
                        text="bounced"
                        onChangeSubscriberStatusFilter={() => onChangeSubscriberStatusFilter('bounced')}
                    />
                </Box>
            </StyledGrid>
        </StyledGrid>
    );
};

const DevicesChart = ({ data, isRealTime = false }) => {
    const colours = generateChartColours(data);
    const canvasId = isRealTime ? 'realTimeDevices' : 'devices';

    const handleBuildChart = () => {
        const chartData = {
            labels: data.map(({ label }) => label),
            datasets: [{
                data: data.map(({ total }) => total),
                backgroundColor: data.map((e, i) => colours[i])
            }]
        };

        const options = {
            plugins: {
                legend: {
                    position: isRealTime ? 'bottom' : 'left',
                    labels: {
                        color: isRealTime ? '#FFF' : 'rgba(0,0,0,0.87)'
                    }
                }
            },
            aspectRatio: 2,
            animations: false
        };

        const devicesChart = document.getElementById(canvasId);

        let chartStatus = Chart.getChart(canvasId);

        chartStatus !== undefined && chartStatus.destroy();

        new Chart(devicesChart, {
            type: 'pie',
            data: chartData,
            options
        });
    };

    useEffect(() => {
        handleBuildChart();
    }, [data]);

    return (
        <>
            <canvas id={canvasId} />
        </>
    );
};

const ActionsChart = ({ data }) => {

    const handleBuildChart = () => {
        const opens = data.filter(e => e.type === 'open');
        const clicks = data.filter(e => e.type === 'click');

        const generateChunks = () => {
            const now = new Date();

            let chunks = [];

            for (let i = 0; i < 12; i++) {
                const chunk = now - (300000 * i);
                const chunkOfTime = new Date(chunk);
                chunks = [...chunks, chunkOfTime];
            }

            return chunks;
        };

        const chunks = generateChunks();

        const actionsPerChunk = data => chunks.map(e => data.filter(a => new Date(a.dateTime) < e && new Date(a.dateTime) > (e - 300000)).length);

        const chartData = {
            labels: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
            datasets: [
                {
                    data: actionsPerChunk(opens),
                    backgroundColor: 'rgba(255, 255, 255, 0.65)'
                },
                {
                    data: actionsPerChunk(clicks),
                    backgroundColor: 'rgba(255, 255, 255, 1)'
                }
            ]
        };

        const options = {
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    displayColors: false,
                    callbacks: {
                        label: ctx => `${ctx.datasetIndex === 0 ? 'Opens' : 'Clicks'}: ${ctx.formattedValue}`,
                        title: ctx => `${ctx[0].label} minutes ago`
                    }
                },
                chartAreaBorder: { //chartJS workaround for missing right hand gridline
                    borderColor: 'rgba(255, 255, 255, 0.4)',
                    borderWidth: 1
                }
            },
            animations: false,
            scales: {
                x: {
                    stacked: true,
                    title: {
                        display: true,
                        text: 'Minutes ago',
                        color: 'rgba(255, 255, 255, 1)'
                    },
                    ticks: {
                        color: 'rgba(255, 255, 255, 1)',
                        max: 60
                    },
                    grid: {
                        color: 'rgba(255, 255, 255, 0.4)'
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                        stepSize: 1,
                        color: 'rgba(255, 255, 255, 1)'
                    },
                    grid: {
                        color: 'rgba(255, 255, 255, 0.4)'
                    }
                }
            }
        };

        const actionsChart = document.getElementById('actionsCanvas');

        let chartStatus = Chart.getChart('actionsCanvas');

        chartStatus !== undefined && chartStatus.destroy();

        const chartAreaBorder = {
            id: 'chartAreaBorder',
            beforeDraw(chart, args, options) {
                const { ctx, chartArea: { left, top, width, height } } = chart;
                ctx.save();
                ctx.strokeStyle = options.borderColor;
                ctx.lineWidth = options.borderWidth;
                ctx.setLineDash(options.borderDash || []);
                ctx.lineDashOffset = options.borderDashOffset;
                ctx.strokeRect(left, top, width, height);
                ctx.restore();
            }
        };

        new Chart(actionsChart, {
            type: 'bar',
            data: chartData,
            options,
            plugins: [chartAreaBorder]
        });
    };

    useEffect(() => {
        data && handleBuildChart();
    }, [data]);

    if (!data) {
        return <CircularProgress />;
    }

    return <canvas id="actionsCanvas" />;
};

const InfoButton = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <IconButton onClick={handleClick} edge="end" size="medium">
                <IconTooltip title="The 'Contacts Selected' figure shown here may not seem to match the total number of Contacts selected as your Audience during the Campaign Sending process. Please click to learn why this might be." />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Box sx={{ padding: 2, width: '250px' }}>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        - A Contact may appear in more than one Group but will only be selected to be sent to <b>once</b> in a campaign.
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        - Any 'Suppressed' Contacts will <b>not</b> be selected to be sent to.
                    </Typography>
                </Box>
            </Popover>
        </>
    );
};

const NewSummary = ({ campaign, isTabSelected, isReport = false, parentIsLoading = null, setParentIsLoading = null, onChangeSubscriberStatusFilter = null }) => {
    const [devices, setDevices] = useState([]);
    const [realTimeDevices, setRealTimeDevices] = useState([]);
    const [socialSummary, setSocialSummary] = useState(null);
    const [recentActions, setRecentActions] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { handleGet } = useApi();

    const handleFetchDevices = async () => {
        const url = buildUrl(`reports/${campaign.id}/devices`);
        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        setDevices(await response.json());
    };

    const handleFetchSocialSummary = async () => {
        const url = `reports/${campaign.id}/social`;
        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        setSocialSummary(await response.json());
    };

    const handleFetchRecentActions = async () => {
        const url = buildUrl(`reports/${campaign.id}/actions`, recentActivityFilter);
        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        setRecentActions(await response.json());
    };

    const handleFetchRealTimeDevices = async () => {
        const url = buildUrl(`reports/${campaign.id}/devices`, recentActivityFilter);
        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        setRealTimeDevices(await response.json());
    };

    const handleFetchRealTimeData = async () => {
        await handleFetchRecentActions();
        await handleFetchRealTimeDevices();
    };

    const intervalRef = useInterval(() => {
        if (isTabSelected && !isReport) {
            handleFetchRealTimeData();
            return;
        }
        else {
            clearInterval(intervalRef.current);
            return;
        }
    }, 60000);

    useEffect(() => {
        handleFetchSocialSummary();
        handleFetchRecentActions();
        handleFetchRealTimeDevices();
        handleFetchDevices();
    }, []);

    useEffect(() => {
        if (recentActions && devices && realTimeDevices && socialSummary) {
            if (setParentIsLoading) {
                setParentIsLoading(false);
                return;
            }

            setIsLoading(false);
        }
    }, [recentActions, devices, realTimeDevices, socialSummary]);

    useEffect(() => {
        !isTabSelected && clearInterval(intervalRef.current);
    }, [isTabSelected]);

    if (parentIsLoading === null && isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    if (parentIsLoading) {
        return <Skeleton variant="rectangular" height={100} />;
    }

    return (
        <Box sx={{ margin: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={isReport ? 12 : 8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent sx={{ display: 'flex' }}>
                                    <SelectedDelivered
                                        selected={campaign.selected}
                                        sent={campaign.sent}
                                        failed={campaign.failed}
                                        bounced={campaign.bounced}
                                        delivered={campaign.sent - campaign.bounced}
                                        onChangeSubscriberStatusFilter={onChangeSubscriberStatusFilter}
                                    />
                                    <Typography>
                                        <InfoButton />
                                    </Typography>

                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={isReport ? 12 : 6}>
                            <Grid container spacing={2}>
                                <Grid item xs={isReport ? 6 : 12}>
                                    <Card>
                                        <CardActionArea onClick={() => onChangeSubscriberStatusFilter('opened')}>
                                            <SummaryCardHeader title="Opens" />
                                            <CardContent>
                                                <Grid container spacing={2} justifyContent="center">
                                                    <Grid item xs={12} xl={6} sx={{ textAlign: 'center' }}>
                                                        <Metric
                                                            figure={campaign.uniqueOpens}
                                                            percentage={renderPercentage(campaign.uniqueOpens, campaign.selected)}
                                                            text="Unique Opens"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} xl={6} sx={{ textAlign: 'center' }}>
                                                        <Metric
                                                            figure={campaign.totalOpens}
                                                            percentage={renderPercentage(campaign.totalOpens, campaign.selected)}
                                                            text="Total Opens"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                                <Grid item xs={isReport ? 6 : 12}>
                                    <Card>
                                        {campaign.numberOfLinks > 0 ? (
                                            <CardActionArea onClick={() => onChangeSubscriberStatusFilter('clicked')}>
                                                <SummaryCardHeader title="Clicks" />
                                                <CardContent>
                                                    <Grid container spacing={2} justifyContent="center">
                                                        <Grid item xs={12} xl={6} sx={{ textAlign: 'center' }}>
                                                            <Metric
                                                                figure={campaign.uniqueClicks}
                                                                percentage={renderPercentage(campaign.uniqueClicks, campaign.uniqueOpens)}
                                                                text="Unique Clicks"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} xl={6} sx={{ textAlign: 'center' }}>
                                                            <Metric
                                                                figure={campaign.totalClicks}
                                                                percentage={renderPercentage(campaign.totalClicks, campaign.totalOpens)}
                                                                text="Total Clicks"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </CardActionArea>
                                        ) : (
                                            <>
                                                <SummaryCardHeader title="Clicks" />
                                                <CardContent>
                                                    <Typography>No Links</Typography>
                                                </CardContent>
                                            </>
                                        )}
                                    </Card>
                                </Grid>
                                <Grid item xs={isReport ? 6 : 12}>
                                    <Card>
                                        <CardActionArea onClick={() => onChangeSubscriberStatusFilter('unsubscribed')}>
                                            <SummaryCardHeader title="Unsubscribes" />
                                            <CardContent>
                                                <Box sx={{ textAlign: 'center' }}>
                                                    <Metric
                                                        figure={campaign.unsubscribes}
                                                        percentage={renderPercentage(campaign.unsubscribes, campaign.selected)}
                                                        text="Unsubscribes"
                                                    />
                                                </Box>
                                            </CardContent>
                                        </CardActionArea>
                                        {campaign.feedback.length !== 0 &&
                                            (
                                                <Card sx={{ cursor: 'default', pointerEvents: 'none' }}>
                                                    <CardContent>
                                                        <TableContainer>
                                                            <Table size="small">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell component="th">
                                                                    Feedback
                                                                        </TableCell>
                                                                        <TableCell component="th">
                                                                    Submissions
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {campaign.feedback
                                                                        .map((feedback, index) => (
                                                                            <TableRow key={index}>
                                                                                <TableCell>
                                                                                    {feedback.feedbackText}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {feedback.total}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </CardContent>
                                                </Card>
                                            )
                                        }
                                    </Card>
                                </Grid>
                                <Grid item xs={isReport ? 6 : 12}>
                                    <Card>
                                        <SummaryCardHeader title="Social" />
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <SocialMetrics
                                                    platform="Facebook"
                                                    data={socialSummary}
                                                />
                                                <SocialMetrics
                                                    platform="Twitter"
                                                    data={socialSummary}
                                                />
                                                <SocialMetrics
                                                    platform="LinkedIn"
                                                    data={socialSummary}
                                                />
                                                <SocialMetrics
                                                    platform="Teams"
                                                    data={socialSummary}
                                                />
                                                <SocialMetrics
                                                    platform="Share"
                                                    data={socialSummary}
                                                />
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        {!isReport && (
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Card>
                                            <SummaryCardHeader title="Peak Engagement Heatmap" tooltip="Unique Opens data is used to show peak engagement" />
                                            <CardContent>
                                                <CampaignHeatmap campaignId={campaign.id} />
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card>
                                            <SummaryCardHeader title="Device Type" />
                                            <CardContent>
                                                {devices && devices.length > 0 ? (
                                                    <DevicesChart data={devices} />
                                                ) : (
                                                    <Typography variant="body2">No activity</Typography>
                                                )}
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {!isReport && (
                    <Grid item xs={12} md={isReport ? 12 : 4}>
                        <Card sx={{ bgcolor: colours.primary, color: '#FFF' }}>
                            <SummaryCardHeader title="Real-time Activity" reverse={true} />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Paper sx={borderedMetric} elevation={0}>
                                            <Metric
                                                figure={recentActions ? recentActions.filter(e => e.type === 'open')?.length ?? 0 : 0}
                                                text="Emails Open"
                                                reverse={true}
                                            />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Paper sx={borderedMetric} elevation={0}>
                                            <Metric
                                                figure={recentActions ? recentActions.filter(e => e.type === 'click')?.length ?? 0 : 0}
                                                text="Clicks"
                                                reverse={true}
                                            />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">Campaign Actions - Last Hour</Typography>
                                        <ActionsChart data={recentActions} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.4)' }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">Device Type - Last Hour</Typography>
                                        {realTimeDevices && realTimeDevices.length > 0 ? (
                                            <DevicesChart data={realTimeDevices} isRealTime={true} />
                                        ) : (
                                            <Typography variant="body2">No activity in the last hour</Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default NewSummary;