import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import FacebookIcon from '@mui/icons-material/Facebook';
import ShareIcon from '@mui/icons-material/Share';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Skeleton from '@mui/material/Skeleton';
import IconTooltip from '../IconTooltip';
import GroupsIcon from '@mui/icons-material/Groups';
import renderPercentage from '../renderPercentage';
import useApi from '../../hooks/useApi';

const getColor = type => {
    const colours = [
        { name: 'Twitter', colour: '#1DA1F2' },
        { name: 'Facebook', colour: '#4267B2' },
        { name: 'LinkedIn', colour: '#0077B5' },
        { name: 'Share', colour: '#444' },
        { name: 'Teams', colour: '#4b53bc' }
    ];

    return colours.find(e => e.name === type).colour;
};

const SocialTable = ({ type, totals, campaignUniqueOpens }) => {
    return (
        <TableContainer>
            <Table size="small">
                <TableBody>
                    {type !== 'Share' && type !== 'Teams' && (
                        <TableRow>
                            <TableCell component="th" width="100%">{type === 'Twitter' ? 'Tweets' : 'Shares'}</TableCell>
                            <TableCell align="right">{totals.shares}</TableCell>
                            <TableCell align="right">{renderPercentage(totals.shares, campaignUniqueOpens)}</TableCell>
                            <TableCell>
                                <IconTooltip title="Social Shares % - Number of Shares / Unique Email Opens" />
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell component="th" width="100%">Opens</TableCell>
                        <TableCell align="right">{totals.opens}</TableCell>
                        <TableCell align="right">{renderPercentage(totals.opens, totals.shares)}</TableCell>
                        <TableCell>
                            <IconTooltip title="Social Opens % - Number of Social Opens / Total Social Shares" />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" width="100%" sx={{ border: 0 }}>Clicks</TableCell>
                        <TableCell align="right" sx={{ border: 0 }}>{totals.clicks}</TableCell>
                        <TableCell align="right" sx={{ border: 0 }}>{renderPercentage(totals.clicks, totals.opens)}</TableCell>
                        <TableCell sx={{ border: 0 }}>
                            <IconTooltip title="Social Clicks % - Number of Social Clicks / Total Social Opens" />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const SocialCard = ({ type, actions, isReport, campaignUniqueOpens }) => {
    const getTotal = type => {
        const actionType = actions.find(e => e.label.includes(type));

        return actionType ? actionType.total : 0;
    };

    const getIcon = type => {
        if (type === 'Facebook') {
            return <FacebookIcon fontSize="large" />;
        }

        if (type === 'LinkedIn') {
            return <LinkedInIcon fontSize="large" />;
        }

        if (type === 'Twitter') {
            return <TwitterIcon fontSize="large" />;
        }

        if (type === 'Share') {
            return <ShareIcon fontSize="large" />;
        }

        if (type === 'Teams') {
            return <GroupsIcon fontSize="large" />;
        }

        return null;
    };

    const totals = {
        shares: getTotal('Post'),
        opens: getTotal('Open'),
        clicks: getTotal('Click')
    };

    const totalActivity = Object.values(totals).reduce((a, b) => a + b, 0);

    return (
        <Grid item xs={isReport ? 6 : 12} md={6} lg={4}>
            <Card>
                <CardHeader
                    title={type === 'Share' ? 'Share Link' : type}
                    sx={{
                        backgroundColor: getColor(type),
                        color: '#FFF',
                        '@media print': {
                            backgroundColor: getColor(type),
                            color: '#FFF'
                        }
                    }}
                    action={getIcon(type)}
                />
                <CardContent>
                    <SocialTable actions={actions} type={type} totals={totals} campaignUniqueOpens={campaignUniqueOpens} />
                </CardContent>
                <CardActions sx={{ backgroundColor: getColor(type), color: '#FFF' }}>
                    <Box component="span" sx={{ fontWeight: 'bold' }}>Total Activity: {totalActivity}</Box>
                </CardActions>
            </Card>
        </Grid>
    );
};

const Social = ({ campaignId, campaignUniqueOpens, isReport = false, parentIsLoading = null, setParentIsLoading = null }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [socialSummary, setSocialSummary] = useState(null);
    const { handleGet } = useApi();

    const handleGetSocialSummary = async () => {
        const url = `reports/${campaignId}/social`;
        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        setSocialSummary(await response.json());
    };

    useEffect(() => {
        handleGetSocialSummary();
    }, []);

    useEffect(() => {
        if (socialSummary) {
            if (setParentIsLoading) {
                setParentIsLoading(false);
                return;
            }

            setIsLoading(false);
        }
    }, [socialSummary]);

    if (parentIsLoading === null && isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    if (parentIsLoading) {
        return <Skeleton variant="rectangular" height={100} />;
    }

    return (
        <Box sx={{ margin: 3 }}>
            <Grid container spacing={3}>
                <SocialCard
                    type="Facebook"
                    actions={socialSummary.filter(e => e.type === 'Facebook')}
                    isReport={isReport}
                    campaignUniqueOpens={campaignUniqueOpens}
                />
                <SocialCard
                    type="Twitter"
                    actions={socialSummary.filter(e => e.type === 'Twitter')}
                    isReport={isReport}
                    campaignUniqueOpens={campaignUniqueOpens}
                />
                <SocialCard
                    type="LinkedIn"
                    actions={socialSummary.filter(e => e.type === 'LinkedIn')}
                    isReport={isReport}
                    campaignUniqueOpens={campaignUniqueOpens}
                />
                <SocialCard
                    type="Teams"
                    actions={socialSummary.filter(e => e.type === 'Teams')}
                    isReport={isReport}
                    campaignUniqueOpens={campaignUniqueOpens}
                />
                <SocialCard
                    type="Share"
                    actions={socialSummary.filter(e => e.type === 'Share')}
                    isReport={isReport}
                    campaignUniqueOpens={campaignUniqueOpens}
                />
            </Grid>
        </Box>
    );
};

export default Social;