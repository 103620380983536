import React, { useEffect, useState, useRef } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import ReactECharts from 'echarts-for-react';
import usePrevious from '../../hooks/usePrevious';

const SubjectLengthEngagementChart = ({ a, b, periodColours }) => {
    const ref = useRef(null);
    const theme = useTheme();
    const [series, setSeries] = useState(null);
    const prevSeries = usePrevious(series);
    const padding = theme.spacing(7);
    const chartHeight = 400;

    const options = {
        grid: {
            containLabel: true,
            left: padding,
            top: padding,
            right: padding,
            bottom: padding
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        yAxis: {
            name: 'Subject Line Word Count',
            nameGap: 32,
            nameLocation: 'center'
        },
        xAxis: {
            name: 'Open Rate',
            nameGap: 32,
            nameLocation: 'center',
            axisLabel: {
                formatter: '{value}%'
            }
        },
        series
    };

    useEffect(() => {
        let series = [
            {
                type: 'line',
                smooth: true,
                name: 'Period A',
                data: a.subjectLineLengthByOpenRate?.map(e => [e.openRate, e.subjectWordCount]) ?? [],
                color: periodColours[0],
                markPoint: {
                    data: [
                        { type: 'max', name: 'Max' },
                        { type: 'min', name: 'Min' }
                    ]
                }
            }
        ];

        if (b) {
            const bData = {
                type: 'line',
                smooth: true,
                name: 'Period B',
                data: b.subjectLineLengthByOpenRate?.map(e => [e.openRate, e.subjectWordCount]) ?? [],
                color: periodColours[1],
                markPoint: {
                    data: [
                        { type: 'max', name: 'Max' },
                        { type: 'min', name: 'Min' }
                    ]
                }
            };

            series.push(bData);
        }

        setSeries(series);
    }, [a, b]);

    useEffect(() => {
        // replaceMerge param is necessary to remove the 2nd dataset

        if (ref?.current && series && (series.length < prevSeries?.length)) {
            ref.current?.getEchartsInstance().setOption(options, { replaceMerge: ['series'] });
        }
    }, [series]);

    return (
        <ReactECharts
            option={options}
            style={{ height: chartHeight }}
            opts={{ renderer: 'svg' }}
            ref={ref}
        />
    );
};

export default SubjectLengthEngagementChart;
