import React, { useState, useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Dashboard from './Dashboard';
import Box from '@mui/material/Box';
import WebFont from 'webfontloader';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import useApi from '../hooks/useApi';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import useAccount from '../hooks/useAccount';
import Page from './Page';
import Email from './Email';

const Reports = () => {
    const { path } = useRouteMatch();
    const { handleGet } = useApi();
    const [fonts, setFonts] = useState(null);
    const { user } = useAccount();

    const hasManageReportsPermission = user.permissions.includes('Manage Reports');

    const handleFetchCustomFonts = async () => {
        const response = await handleGet('fonts');

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setFonts(data);
    };

    const handleLoadFonts = font => {
        if (font.type === 'google') {
            WebFont.load({
                google: {
                    families: [font.name]
                }
            });
        }
    };

    useEffect(() => {
        (fonts && fonts.length > 0) && fonts.forEach((font) => handleLoadFonts(font));
    }, [fonts]);

    useEffect(() => {
        if (hasManageReportsPermission) {
            handleFetchCustomFonts();
        }
    }, []);

    if (!hasManageReportsPermission) {
        return (
            <Paper sx={{ padding: 6, textAlign: 'center' }}>
                <Typography>You don't have permission to manage Reports.</Typography>
                <Typography>Please contact your account administrator to have this enabled.</Typography>
            </Paper>
        );
    }

    if (!fonts) {
        return <LoadingOverlay />;
    }

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Box>
                    <Dashboard />
                </Box>
            </Route>
            <Route exact path={`${path}/email/:id`}>
                <Email />
            </Route>
            <Route exact path={`${path}/page/:id`}>
                <Page />
            </Route>
        </Switch>
    );
};

export default Reports;