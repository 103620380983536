import React, { useState } from 'react';
import { IconButton, Popover, Box } from '@mui/material';
import { Help } from '@mui/icons-material';

const BetterTooltip = ({ children }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'popover-tooltip' : undefined;

    return (
        <>
            <IconButton onClick={handleClick} color="info" size="small">
                <Help fontSize="inherit" />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Box p={2}>
                    {children}
                </Box>
            </Popover>
        </>
    );
};

export default BetterTooltip;