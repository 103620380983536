import React, { useState, useEffect } from 'react';
import { Stack, Paper, Typography, Table, TableBody, TableCell, TableRow, TableContainer, TableHead, Box, TableFooter, Collapse } from '@mui/material';
import useApi from '../../hooks/useApi';
import buildUrl from '../../buildUrl';
import UnsubscribeFeedbackChart from './UnsubscribeFeedbackChart';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import IconTooltip from '../../reports/IconTooltip';

const Feedback = () => {
    const { handleGet } = useApi();
    const [feedback, setFeedback] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [submissions, setSubmissions] = useState(0);

    const handleLoadItems = async () => {
        const url = buildUrl('account/feedback');
        const response = await handleGet(url);

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        let data = await response.json();

        // if 'Other' exists, move to the end
        const other = data.find(e => e.feedbackText.toLowerCase() === 'other');

        if (other) {
            data = [...data.filter(a => a !== other), other];
        }

        setFeedback(data);
        setIsLoading(false);
    };

    useEffect(() => {
        handleLoadItems();
    }, []);

    useEffect(() => {
        feedback && setSubmissions(feedback?.reduce((n, { total }) => n + total, 0) ?? 0);
    }, [feedback]);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <Stack>
            <Paper sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography component="h2" variant="h6" gutterBottom sx={{ flexGrow: 1 }}>
                            Unsubscribe Feedback
                    </Typography>
                    <IconTooltip
                        title="This screen anonymously summarises stats from Unsubscribers who have chosen to leave feedback"
                    />
                </Box>
                <Paper>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell component="th">Feedback</TableCell>
                                    <TableCell component="th" align="right">Submissions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {feedback?.map(e => (
                                    <TableRow key={e.feedbackText}>
                                        <TableCell>{e.feedbackText}</TableCell>
                                        <TableCell align="right">{e.total}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell component="th" sx={{ fontWeight: 'bold', color: 'black', fontSize: 14 }}>Total Submissions</TableCell>
                                    <TableCell align="right" sx={{ fontWeight: 'bold', color: 'black', fontSize: 14 }}>{submissions}</TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Paper>
                <Collapse in={submissions !== 0}>
                    <UnsubscribeFeedbackChart feedback={feedback} />
                </Collapse>
            </Paper>
        </Stack>

    );
};

export default Feedback;