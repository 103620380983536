import React, { useState, useEffect } from 'react';
import { Typography, Button, Drawer, Box, Chip, List, ListItem, Stack, FormControl, FormControlLabel, Switch, Toolbar, IconButton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/Circle';
import PeriodSelect from '../account/reportingPeriod/PeriodSelect';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import useTheme from '@mui/material/styles/useTheme';
import { lighten } from '@mui/system';
import RenderPeriodDateRange from '../account/reportingPeriod/RenderPeriodDateRange';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';

const xScroll = {
    '&.MuiPickersLayout-root': {
        overflow: 'auto'
    }
};

const datePickerToolbarStyle = {
    '&.MuiPickersLayout-toolbar': {
        gridColumn: 2,
        gridRow: 1
    }
};

const DayStyle = colour => {
    return {
        '&.MuiDateRangePickerDay-root .Mui-selected': {
            color: '#fff',
            backgroundColor: colour,
            borderColor: colour,
            ':hover': {
                color: '#fff',
                backgroundColor: colour,
                borderColor: colour
            },
            ':select': {
                color: '#fff',
                backgroundColor: colour,
                borderColor: colour
            }
        },
        '&.MuiDateRangePickerDay-root.MuiDateRangePickerDay-hiddenDayFiller': {
            color: 'inherit',
            backgroundColor: 'inherit',
            borderColor: 'inherit'
        },
        '&.Mui-selected': {
            color: '#fff',
            backgroundColor: colour,
            borderColor: colour
        },
        '&.MuiDateRangePickerDay-rangeIntervalDayHighlight': {
            backgroundColor: lighten(colour, 0.7)
        }
    };
};

const GetCompareShortcutItems = (valueA) => {
    return [
        {
            label: 'Preceding period',
            getValue: () => {
                const length = valueA[1].diff(valueA[0], 'day');
                const start = valueA[0].subtract(length, 'day');
                const end = valueA[0].subtract(1, 'day');

                return [start, end];
            }
        },
        {
            label: 'Same period last year',
            getValue: () => {
                const start = valueA[0].subtract(1, 'year');
                const end = valueA[1].subtract(1, 'year');

                return [start, end];
            }
        }
    ];
};

const shortcutItems = [
    {
        label: 'This week',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('week'), today.endOf('week')];
        }
    },
    {
        label: 'Last week',
        getValue: () => {
            const today = dayjs();
            const prevWeek = today.subtract(7, 'day');
            return [prevWeek.startOf('week'), prevWeek.endOf('week')];
        }
    },
    {
        label: 'Last 7 days',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(7, 'day'), today];
        }
    },
    {
        label: 'Last 28 days',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(28, 'day'), today];
        }
    },
    {
        label: 'Last 30 days',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(30, 'day'), today];
        }
    },
    {
        label: 'Last 90 days',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(90, 'day'), today];
        }
    },
    {
        label: 'Current month',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('month'), today.endOf('month')];
        }
    },
    {
        label: 'Last month',
        getValue: () => {
            const today = dayjs();
            const endOfLastMonth = today.startOf('month').subtract(1, 'day');
            return [endOfLastMonth.startOf('month'), endOfLastMonth];
        }
    },
    {
        label: 'Last 12 months',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(12, 'month'), today];
        }
    },
    {
        label: 'This year (Jan - today)',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('year'), today];
        }
    }
];

const Shortcuts = ({ props, reportingPeriods, selectedPeriods, isCompare = false }) => {
    const { items, onChange, isValid, changeImportance = 'set' } = props;
    const theme = useTheme();

    if (items === null || items.length === 0) {
        return null;
    }

    const resolvedItems = items.map((item) => {
        const newValue = item.getValue({ isValid });

        return {
            label: item.label,
            onClick: () => {
                onChange(newValue, changeImportance, item);
            },
            disabled: !isValid(newValue)
        };
    });

    const handleChange = dates => {
        const period = [dayjs(dates[0]), dayjs(dates[1])];

        onChange(period, changeImportance, { label: 'Custom' });
    };

    return (
        <Box
            sx={{
                gridArea: '1 / 1 / 3',
                borderRight: 1,
                borderColor: theme.palette.divider,
                p: 1,
                pl: 0
            }}
        >
            <Box
                sx={{
                    maxHeight: 430, // height of calendar + toolbar, be better if we didn't have to specify
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '6px'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        borderRadius: '3px'
                    }
                }}
            >
                <Box p={2} pb={1}>
                    <PeriodSelect
                        reportingPeriods={reportingPeriods}
                        selectedPeriods={selectedPeriods}
                        onSetPeriod={period => handleChange(period)}
                        isCompare={isCompare}
                    />
                </Box>
                <List dense>
                    {resolvedItems.map((item) => {
                        return (
                            <ListItem key={item.label}>
                                <Chip {...item} />
                            </ListItem>
                        );
                    })}
                </List>
            </Box>
        </Box>
    );
};

const DatesSelector = ({ reportingPeriods, selectedPeriods, onSetSelectedPeriods, compare, onSetCompare, periodColours }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [valueA, setValueA] = useState([dayjs(new Date(selectedPeriods[0][0])), dayjs(new Date(selectedPeriods[0][1]))]);
    const [valueB, setValueB] = useState(null);
    const [valueAShortcutSelected, setValueAShortcutSelected] = useState(null);
    const [valueBShortcutSelected, setValueBShortcutSelected] = useState(null);
    const mediumAndUp = useMediaQuery(theme.breakpoints.up('md'));
    const xxlAndUp = useMediaQuery(theme.breakpoints.up('xxl'));

    const handleChangeA = (newValue, ctx) => {
        setValueA(newValue);
        setValueAShortcutSelected(ctx.shortcut);
    };

    const handleChangeB = (newValue, ctx) => {
        setValueB(newValue);
        setValueBShortcutSelected(ctx.shortcut);
    };

    useEffect(() => {
        let values = [];

        if (valueA?.length > 1 && valueA[1] !== null) {
            values.push([valueA[0].toDate(), valueA[1].toDate()]);
        }

        if (compare && valueB?.length > 1 && valueB[1] !== null) {
            values.push([valueB[0].toDate(), valueB[1].toDate()]);
        }

        values.length > 0 && onSetSelectedPeriods(values);
    }, [valueA, valueB, compare]);

    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                endIcon={<ArrowDropDownIcon sx={{ color: theme.palette.text.secondary }} />}
                variant="text"
                display="flex"
                size="small"
            >
                <Box sx={{ display: 'block' }}>
                    <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'flex-start' }} spacing={1}>
                        <CircleIcon
                            sx={{
                                fontSize: 12,
                                color: periodColours[0],
                                mr: 1
                            }}
                        />
                        <Typography
                            variant="body2"
                            component="div"
                            sx={{
                                m: 0,
                                p: 0,
                                lineHeight: 1,
                                color: theme.palette.text.secondary
                            }}
                        >
                            <RenderPeriodDateRange
                                value1={selectedPeriods[0][0]}
                                value2={selectedPeriods[0][1]}
                            />
                        </Typography>
                        {valueAShortcutSelected && (
                            <Chip
                                size="small"
                                label={valueAShortcutSelected.label}
                                sx={{ fontWeight: 'normal' }}
                            />
                        )}
                    </Stack>
                    {(compare && selectedPeriods.length > 1) && (
                        <Stack direction="row" sx={{ alignItems: 'center', mt: 0.5, justifyContent: 'flex-start' }} spacing={1}>
                            <CircleIcon
                                sx={{
                                    fontSize: 12,
                                    color: periodColours[1],
                                    mr: 1
                                }}
                            />
                            <Typography
                                variant="body2"
                                component="div"
                                sx={{
                                    m: 0,
                                    p: 0,
                                    lineHeight: 1,
                                    color: theme.palette.text.secondary
                                }}
                            >
                                <RenderPeriodDateRange
                                    value1={selectedPeriods[1][0]}
                                    value2={selectedPeriods[1][1]}
                                />
                            </Typography>
                            {valueBShortcutSelected && (
                                <Chip
                                    size="small"
                                    label={valueBShortcutSelected.label}
                                    sx={{ fontWeight: 'normal' }}
                                />
                            )}
                        </Stack>
                    )}
                </Box>
            </Button>
            <Drawer
                open={open}
                onClose={() => setOpen(false)}
                anchor="top"
                sx={{
                    zIndex: theme.zIndex.drawer + 3
                }}
                PaperProps={{
                    sx: {
                        overflow: mediumAndUp ? 'hidden' : 'scroll'
                    }
                }}
            >
                <Toolbar sx={{ display: 'flex' }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <FormControl
                            size="small"
                            margin="none"
                        >
                            <FormControlLabel
                                control={(
                                    <Switch
                                        checked={compare}
                                        onChange={event => onSetCompare(event.target.checked)}
                                        inputProps={{ 'aria-label': 'Compare' }}
                                    />
                                )}
                                label="Compare"
                                labelPlacement="end"
                            />
                        </FormControl>
                    </Box>
                    <IconButton onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid
                        container
                        sx={mediumAndUp ? {
                            '--Grid-borderWidth': '1px',
                            borderColor: 'divider',
                            '& > div:first-of-type': {
                                borderRight: 'var(--Grid-borderWidth) solid',
                                borderColor: 'divider'
                            }
                        } : undefined}
                    >
                        <Grid xs={12} md={compare ? 6 : 12}>
                            <Box px={2} py={1} sx={{ backgroundColor: periodColours[0] }}>
                                <Typography variant="body1" sx={{ color: '#FFFFFF' }}>
                                    Period{compare ? ' A' : ''}
                                </Typography>
                            </Box>
                            <StaticDateRangePicker
                                sx={xScroll}
                                slots={{
                                    shortcuts: props => <Shortcuts props={props} reportingPeriods={reportingPeriods} selectedPeriods={selectedPeriods} />
                                }}
                                slotProps={{
                                    shortcuts: {
                                        items: shortcutItems
                                    },
                                    actionBar: { actions: [] },
                                    day: {
                                        sx: DayStyle(periodColours[0])
                                    },
                                    toolbar: {
                                        sx: datePickerToolbarStyle
                                    }
                                }}
                                onChange={handleChangeA}
                                value={valueA}
                                calendars={xxlAndUp ? 2 : compare ? 1 : 2}
                            />

                        </Grid>
                        {compare && (
                            <Grid xs={12} md={6}>
                                <Box px={2} py={1} sx={{ backgroundColor: periodColours[1] }}>
                                    <Typography variant="body1" sx={{ color: '#FFFFFF' }}>
                                        Period B
                                    </Typography>
                                </Box>
                                <StaticDateRangePicker
                                    sx={xScroll}
                                    slots={{
                                        shortcuts: props => <Shortcuts props={props} reportingPeriods={reportingPeriods} selectedPeriods={selectedPeriods} isCompare={true} />
                                    }}
                                    slotProps={{
                                        shortcuts: {
                                            items: GetCompareShortcutItems(valueA)
                                        },
                                        actionBar: { actions: [] },
                                        day: {
                                            sx: DayStyle(periodColours[1])
                                        },
                                        toolbar: {
                                            sx: datePickerToolbarStyle
                                        }
                                    }}
                                    onChange={handleChangeB}
                                    value={valueB}
                                    calendars={xxlAndUp ? 2 : 1}
                                />
                            </Grid>
                        )}
                    </Grid>
                </LocalizationProvider>
            </Drawer>
        </>
    );
};

export default DatesSelector;