import React from 'react';
import { createContext, useState, useEffect } from 'react';
import uiGuidedSteps from '../uiGuidedSteps.json';
import Joyride, { STATUS } from 'react-joyride';
import useApi from '../hooks/useApi';

const TourContext = createContext();

const TourProvider = ({ children }) => {
    const [runTour, setRunTour] = useState(false);
    const [steps, setSteps] = useState([]);
    const [tourStatus, setTourStatus] = useState('');
    const { handlePut } = useApi();
    const [tourPath, setTourPath] = useState(null);

    const handleCompleteTour = async (tourName) => {
        if (tourName) {
            await handlePut('user/tour', { tourName });
        }
    };

    const handleRunTour = ({ path }) => {
        setTourPath(path);

        if (uiGuidedSteps[path]) {
            const steps = uiGuidedSteps[path]
                .filter(step => document.querySelector(step.target))
                .map((step, index) => ({
                    ...step,
                    disableBeacon: index === 0
                }));

            setSteps(steps);
            setRunTour(true);
        }
        else {
            setSteps([]);
            setTourPath(null);
            setRunTour(false);
        }
    };

    const handleTourCallback = (data) => {
        const { status, action } = data;

        if (action === 'close') {
            setRunTour(false);
            setTourPath(null);
            return;
        }

        if (status === STATUS.SKIPPED) {
            return;
        }

        setTourStatus(status);
    };

    useEffect(() => {
        setSteps((currentSteps) =>
            currentSteps.map((step) => ({
                ...step
            }))
        );
    }, [tourPath]);

    useEffect(() => {
        if (tourStatus === STATUS.FINISHED) {
            setRunTour(false);
            handleCompleteTour(tourPath);
            setTourPath(null);
        }
    }, [tourStatus]);

    return (
        <TourContext.Provider value={{
            handleCompleteTour,
            handleRunTour
        }}>
            <Joyride
                steps={steps}
                run={runTour}
                callback={handleTourCallback}
                continuous
                scrollToFirstStep
                showProgress
                disableCloseOnEsc
                disableOverlayClose
                locale={{ last: 'Complete', open: 'Continue UI Tour' }}
                styles={{
                    options: {
                        zIndex: 10000,
                        beaconSize: 36
                    },
                    tooltipContent: {
                        textAlign: 'left',
                        paddingBottom: '0px',
                        paddingLeft: '0px',
                        paddingRight: '0px'
                    },
                    tooltipTitle: {
                        textAlign: 'left',
                        marginTop: '10px',
                        paddingBottom: '0px'
                    }
                }}
            />
            {children}
        </TourContext.Provider>
    );
};

export { TourProvider, TourContext };