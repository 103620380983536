import React from 'react';
import { Divider, Card, CardHeader, CardContent } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import RenderPeriodDateRange from './RenderPeriodDateRange';
import { Chip } from '@mui/material';

const Period = ({ period, dates, totalPeriods, calendarCount }) => {
    return (
        <Card>
            <CardHeader
                sx={{ pb: 1 }}
                title={(
                    <RenderPeriodDateRange
                        value1={dates[0]}
                        value2={dates[1]}
                    />
                )}
                titleTypographyProps={{
                    variant: 'body1'
                }}
                action={(
                    <Chip
                        size="small"
                        label={`${period} of ${totalPeriods}`}
                    />
                )}
            />
            <Divider />
            <CardContent sx={{ justifyContent: 'center' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StaticDateRangePicker
                        displayStaticWrapperAs="desktop"
                        value={dates}
                        calendars={calendarCount}
                        disabled
                    />
                </LocalizationProvider>
            </CardContent>
        </Card>
    );
};

export default Period;