import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { AppBar, Box, Button, IconButton, List, ListItem, ListItemText, Popover, Stack, Tab, Tabs, TextField, Toolbar, Typography } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import InfoIcon from '@mui/icons-material/Info';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import buildUrl from '../buildUrl';
import useApi from '../hooks/useApi';
import useSnackbar from '../hooks/useSnackbar';
import usePrevious from '../hooks/usePrevious';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import Links from './views/Links';
import Hotspot from './views/Hotspot';
import Device from './views/Device';
import Social from './views/Social';
import Data from './views/Data';
import NewSummary from './views/NewSummary';
import Print from './views/Print';
import useTheme from '@mui/material/styles/useTheme';

const titleStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
};

const Subtitle = ({ campaign }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const sendDateTime = format((new Date(campaign.sendDateTime)), 'dd/MM/yyyy HH:mm');
    const statusAndDate = `${campaign.status} ${sendDateTime}`;

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getSubtitle = () => {
        if (campaign.allSubscribers) {
            return `${statusAndDate} to All Contacts`;
        }

        if (campaign.segment) {
            return `${statusAndDate} to 1 segment`;
        }

        if (campaign.groups && campaign.groups.length > 0) {
            if (campaign.groups.length === 1) {
                const groupName = campaign.groups[0].name;
                if (groupName === 'On-the-fly Group') return `${statusAndDate} to Manual List`;
                if (groupName === 'Proof') return `${statusAndDate} to Proof Group`;
                return `${statusAndDate} to 1 group`;
            }

            return `${statusAndDate} to ${campaign.groups.length} groups`;
        }

        return statusAndDate;
    };

    const showMoreInfoChecks = () => {
        if (campaign.allSubscribers) {
            return false;
        }

        if (campaign.groups && campaign.groups.length === 1) {
            if (campaign.groups[0].name === 'Proof' || campaign.groups[0].name === 'On-the-fly Group') {
                return false;
            }
            else {
                return true;
            }
        }

        return true;
    };

    const showMoreInfo = showMoreInfoChecks();

    const getPopoverContent = () => {
        if (showMoreInfo) {

            if (campaign.segment) {
                return `Segment: ${campaign.segment.name}`;
            }

            if (campaign.groups && campaign.groups.length > 0) {

                if (campaign.groups.length === 1) {
                    return `Group: ${campaign.groups[0].name}`;
                }
                else {
                    return (
                        <List
                            dense
                            disablePadding
                            subheader="Groups:"
                        >
                            {campaign.groups.map((g, i) => (
                                <ListItem disablePadding key={i}>
                                    <ListItemText primary={g.name} />
                                </ListItem>
                            ))}
                        </List>
                    );
                }
            }
        }

        return 'No extra information available';
    };

    const open = Boolean(anchorEl);
    const id = open ? 'popover' : undefined;

    return (
        <>
            <Typography component="h2" variant="subtitle1" sx={titleStyle}>
                {getSubtitle()}
                {(campaign && showMoreInfo) && (
                    <IconButton
                        onClick={handleClick}
                        sx={{ marginLeft: 1 }}
                        color="info"
                    >
                        <InfoIcon fontSize="small" />
                    </IconButton>
                )}
            </Typography>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Typography sx={{ p: 2 }}>
                    {getPopoverContent()}
                </Typography>
            </Popover>
        </>
    );
};

const ShareButton = ({ shareLink }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { showSnackbar } = useSnackbar();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const copyToClipboard = value => {
        navigator.clipboard.writeText(value);
        showSnackbar('Share link copied to clipboard', 'info');
    };

    const renderField = (value, label) => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    fullWidth
                    value={value}
                    label={label}
                    onFocus={e => e.target.select()}
                    InputProps={{
                        readOnly: true
                    }}
                    sx={{ flex: 1 }}
                />
                <IconButton
                    aria-label="copy to clipboard"
                    onClick={() => copyToClipboard(value)}
                    sx={{ flex: 0, marginLeft: 1 }}
                >
                    <ContentPasteIcon />
                </IconButton>
            </Box>
        );
    };

    return (
        <>
            <IconButton onClick={handleClick} edge="end" size="large">
                <ShareIcon />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Box sx={{ padding: 2 }}>
                    <Typography>Share your campaign online:</Typography>
                    {renderField(`${shareLink}?type=facebook`, 'Facebook')}
                    {renderField(`${shareLink}?type=twitter`, 'Twitter')}
                    {renderField(`${shareLink}?type=linkedin`, 'LinkedIn')}
                    {renderField(shareLink, 'Share Link')}
                </Box>
            </Popover>
        </>
    );
};

const NewEmail = () => {
    const { id } = useParams();
    const { handleGet } = useApi();
    const { showSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialising, setIsInitialising] = useState(true);
    const [html, setHtml] = useState(null);
    const [links, setLinks] = useState(null);
    const [email, setEmail] = useState(null);
    const [summary, setSummary] = useState(null);
    const [groups, setGroups] = useState(null);
    const [tab, setTab] = useState(0);
    const [selectedGroup, setSelectedGroup] = useState('none');
    const [subscriberStatus, setSubscriberStatus] = useState('selected');
    const [linkId, setLinkId] = useState(null);
    const theme = useTheme();

    const previousLinkId = usePrevious(linkId);
    const previousEmail = usePrevious(email);

    const sentDate = email ? format((new Date(email.sendDateTime)), 'dd-MM-yyyy HHmm') : '-';

    const handleLoadCampaign = async () => {
        const response = await handleGet(`reports/${id}`);

        if (!response.ok) {
            showSnackbar('Error fetching Email', 'error');
            return;
        }

        setEmail(await response.json());
    };

    const handleLoadCampaignSummary = async () => {
        const url = buildUrl(`reports/${id}/summary`, {
            ...(selectedGroup !== 'none' && { groupId: selectedGroup })
        });

        const response = await handleGet(url);

        if (!response.ok) {
            showSnackbar('Error fetching Summary', 'error');
            return;
        }

        setSummary(await response.json());
    };

    const handleFetchGroups = async () => {
        const response = await handleGet('groups');

        if (!response.ok) {
            showSnackbar('Error fetching Groups', 'error');
            return;
        }

        setGroups(await response.json());
    };

    const handleFetchHtml = async () => {
        const response = await handleGet(`reports/${id}/html`);

        if (!response.ok) {
            showSnackbar('Error fetching HTML', 'error');
            return;
        }

        setHtml(await response.text());
    };

    const handleFetchLinks = async () => {
        const response = await handleGet(`reports/${id}/links`);

        if (!response.ok) {
            showSnackbar('Error fetching Links', 'error');
            return;
        }

        setLinks(await response.json());
    };

    const handleViewWhoClicked = linkId => setLinkId(linkId);

    const handleChangeSubscriberStatusFilter = subscriberStatus => {
        setTab(3);
        setSubscriberStatus(subscriberStatus);
    };

    const handleSetDocumentTitle = () => document.title = `Campaign Report for '${email?.subject}' - Sent ${sentDate}`;

    const handleUnSetDocumentTitle = () => document.title = 'NewZapp';

    useEffect(() => {
        if (!email) {
            return;
        }

        if (email !== previousEmail) {
            setSelectedGroup('none');
        }

        window.addEventListener('beforeprint', () => handleSetDocumentTitle());
        window.addEventListener('afterprint', () => handleUnSetDocumentTitle());

        return () => {
            window.removeEventListener('beforeprint', () => handleSetDocumentTitle());
            window.removeEventListener('afterprint', () => handleUnSetDocumentTitle());
        };
    }, [email]);

    useEffect(() => {
        if (!isInitialising) {
            setIsLoading(true);
            setEmail(null);
            setSummary(null);
        }

        handleLoadCampaign();
        handleLoadCampaignSummary();
        handleFetchGroups();
        handleFetchHtml();
        handleFetchLinks();
    }, [id]);

    useEffect(() => {
        if (email && summary && groups) {
            setIsLoading(false);
            setIsInitialising(false);
        }
    }, [email, summary, groups]);

    useEffect(() => {
        if (isInitialising) {
            return;
        }

        setIsLoading(true);
        setSummary(null);

        handleLoadCampaignSummary();
    }, [selectedGroup]);

    useEffect(() => {
        if (!isInitialising && linkId && (linkId !== previousLinkId)) {
            setTab(2);
        }
    }, [linkId]);

    if (isLoading || isInitialising) {
        return (
            <LoadingOverlay />
        );
    }

    const Header = () => {
        const title = email.subject;

        return (
            <AppBar
                position="sticky"
                color="inherit"
                sx={{
                    top: 112,
                    zIndex: theme.zIndex.appBar - 1,
                    borderTop: 1,
                    borderColor: theme.palette.divider,
                    '@media print': {
                        position: 'relative',
                        top: 0,
                        display: 'block',
                        boxShadow: 'none',
                        borderBottom: 1
                    }
                }}
            >
                <Toolbar variant="dense" sx={{ overflow: 'hidden', pt: 2 }}>
                    <Stack>
                        <Typography component="h1" variant="h6" sx={titleStyle}>{email.name}</Typography>
                        <Typography component="h2" variant="body1" sx={titleStyle}>Subject: {title}</Typography>
                    </Stack>
                </Toolbar>
                <Toolbar variant="dense">
                    <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                        <Subtitle campaign={email} />
                    </Box>
                    <Box sx={{ whiteSpace: 'nowrap', paddingLeft: 2 }}>
                        <Button variant="text" onClick={() => handleChangeSubscriberStatusFilter('sent')}>{email.sent} Sent</Button>
                        <Button variant="text" onClick={() => handleChangeSubscriberStatusFilter('opened')}>{email.uniqueOpens} Unique Opens</Button>
                        <Button variant="text" onClick={() => handleChangeSubscriberStatusFilter('clicked')}>{email.uniqueClicks} Unique Clicks</Button>
                        <Button variant="text" onClick={() => handleChangeSubscriberStatusFilter('bounced')}>{email.bounced} Bounced</Button>
                        {email.failed > 0 && <Button variant="text" onClick={() => handleChangeSubscriberStatusFilter('failed')}>{email.failed} Failed</Button>}
                        <ShareButton
                            shareLink={email.shareLink}
                        />
                    </Box>
                </Toolbar>
                <Tabs value={tab} onChange={(e, tab) => setTab(tab)}>
                    <Tab label="Summary" />
                    <Tab label="Hotspot" disabled={!html} />
                    <Tab label="Links" disabled={!links} />
                    <Tab label="Data" />
                    <Tab label="Device" />
                    <Tab label="Social" />
                    {/*<Tab label="Analytics" />*/}
                    <Tab label="Print" />
                </Tabs>
            </AppBar>
        );
    };

    return (
        <>
            <Header />
            <Box sx={{ overflow: 'hidden' }}>
                {tab === 0 && (
                    <NewSummary
                        campaign={email}
                        isTabSelected={tab === 0}
                        onChangeSubscriberStatusFilter={subscriberStatus => handleChangeSubscriberStatusFilter(subscriberStatus)}
                    />
                )}
                {tab === 1 && (
                    <Hotspot
                        html={html}
                        itemLinks={links}
                        onViewWhoClicked={handleViewWhoClicked}
                    />
                )}
                {tab === 2 && (
                    <Links
                        id={email.id}
                        allLinks={links}
                        onViewWhoClicked={handleViewWhoClicked}
                        linkId={linkId}
                    />
                )}
                {tab === 3 && (
                    <Data
                        campaignId={email.id}
                        groups={groups}
                        subscriberStatus={subscriberStatus}
                        setSubscriberStatus={subscriberStatus => setSubscriberStatus(subscriberStatus)}
                        selectedGroup={selectedGroup}
                        setSelectedGroup={selectedGroup => setSelectedGroup(selectedGroup)}
                        campaignSummary={summary}
                    />
                )}
                {tab === 4 && <Device campaignId={email.id} />}
                {tab === 5 && (
                    <Social
                        campaignId={email.id}
                        campaignUniqueOpens={email.uniqueOpens}
                    />
                )}
                {/*{tab === 6 && (*/}
                {/*    <Analytics*/}
                {/*        gaCampaign={campaign.gaCampaign}*/}
                {/*        sendDate={campaign.sendDateTime}*/}
                {/*    />*/}
                {/*)}*/}
                {tab === 6 && (
                    <Print
                        email={email}
                        html={html}
                        links={links}
                    />
                )}
            </Box>
        </>
    );
};

export default NewEmail;