import { PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
    auth: {
        clientId: '59be1fcc-0442-42f2-b729-d67e40243b2b',
        authority: 'https://login.microsoftonline.com/common',
        redirectUri: window.location.origin
    }
};

export const loginRequest = {
    scopes: ['openid', 'profile', 'User.Read', 'Group.Read.All', 'Team.ReadBasic.All', 'Channel.ReadBasic.All', 'ChannelMessage.Send']
};

export const msalInstance = new PublicClientApplication(msalConfig);