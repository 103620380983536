const TypedValue = ({ value, type, accuracy = 2 }) => {
    switch (type) {
        case 'number':
            return new Intl.NumberFormat('en-GB', { maximumFractionDigits: accuracy }).format(value);
        case 'rate':
            return `${Number.isInteger(value) ? value : value.toFixed(accuracy)}%`;
        case 'hour':
            return value > 12 ? `${value - 12} p.m.` : value === 0 ? '12 a.m.' : `${value} a.m.`;
        default:
            return value;
    }
};

export default TypedValue;