import React from 'react';
import { useHistory } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import UserAvatar from '../../UserAvatar';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

const MessageCard = ({ message }) => {
    const history = useHistory();

    const handleClick = () => {
        const messageType = message.type.toLowerCase();

        if (messageType === 'email' && message.campaignId) {
            history.push(`/reports/email/${message.campaignId}`);
        }
        else if (messageType === 'page' && message.landingPageId) {
            history.push(`/reports/page/${message.landingPageId}`);
        }
        else if (messageType === 'survey' && message.surveyId) {
            history.push(`/editor/surveys/${message.surveyId}/results`);
        }
    };

    const typeLabel = message.type === 'email' ? 'View Email Report' : message.type === 'page' ? ' View Landing Page Report' : 'View Survey Results';

    return (
        <Card sx={{ marginBottom: 2 }} className="team-message">
            <CardHeader
                avatar={
                    message.userAvatar ? (
                        <UserAvatar image={message.userAvatar} />
                    ) : (
                        <UserAvatar name={message.userName} />
                    )
                }
                title={(
                    <Box display="flex" alignItems="center" className="teams-message-sender">
                        <Typography variant="h6">
                            {message.userName || 'Sender Name'}
                        </Typography>
                        <Typography variant="caption" sx={{ marginLeft: 2, mt: 0.7 }}>
                            {message?.created ? new Date(message.created).toLocaleString() : 'Time'}
                        </Typography>
                    </Box>
                )}
                subheader={(
                    <Chip
                        className="teams-message-opens"
                        label={<strong>Opens: {message.opens}</strong>}
                        icon={<AdsClickIcon fontSize="inherit" />}
                        variant="outlined"
                        size="small"
                    />
                )}
                action={(
                    <Button className="view-content" sx={{ mt: 2, mr: 1 }} variant="contained" onClick={handleClick} size="small">{typeLabel}</Button>
                )}
            />
            <Divider />
            <CardContent>
                <Card className="teams-message-message" sx={{ borderRadius: 1, borderTop: '3px solid', borderColor: 'primary.main' }}>
                    <CardHeader
                        avatar={
                            message.image ? (
                                <Avatar sx={{ width: 45, height: 45 }} variant="square" src={message.image} />
                            ) : null
                        }
                        title={(
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                {message.title}
                            </Typography>
                        )}
                        subheader={(
                            <Typography variant="subtitle2">
                                {message.subtitle}
                            </Typography>
                        )}
                    />
                    <CardContent sx={{ pt: 0 }}>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            {message.body}
                        </Typography>
                        <Button
                            variant="outlined"
                            href={
                                message.sharelink.includes('?')
                                    ? `${message.sharelink}&preview=true`
                                    : `${message.sharelink}?preview=true`
                            }
                            target="_blank"
                        >
                            View Content
                        </Button>
                    </CardContent>
                </Card>
            </CardContent>
        </Card>
    );
};

export default MessageCard;