import React, { useState } from 'react';
import { Card, CardHeader, IconButton, CardContent, Typography, Stack, Box, Paper, Popover } from '@mui/material';
import { SentimentSatisfiedAlt, Help } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import SentimentGauge from './charts/SentimentGauge';
import useTheme from '@mui/material/styles/useTheme';

const Score = ({ a, text, periodColours, b = null }) => {
    const theme = useTheme();

    return (
        <Stack spacing={0} sx={{ textAlign: 'center' }}>
            <Box>
                <Typography variant="h5" sx={{ display: 'inline', lineHeight: 1, color: periodColours[0] }}>{a}</Typography>
                {b !== null && (
                    <>
                        <Typography variant="body1" color={theme.palette.text.secondary} sx={{ display: 'inline', lineHeight: 1 }}> vs </Typography>
                        <Typography variant="h5" sx={{ display: 'inline', lineHeight: 1, color: periodColours[1] }}>{b}</Typography>
                    </>
                )}
            </Box>
            <Typography variant="body2" color={theme.palette.text.secondary}>{text}</Typography>
        </Stack>
    );
};

const Weighting = () => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'sentiment-weighting' : undefined;

    return (
        <>
            <IconButton onClick={handleClick} color="info">
                <Help />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Box p={2}>
                    <Typography variant="body1" fontWeight="bold" sx={{ pb: 0.5 }}>Weighting</Typography>
                    <Typography variant="body2" color={theme.palette.text.secondary}>The Sentiment figure is produced from the engagement on your email campaigns. <br />It is scored out of 10 and calculated using the following metrics.</Typography>
                    <Stack direction="row" spacing={3} sx={{ pt: 1 }}>
                        <Box>
                            <Typography variant="body2" fontWeight="bold" sx={{ pb: 0.5 }}>Open Rate</Typography>
                            <Typography variant="body2" color={theme.palette.text.secondary}>0 - 10% = 0.5</Typography>
                            <Typography variant="body2" color={theme.palette.text.secondary}>11 - 20% = 1</Typography>
                            <Typography variant="body2" color={theme.palette.text.secondary}>21 - 40% = 1.5</Typography>
                            <Typography variant="body2" color={theme.palette.text.secondary}>41 - 60% = 2</Typography>
                            <Typography variant="body2" color={theme.palette.text.secondary}>61 - 80% = 2.5</Typography>
                            <Typography variant="body2" color={theme.palette.text.secondary}>81 - 100% = 3</Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2" fontWeight="bold" sx={{ pb: 0.5 }}>Total Opens</Typography>
                            <Typography variant="body2" color={theme.palette.text.secondary}>0 - 50% = 0.5</Typography>
                            <Typography variant="body2" color={theme.palette.text.secondary}>51 - 100% = 1</Typography>
                            <Typography variant="body2" color={theme.palette.text.secondary}>101 - 200% = 1.5</Typography>
                            <Typography variant="body2" color={theme.palette.text.secondary}>Over 200% = 2</Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2" fontWeight="bold" sx={{ pb: 0.5 }}>Click Rate</Typography>
                            <Typography variant="body2" color={theme.palette.text.secondary}>0 - 5% = 0.5</Typography>
                            <Typography variant="body2" color={theme.palette.text.secondary}>6 - 10% = 1</Typography>
                            <Typography variant="body2" color={theme.palette.text.secondary}>11 - 20% = 1.5</Typography>
                            <Typography variant="body2" color={theme.palette.text.secondary}>21 - 50% = 2</Typography>
                            <Typography variant="body2" color={theme.palette.text.secondary}>51 - 80% = 2.5</Typography>
                            <Typography variant="body2" color={theme.palette.text.secondary}>81 - 100% = 3</Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2" fontWeight="bold" sx={{ pb: 0.5 }}>Total Clicks</Typography>
                            <Typography variant="body2" color={theme.palette.text.secondary}>0 - 10% = 0.5</Typography>
                            <Typography variant="body2" color={theme.palette.text.secondary}>11 - 40% = 1</Typography>
                            <Typography variant="body2" color={theme.palette.text.secondary}>41 - 100% = 1.5</Typography>
                            <Typography variant="body2" color={theme.palette.text.secondary}>Over 100% = 2</Typography>
                        </Box>
                    </Stack>
                </Box>
            </Popover>
        </>
    );
};

const SentimentCard = ({ a, b, periodColours }) => {
    return (
        <Card sx={{ m: 0, p: 0 }}>
            <CardHeader
                sx={{ m: 0, py: 1 }}
                title="Sentiment"
                titleTypographyProps={{
                    variant: 'h6',
                    fontWeight: 'normal'
                }}
                avatar={<SentimentSatisfiedAlt />}
                action={<Weighting />}
            />
            <CardContent
                sx={{
                    m: 0,
                    px: 2,
                    py: 0
                }}
            >
                <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                    <Grid xs={12} md={6}>
                        <SentimentGauge
                            score={a.sentimentScore}
                            colour={periodColours[0]}
                            period={b ? 'A' : null}
                        />
                    </Grid>
                    <Grid xs={12} md={6}>
                        {b ? (
                            <SentimentGauge
                                score={b.sentimentScore}
                                colour={periodColours[1]}
                                period="B"
                            />
                        ) : (
                            <Paper variant="outlined" sx={{ p: 2 }}>
                                <Grid container spacing={1}>
                                    <Grid xs={12}>
                                        <Typography variant="body1">Breakdown Scores</Typography>
                                    </Grid>
                                    <Grid xs={6}>
                                        <Score
                                            a={a.sentimentBreakdown?.openRateScore || 0}
                                            text="Open Rate"
                                            periodColours={periodColours}
                                        />
                                    </Grid>
                                    <Grid xs={6}>
                                        <Score
                                            a={a.sentimentBreakdown?.totalOpensScore || 0}
                                            text="Total Opens"
                                            periodColours={periodColours}
                                        />
                                    </Grid>
                                    <Grid xs={6}>
                                        <Score
                                            a={a.sentimentBreakdown?.clickRateScore || 0}
                                            text="Click Rate"
                                            periodColours={periodColours}
                                        />
                                    </Grid>
                                    <Grid xs={6}>
                                        <Score
                                            a={a.sentimentBreakdown?.totalClicksScore || 0}
                                            text="Total Clicks"
                                            periodColours={periodColours}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        )}
                    </Grid>
                    {b && (
                        <>
                            <Grid xs={12}>
                                <Typography variant="body1" sx={{ px: 2 }}>Breakdown Scores</Typography>
                            </Grid>
                            <Grid xs={6} md={3}>
                                <Score
                                    a={a.sentimentBreakdown?.openRateScore || 0}
                                    b={b.sentimentBreakdown?.openRateScore || 0}
                                    text="Open Rate"
                                    periodColours={periodColours}
                                />
                            </Grid>
                            <Grid xs={6} md={3}>
                                <Score
                                    a={a.sentimentBreakdown?.totalOpensScore || 0}
                                    b={b.sentimentBreakdown?.totalOpensScore || 0}
                                    text="Total Opens"
                                    periodColours={periodColours}
                                />
                            </Grid>
                            <Grid xs={6} md={3}>
                                <Score
                                    a={a.sentimentBreakdown?.clickRateScore || 0}
                                    b={b.sentimentBreakdown?.clickRateScore || 0}
                                    text="Click Rate"
                                    periodColours={periodColours}
                                />
                            </Grid>
                            <Grid xs={6} md={3}>
                                <Score
                                    a={a.sentimentBreakdown?.totalClicksScore || 0}
                                    b={b.sentimentBreakdown?.totalClicksScore || 0}
                                    text="Total Clicks"
                                    periodColours={periodColours}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SentimentCard;