import React, { useState, useEffect, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Search from '../contacts/Search';
import Sort from '../contacts/Sort';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Tag from '../tags/Tag';
import useTheme from '@mui/material/styles/useTheme';
import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Unstable_Grid2';
import useAccount from '../hooks/useAccount';

const GroupTypeSelect = ({ groupType, setGroupType, showArchive }) => {
    const [type, setType] = useState(groupType ?? 'all');

    useEffect(() => {
        setGroupType(type === 'all' ? null : type);
    }, [type]);

    return (
        <FormControl size="small" fullWidth margin="none">
            <InputLabel id="type-label">Type</InputLabel>
            <Select
                labelId="type-label"
                id="type"
                value={type}
                label="Sort"
                onChange={event => setType(event.target.value)}
            >
                <MenuItem value="all">Active</MenuItem>
                <MenuItem value="public">Public</MenuItem>
                <MenuItem value="private">Private</MenuItem>
                {showArchive && <MenuItem value="archive">Archive</MenuItem>}
            </Select>
        </FormControl>
    );
};

const GroupsFilters = ({
    disabled,
    groupsSort,
    setGroupsSort,
    groupType,
    setGroupType,
    setGroupsSearchValue,
    groupsTempSearchValue,
    setGroupsTempSearchValue,
    filterTagIds,
    onFilterByTag,
    popperGutters = 0,
    openCreateGroupDialog = null,
    showArchive = false
}) => {

    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const ref = useRef();
    const { tags } = useAccount();
    const open = Boolean(anchorEl);
    const id = open ? 'search-options' : undefined;

    const handleRemoveTagFilter = (event, id) => {
        event.stopPropagation();
        onFilterByTag(id);
    };

    const handleFilterByTag = (event, id) => {
        event.stopPropagation();
        setGroupsSearchValue('');
        onFilterByTag(id);
        //clear field for clarity (also closes popper)
        setGroupsTempSearchValue('');
    };

    //the reason there are two handleSearch functions here:
    //needs to be able to take a value sometimes... but sometimes will be sent an event as the first prop
    //TODO handle this better

    const handleSearch = () => {
        setGroupsSearchValue(groupsTempSearchValue);
        setAnchorEl(null);
    };

    const handleSearchValue = value => {
        setGroupsSearchValue(value);
        setAnchorEl(null);
    };

    const filteredTags = tags?.filter(e => e.name.toLowerCase().includes(groupsTempSearchValue.toLowerCase()));

    useEffect(() => {
        setAnchorEl(groupsTempSearchValue ? ref.current : null);
    }, [groupsTempSearchValue]);

    return (
        <Stack spacing={1} sx={{ marginY: 2, width: '100%' }}>
            <Grid container spacing={1}>
                {openCreateGroupDialog && (
                    <Grid xs={4}>
                        <Button onClick={openCreateGroupDialog} fullWidth disabled={disabled}>
                            New Group
                        </Button>
                    </Grid>
                )}
                <Grid xs={4}>
                    <Sort
                        sort={groupsSort}
                        setSort={groupsSort => setGroupsSort(groupsSort)}
                        fullWidth={true}
                        margin={false}
                    />
                </Grid>
                <Grid xs={4}>
                    <GroupTypeSelect
                        groupType={groupType}
                        setGroupType={setGroupType}
                        showArchive={showArchive}
                    />
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex' }} ref={ref}>
                <Search
                    placeholder="Filter by Name or Tag"
                    tempSearchValue={groupsTempSearchValue}
                    setTempSearchValue={setGroupsTempSearchValue}
                    onSearch={handleSearchValue}
                />
            </Box>
            {filterTagIds.length > 0 && (
                <Box>
                    <Typography variant="subtitle2">
                        Filtered by:
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {tags.filter(tag => filterTagIds.includes(tag.id)).map(tag => (
                            <Tag
                                key={tag.id}
                                colour={tag.colour}
                                name={tag.name}
                                onDelete={event => handleRemoveTagFilter(event, tag.id)}
                            />
                        ))}
                    </Box>
                </Box>
            )}
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                disablePortal
                placement="bottom-start"
                sx={{ width: '100%', zIndex: theme.zIndex.modal + 1 }}
            >
                <Paper elevation={6} sx={{ p: 1, mx: popperGutters, flexGrow: 1 }}>
                    <Stack spacing={1}>
                        <Box>
                            <Typography variant="subtitle2" sx={{ textAlign: 'center', pb: 1 }}>
                                Filter by Tag
                            </Typography>
                            {(tags && filteredTags.length > 0) ? (
                                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                    {filteredTags.map(tag => (
                                        <Tag
                                            key={tag.id}
                                            colour={tag.colour}
                                            name={tag.name}
                                            onClick={event => handleFilterByTag(event, tag.id)}
                                        />
                                    ))}
                                </Box>
                            ) : (
                                <Typography variant="body2" sx={{ textAlign: 'center' }}>No tags found</Typography>
                            )}
                        </Box>
                        <Divider>
                            <Typography variant="body2">
                                OR
                            </Typography>
                        </Divider>
                        <Box sx={{ textAlign: 'center' }}>
                            <Button onClick={handleSearch} startIcon={<SearchIcon />}>
                                Filter by Name
                            </Button>
                        </Box>
                    </Stack>
                </Paper>
            </Popper>
        </Stack>
    );
};

export default GroupsFilters;