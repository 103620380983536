import { format, isSameYear } from 'date-fns';

const RenderPeriodDateRange = ({ value1, value2 }) => {
    if (isSameYear(value1, value2)) {
        return `${format(value1, 'd MMM')} - ${format(value2, 'd MMM y')}`;
    }

    return `${format(value1, 'd MMM y')} - ${format(value2, 'd MMM y')}`;
};

export default RenderPeriodDateRange;