import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import FormControlLabel from '@mui/material/FormControlLabel';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import TopicIcon from '@mui/icons-material/Topic';
import ArchiveIcon from '@mui/icons-material/Archive';
import Box from '@mui/material/Box';
import { format } from 'date-fns';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import buildUrl from '../buildUrl';
import useSearch from '../hooks/useSearch';
import DataColumn from '../dataTable/DataColumn';
import DatePicker from '../DatePicker';
import useApi from '../hooks/useApi';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CenteredProgress from '../loadingOverlay/CenteredProgress';
import RenameItemDialog from './dialogs/RenameItemDialog';
import DeleteItemDialog from './dialogs/DeleteItemDialog';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import DeleteIcon from '@mui/icons-material/Delete';
import FullScreenSettingsDialog from '../shared/FullScreenSettingsDialog';
import { useParams } from 'react-router-dom';
import { Button, ListItemIcon, ListItemText } from '@mui/material';
import useSnackbar from '../hooks/useSnackbar';
import useAccount from '../hooks/useAccount';
import Topics from '../account/topics/Topics';
import Picker from '../picker/Picker';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import TopicFilter from '../topics/TopicFilter';

import Android12Switch from '../editor/selects/ArchiveToggle.js';

const ReportMenu = ({ report, onRename, onArchive, onUnArchive, onDelete, status, isBeingUsed = false }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const archiveLabel = report.archived ? 'Unarchive' : 'Archive';

    const handleClick = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(e.currentTarget);
    };

    const handleClose = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(null);
    };

    const handleAction = (e, action) => {
        e.stopPropagation();

        action();
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleClick} size="small" disabled={isBeingUsed}>
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={e => handleAction(e, onRename)}>
                    <ListItemIcon>
                        <DriveFileRenameOutlineIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Rename</ListItemText>
                </MenuItem>
                <MenuItem onClick={e => {
                    if (archiveLabel === 'Archive') {
                        handleAction(e, onArchive);
                    }
                    else {
                        handleAction(e, onUnArchive);
                    }
                }}>
                    <ListItemIcon>
                        <ArchiveIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{archiveLabel}</ListItemText>
                </MenuItem>
                {status === 'Scheduled' ? (
                    <MenuItem onClick={e => handleAction(e, onDelete)}>
                        <ListItemIcon>
                            <CancelScheduleSendIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Cancel & Revert to Draft</ListItemText>
                    </MenuItem>
                ) : (
                    <MenuItem onClick={e => handleAction(e, onDelete)}>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Delete & Revert to Draft</ListItemText>
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

const rowsPerPageOptions = [10];

const emailColumns = [
    { name: 'name', label: 'Name', orderDesc: false, dataType: 'text' },
    { name: 'subject', label: 'Subject', orderDesc: false, dataType: 'text' },
    { name: 'status', label: 'Status', orderDesc: false, dataType: 'text' },
    { name: 'sendDateTime', label: 'Date/Time', align: 'right', orderDesc: false, dataType: 'dateTime' },
    { name: 'selected', label: 'Selected', align: 'right', orderDesc: true, dataType: 'number' },
    { name: 'sent', label: 'Sent', align: 'right', orderDesc: true, dataType: 'number' },
    { name: 'uniqueOpens', label: 'Unique Opens', align: 'right', orderDesc: true, dataType: 'number' },
    { name: 'uniqueClicks', label: 'Unique Clicks', align: 'right', orderDesc: true, dataType: 'number' },
    { name: 'numberOfLinks', label: 'Links', align: 'right', orderDesc: true, dataType: 'number' }
];

const CampaignsTable = () => {
    const history = useHistory();
    const { topics, handleCreateTopic } = useAccount();
    const [filterTopics, setFilterTopics] = useState([]);
    const [isInitialising, setIsInitialising] = useState(true);
    const [reports, setReports] = useState([]);
    const [count, setCount] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [itemToRename, setItemToRename] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [manageTopics, setManageTopics] = useState(null);
    const [archived, setArchived] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [page, setPage] = useState(0);
    const [orderBy, setOrderBy] = useState('sendDateTime');
    const [orderDesc, setOrderDesc] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [tempSearchValue, setTempSearchValue] = useSearch(setSearchValue);
    const { handleGet, handlePut, handleDelete } = useApi();
    const { showSnackbar } = useSnackbar();
    const routeParams = useParams();

    const handleFetchCount = async () => {
        const params = {
            search: searchValue,
            ...(fromDate && { fromDate: format(fromDate, 'yyyy-MM-dd') }),
            ...(toDate && { toDate: format(toDate, 'yyyy-MM-dd') }),
            ...(filterTopics.length > 0 && { topicIds: filterTopics.map(e => e.id) }),
            archived
        };

        const url = buildUrl('reports/count', params);
        const response = await handleGet(url);

        if (!response.ok) {
            showSnackbar('Error fetching count', 'error');
            return;
        }

        const data = await response.json();
        setCount(data);
    };

    const handleFetchReports = async () => {
        setIsLoading(true);

        const params = {
            search: searchValue,
            ...(fromDate && { fromDate: format(fromDate, 'yyyy-MM-dd') }),
            ...(toDate && { toDate: format(toDate, 'yyyy-MM-dd') }),
            skip: page * rowsPerPage,
            take: rowsPerPage,
            orderBy,
            isDescending: orderDesc,
            ...(filterTopics.length > 0 && { topicIds: filterTopics.map(e => e.id) }),
            archived
        };

        const url = buildUrl('reports', params);
        const response = await handleGet(url);

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const data = await response.json();

        setReports(data);
        setIsLoading(false);
    };

    const handleSort = (field, defaultOrder) => {
        if (field === orderBy) {
            setOrderDesc(!orderDesc);
        }
        else {
            setOrderBy(field);
            setOrderDesc(defaultOrder);
        }
    };

    // archive/unarchive report
    const handleArchiveState = async ({ item, archived }) => {
        setIsLoading(true);

        const response = await handlePut(`reports/${item.id}/archive`, { archived });

        if (!response.ok) {
            showSnackbar('Report archiving error', 'error');
        }
        else archived ? showSnackbar('Report archived', 'success') : showSnackbar('Report unarchived', 'succcess');

        setIsLoading(false);
        handleFetchCount();
        handleFetchReports();

    };

    // rename dialog
    const handleRename = async name => {
        const response = await handlePut(`reports/${itemToRename.id}/rename`, { name });

        if (!response.ok) {
            showSnackbar('Campaign rename failed', 'error');
        }

        setItemToRename(null);
        handleFetchReports();
    };

    //  update topic
    const handleUpdateTopic = async ({ report, topics = null }) => {
        const topicIds = topics?.map(e => e.id);

        const params = {
            ...(topicIds && { topicIds })
        };

        try {
            const response = await handlePut(`reports/${report.id}`, params);

            if (!response.ok) {
                const errorMessage = response.status === 404
                    ? 'Campaign not found'
                    : 'Failed to update the campaign';
                showSnackbar(errorMessage, 'error');
            }
        }
        catch (error) {
            console.error('Error updating topic:', error);
            showSnackbar('An error occurred while updating the topic.', 'error');
        }
    };

    const handleChangeTopicsFilter = topic => {

        if (!topic) {
            setFilterTopics([]);
            return;
        }

        if (Boolean(filterTopics.find(r => r.id === topic.id))) {
            setFilterTopics(filterTopics.filter(r => r.id !== topic.id));
        }
        else {
            setFilterTopics([...filterTopics, topic]);
        }
    };

    const handleInit = async () => {
        await handleFetchReports();
        await handleFetchCount();
        setIsInitialising(false);
    };

    const handleSubmitCreateTopic = async ({ name, description, colour }) => {
        await handleCreateTopic({
            name,
            description,
            colour
        });
    };

    // delete dialog
    const handleDeleteItem = async () => {
        const response = await handleDelete(`reports/items/${itemToDelete.id}`);

        if (itemToDelete.id?.toString() === routeParams.id?.toString()) {
            history.push('/reports');
        }

        if (!response.ok) {
            showSnackbar('Error happened when delete campaign attempted', 'error');
        }

        setItemToDelete(null);
        handleFetchReports();
    };

    useEffect(() => {
        if (isInitialising) {
            return;
        }

        handleFetchCount();
    }, [searchValue, fromDate, toDate, filterTopics, archived]);

    useEffect(() => {
        setPage(0);
    }, [searchValue, fromDate, toDate, orderBy, orderDesc, rowsPerPage, filterTopics, archived]);

    useEffect(() => {
        setIsLoading(true);
    }, [searchValue, fromDate, toDate, orderBy, orderDesc, rowsPerPage, filterTopics, archived]);

    useEffect(() => {
        if (isInitialising) {
            return;
        }

        handleFetchReports();
    }, [searchValue, fromDate, toDate, orderBy, orderDesc, rowsPerPage, page, filterTopics, archived]);

    useEffect(() => {
        handleInit();
    }, []);

    if (isInitialising) {
        return <LoadingOverlay />;
    }

    return (
        <Box sx={{ overflow: 'hidden' }}>
            <Paper>
                <Toolbar disableGutters sx={{ mx: 1.5, paddingTop: 0.5, paddingBottom: 0.5 }}>
                    <Box sx={{ flexGrow: 1, mr: 1 }}>
                        <OutlinedInput
                            value={tempSearchValue}
                            onChange={e => setTempSearchValue(e.target.value)}
                            placeholder="Search..."
                            size="small"
                            sx={{ mr: 1 }}
                            endAdornment={(
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setTempSearchValue('')}
                                        edge="end"
                                        disabled={!Boolean(tempSearchValue)}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            )}
                        />
                        <TopicFilter
                            availableTopics={topics}
                            selectedTopics={filterTopics}
                            onAddTopic={topic => handleChangeTopicsFilter(topic)}
                            onRemoveTopic={topic => handleChangeTopicsFilter(topic)}
                            onClearTopics={() => handleChangeTopicsFilter()}
                        />
                        <Box sx={{ display: 'inline-flex' }} mr={1}>
                            <DatePicker value={fromDate} onChange={setFromDate} label="From Date" />
                        </Box>
                        <Box sx={{ display: 'inline-flex' }} mr={1}>
                            <DatePicker value={toDate} onChange={setToDate} label="To Date" />
                        </Box>
                        <FormControlLabel
                            sx={{ ml: 1 }}
                            control={<Android12Switch checked={archived} onChange={e => setArchived(e.target.checked)} />}
                            label="Archived"
                        />
                        <Button
                            sx={{
                                position: 'absolute',
                                right: 0,
                                pr: 3
                            }}
                            onClick={() => setManageTopics(true)}
                        >
                            <TopicIcon fontSize="small" sx={{ mr: 0.5 }} />
                            Manage topics
                        </Button>
                    </Box>
                </Toolbar>
                <Divider />
                {isLoading ? (
                    <Box py={4}>
                        <CenteredProgress colour="primary" />
                    </Box>
                ) : (
                    <>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        {emailColumns.map(col => (
                                            <TableCell
                                                key={col.name}
                                                component="th"
                                                scope="row"
                                                align={col.align}
                                                width={col.width || 'auto'}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === col.name}
                                                    direction={orderDesc ? 'desc' : 'asc'}
                                                    onClick={() => handleSort(col.name, col.orderDesc)}
                                                >
                                                    {col.label}
                                                </TableSortLabel>

                                            </TableCell>
                                        ))}
                                        <TableCell component="th" scope="row" align="left" sx={{ width: '20%' }}>Topics</TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reports.map(report => (
                                        <TableRow
                                            key={report.id}
                                            hover={true}
                                        >
                                            {emailColumns.map(col => (
                                                <DataColumn
                                                    cursor={col.name === 'name' ? 'pointer' : 'default'}
                                                    key={col.name}
                                                    align={col.align}
                                                    dataType={col.dataType}
                                                    value={report[col.name]}
                                                    onClick={() => {
                                                        if (col.name === 'name') {
                                                            history.push(`/reports/email/${report.id}`);
                                                        }
                                                    }}
                                                />
                                            ))}
                                            <TableCell align="left" sx={{ p: 0 }}>
                                                <Picker
                                                    availableItems={topics}
                                                    selectedItems={report.topics}
                                                    onChangeItems={topics => handleUpdateTopic({ report, topics })}
                                                    onClickItem={topic => handleChangeTopicsFilter(topic)}
                                                    onCreateTopic={handleSubmitCreateTopic}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <ReportMenu
                                                    report={report}
                                                    status={report.status}
                                                    onRename={() => setItemToRename(report)}
                                                    onArchive={() => handleArchiveState({ item: report, archived: true })}
                                                    onUnArchive={() => handleArchiveState({ item: report, archived: false })}
                                                    onDelete={() => setItemToDelete(report)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(e, newPage) => setPage(newPage)}
                            onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
                        />
                    </>
                )}

                {itemToRename && (
                    <RenameItemDialog
                        item={itemToRename}
                        onClose={() => setItemToRename(null)}
                        onSubmit={handleRename}
                    />
                )}

                {itemToDelete && (
                    <DeleteItemDialog
                        item={itemToDelete}
                        onClose={() => setItemToDelete(null)}
                        onSubmit={handleDeleteItem}
                    />
                )}

                {manageTopics && (
                    <FullScreenSettingsDialog handleDialogClose={() => setManageTopics(false)} title={'Manage Topics'}>
                        <Topics />
                    </FullScreenSettingsDialog>
                )}
            </Paper>
        </Box>
    );
};

export default CampaignsTable;