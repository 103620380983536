
import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import useNotifications from '../../hooks/useNotifications';

const ExportDialog = ({ jobId, isComplete = false, url, onClose }) => {
    const { notifications } = useNotifications();
    const [hasCompleted, setHasCompleted] = useState(isComplete);
    const [downloadUrl, setDownloadUrl] = useState(url);

    useEffect(() => {
        if (!hasCompleted) {
            const notification = notifications
                .filter(({ isRead, type }) => !isRead && type === 'export')
                .map(({ body }) => JSON.parse(body))
                .find(({ id }) => id === jobId);

            if (notification) {
                setHasCompleted(true);
                setDownloadUrl(notification.url);
            }
        }

    }, [notifications, isComplete]);

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>{hasCompleted ? 'Export Complete' : 'Export in Progress'}</DialogTitle>
            <DialogContent>
                {hasCompleted ? (
                    <DialogContentText>
                        Your export is complete. This link will be valid for the next 7 days.
                    </DialogContentText>
                ) : (
                    <>
                        <Typography paragraph>You will be notified when the export is complete.<br />You can safely close this dialog.</Typography>
                        <LinearProgress />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                {hasCompleted && (
                    <Button component="a" href={downloadUrl} sx={{ marginRight: 1 }}>Download</Button>
                )}
                <Button variant="outlined" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExportDialog;