import React, { useState, useEffect } from 'react';
import { Paper, Box, List, Stack, Skeleton } from '@mui/material';
import { useRouteMatch } from 'react-router-dom';
import { Email, Web, QrCode2, PeopleAlt, Analytics } from '@mui/icons-material';
import useTheme from '@mui/material/styles/useTheme';
import Grid from '@mui/material/Unstable_Grid2';
import { format } from 'date-fns';
import buildUrl from '../buildUrl';
import useApi from '../hooks/useApi';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import CustomListItem from './CustomListItem';
import ChannelContentOverviewChart from './charts/ChannelContentOverviewChart';

const NewDashboard = ({ selectedPeriods, prevSelectedPeriods, compare, periodColours }) => {
    const { path } = useRouteMatch();
    const { handleGet } = useApi();
    const theme = useTheme();
    const [summaryA, setSummaryA] = useState(null);
    const [summaryB, setSummaryB] = useState(null);
    const [isInitialising, setIsInitialising] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const showB = compare && summaryB;

    const handleFetchSummary = async (period, isB = false) => {
        setIsLoading(true);

        const fromDate = period[0];
        const toDate = period[1];

        const params = {
            ...(fromDate && { fromDate: format(fromDate, 'yyyy-MM-dd') }),
            ...(toDate && { toDate: format(toDate, 'yyyy-MM-dd') })
        };

        const url = buildUrl('reports/summary', params);
        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        const data = await response.json();

        isB ? setSummaryB(data) : setSummaryA(data);
    };

    useEffect(() => {
        !isInitialising && setIsLoading(true);

        if (selectedPeriods.length === 0) {
            return;
        }

        // only fetch data if null or period has changed

        if (!summaryA || (selectedPeriods[0] !== prevSelectedPeriods[0])) {
            handleFetchSummary(selectedPeriods[0]);
        }

        if (compare && selectedPeriods.length > 1 && (!summaryB || selectedPeriods[1] !== prevSelectedPeriods[1])) {
            handleFetchSummary(selectedPeriods[1], true);
        }
    }, [selectedPeriods]);

    useEffect(() => {
        if (!compare) {
            if (summaryA) {
                isInitialising && setIsInitialising(false);
                isLoading && setIsLoading(false);
            }
        }
        else {
            if (summaryA && summaryB) {
                isInitialising && setIsInitialising(false);
                isLoading && setIsLoading(false);
            }
        }
    }, [summaryA, summaryB, compare]);

    if (isInitialising) {
        return <LoadingOverlay />;
    }

    return (
        <Box sx={{ overflow: 'hidden' }}>
            <Grid container spacing={2} sx={{ padding: 2 }}>
                <Grid xs={12} md={4} lg={3}>
                    <Paper>
                        {summaryA ? (
                            <List disablePadding>
                                <CustomListItem
                                    a={summaryA.totalContacts}
                                    b={null}
                                    showB={false}
                                    text="Total Contacts"
                                    icon={<PeopleAlt />}
                                    periodColours={[theme.palette.text.primary, theme.palette.text.secondary]}
                                    showChange={false}
                                    route="/contacts"
                                />
                                <CustomListItem
                                    a={summaryA.totalEmailsForPeriod}
                                    b={summaryB?.totalEmailsForPeriod}
                                    showB={showB}
                                    text="Emails Sent"
                                    icon={<Email />}
                                    periodColours={periodColours}
                                    route={`${path}/emails`}
                                />
                                <CustomListItem
                                    a={summaryA.totalPagesForPeriod}
                                    b={summaryB?.totalPagesForPeriod}
                                    showB={showB}
                                    text="Landing Pages Published"
                                    icon={<Web />}
                                    periodColours={periodColours}
                                    route={`${path}/pages`}
                                />
                                <CustomListItem
                                    a={summaryA.totalSurveysForPeriod}
                                    b={summaryB?.totalSurveysForPeriod}
                                    showB={showB}
                                    text="Surveys Published"
                                    icon={<Analytics />}
                                    periodColours={periodColours}
                                />
                                <CustomListItem
                                    a={summaryA.totalQRCodesForPeriod}
                                    b={summaryB?.totalQRCodesForPeriod}
                                    showB={showB}
                                    text="Active QR Codes"
                                    icon={<QrCode2 />}
                                    periodColours={periodColours}
                                    lastItem={true}
                                />
                            </List>
                        ) : (
                            <Stack spacing={4} padding={2}>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </Stack>
                        )}
                    </Paper>
                </Grid>
                <Grid xs={12} md={8} lg={9}>
                    <Paper>
                        <ChannelContentOverviewChart
                            a={summaryA}
                            b={showB ? summaryB : null}
                            periodColours={periodColours}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default NewDashboard;