import React, { useState, useEffect } from 'react';
import { Button, Typography, Container, InputLabel, MenuItem, FormControl, Select, Stack, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import AlertBar from '../../alertBar/AlertBar';
import useAccount from '../../hooks/useAccount';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import enLocale from 'date-fns/locale/en-GB';
import useSnackbar from '../../hooks/useSnackbar';
import { addDays, addYears, isEqual, format } from 'date-fns';
import { createWeeklyPeriods, createMonthlyPeriods, createQuarterlyPeriods } from '../reportingPeriod/ReportingPeriods';
import Period from './Period';

const ReportingPeriod = ({ onClose }) => {
    const { showSnackbar } = useSnackbar();
    const { reportingPeriod, handleUpdateReportingPeriod } = useAccount();

    const today = new Date();
    const thisYear = today.getFullYear();

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [show, setShow] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [type, setType] = useState(reportingPeriod?.type ?? 'monthly');
    const [origin, setOrigin] = useState(reportingPeriod?.startDate ? new Date(reportingPeriod.startDate) : new Date(thisYear, 0, 1));

    const [examplePeriods, setExamplePeriods] = useState(null);

    const calendarCount = type === 'monthly' ? 1 : type === 'quarterly' ? 3 : 2;

    const handleCreateExamplePeriods = (type, origin) => {
        const start = new Date(origin);

        const end = addDays(addYears(new Date(origin), 1), -1);
        const fourWeeklyEnd = addYears(new Date(origin), 1);

        let periods = [];

        switch (type) {
            case '4weekly':
                periods = createWeeklyPeriods(start, fourWeeklyEnd, true);

                break;

            case 'quarterly':
                periods = createQuarterlyPeriods(start, end);

                break;

            //case 'monthly':
            default:
                periods = createMonthlyPeriods(start, end);
        }

        setExamplePeriods(periods);
    };

    const handleUpdateAccount = async () => {
        const response = await handleUpdateReportingPeriod({
            reportingPeriodType: type,
            reportingPeriodStartDate: format(origin, 'yyyy-MM-dd')
        });

        if (!response.ok) {
            showSnackbar('Changes could not be saved', 'error');
            return;
        }

        setHasUnsavedChanges(false);
        showSnackbar('Changes saved', 'success');

        onClose();
    };

    const onSubmit = async e => {
        e.preventDefault();

        setIsLoading(true);

        await handleUpdateAccount();

        setIsLoading(false);
    };

    const handleChangeType = event => {
        setShow(false);
        handleCreateExamplePeriods(event.target.value, new Date(thisYear, 0, 1));
        setType(event.target.value);
        setOrigin(new Date(thisYear, 0, 1));
    };

    const handleChangeOrigin = origin => {
        setShow(false);
        handleCreateExamplePeriods(type, origin);
        setOrigin(origin);
    };

    useEffect(() => {
        handleCreateExamplePeriods(type, origin);
    }, []);

    useEffect(() => {
        const isDateChanged = !reportingPeriod?.startDate
            ? !isEqual(origin, new Date(thisYear, 0, 1))
            : !isEqual(origin, new Date(reportingPeriod.startDate));

        (reportingPeriod === null && type === 'monthly')
            ? setHasUnsavedChanges(false)
            : setHasUnsavedChanges(type !== reportingPeriod?.type || isDateChanged);
    }, [type, origin]);

    return (
        <>
            <AlertBar
                shown={hasUnsavedChanges}
                action={(
                    <Button
                        variant="outlined"
                        size="small"
                        disabled={isLoading}
                        onClick={onSubmit}
                    >
                        Save Changes
                    </Button>
                )}
                positionTop={0}
            >
                You have unsaved changes
            </AlertBar>
            <Container sx={{ padding: 3 }} maxWidth="false">
                <Stack spacing={3}>
                    <Typography>
                        A reporting period, also known as an accounting period, is a specific period of time when a company&apos;s financial performance and position are reported and analysed. We'll group your reports data and allow you to compare between periods.
                    </Typography>
                    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} alignItems="flex-start">
                        <FormControl size="small" margin="none">
                            <InputLabel id="period-label">Periods</InputLabel>
                            <Select
                                labelId="period-label"
                                value={type}
                                label="Periods"
                                onChange={handleChangeType}
                                size="small"
                                margin="none"
                            >
                                <MenuItem value="4weekly">4 Weekly (13 periods)</MenuItem>
                                <MenuItem value="monthly">Monthly (12 periods)</MenuItem>
                                <MenuItem value="quarterly">Quarterly (4 periods)</MenuItem>
                            </Select>
                        </FormControl>
                        {type === '4weekly' && (
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
                                <DatePicker
                                    mask="__/__/____"
                                    label="Start Date"
                                    value={origin}
                                    onChange={handleChangeOrigin}
                                    slotProps={{
                                        toolbar: {
                                            hidden: false
                                        },
                                        textField: {
                                            size: 'small'
                                        }
                                    }} />
                            </LocalizationProvider>
                        )}
                        <Box>
                            <Button onClick={() => setShow(true)}>
                                Generate Example Periods
                            </Button>
                        </Box>
                    </Stack>
                    {(examplePeriods && show) && (
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <Typography variant="h6">
                                    Example Periods
                                </Typography>
                            </Grid>
                            {examplePeriods.map((e, i, a) => (
                                <Grid
                                    xs="auto"
                                    sm={calendarCount > 1 ? 'auto' : 6}
                                    lg={calendarCount > 1 ? 'auto' : 4}
                                    xl={calendarCount > 1 ? 'auto' : 3}
                                    key={i}
                                >
                                    <Period
                                        period={i + 1}
                                        dates={e}
                                        totalPeriods={a.length}
                                        calendarCount={calendarCount}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Stack>
            </Container>
            {isLoading && <LoadingOverlay />}
        </>
    );
};

export default ReportingPeriod;