import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Toolbar, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TableSortLabel, Box, OutlinedInput, InputAdornment, Stack, Skeleton, Typography, Card, CardContent, CardHeader, FormControl, InputLabel, Select, MenuItem, AppBar, Tab, Tabs } from '@mui/material';
import { Margin, Unsubscribe, Clear, Edit, DepartureBoard, Drafts, AdsClick, Send, ScheduleSend, Watch, Block, ShortText } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import { format } from 'date-fns';
import IconButton from '@mui/material/IconButton';
import buildUrl from '../buildUrl';
import DataColumn from '../dataTable/DataColumn';
import DatePicker from '../DatePicker';
import useSearch from '../hooks/useSearch';
import useApi from '../hooks/useApi';
import CenteredProgress from '../loadingOverlay/CenteredProgress';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import BestSubject from './BestSubject';
import CompareValues from './CompareValues';
import TabbedHeatmapCard from './TabbedHeatmapCard';
import SentimentCard from './SentimentCard';
import SubjectLengthEngagementChart from './charts/SubjectLengthEngagementChart';
import AverageSendOpenClickRatesChart from './charts/AverageSendOpenClickRatesChart';
import RenderPeriodDateRange from '../account/reportingPeriod/RenderPeriodDateRange';
import useTheme from '@mui/material/styles/useTheme';

const rowsPerPageOptions = [10];

const columns = [
    { name: 'name', label: 'Name', orderDesc: false, dataType: 'text' },
    { name: 'subject', label: 'Subject', orderDesc: false, dataType: 'text' },
    { name: 'status', label: 'Status', orderDesc: false, dataType: 'text' },
    { name: 'sendDateTime', label: 'Date/Time', align: 'right', orderDesc: false, dataType: 'dateTime' },
    { name: 'selected', label: 'Selected', align: 'right', orderDesc: true, dataType: 'number' },
    { name: 'sent', label: 'Sent', align: 'right', orderDesc: true, dataType: 'number' },
    { name: 'uniqueOpens', label: 'Unique Opens', align: 'right', orderDesc: true, dataType: 'number' },
    { name: 'uniqueClicks', label: 'Unique Clicks', align: 'right', orderDesc: true, dataType: 'number' },
    { name: 'numberOfLinks', label: 'Links', align: 'right', orderDesc: true, dataType: 'number' }
];

const Emails = ({ selectedPeriods, prevSelectedPeriods, compare, periodColours }) => {
    const history = useHistory();
    const theme = useTheme();
    const { handleGet } = useApi();
    const [reports, setReports] = useState(null);
    const [count, setCount] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [page, setPage] = useState(0);
    const [summaryA, setSummaryA] = useState(null);
    const [summaryB, setSummaryB] = useState(null);
    const [orderBy, setOrderBy] = useState('sendDateTime');
    const [orderDesc, setOrderDesc] = useState(true);
    const [isInitialising, setIsInitialising] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [tempSearchValue, setTempSearchValue] = useSearch(setSearchValue);
    const [filterByPeriod, setFilterByPeriod] = useState('none');
    const [tab, setTab] = useState('navigation');

    const showB = compare && summaryB;

    const handleFetchCount = async () => {
        const filterByPeriodDates = filterByPeriod !== 'none' ? filterByPeriod === 'a' ? selectedPeriods[0] : selectedPeriods[1] : null;

        const from = filterByPeriodDates !== null ? format(filterByPeriodDates[0], 'yyyy-MM-dd') : fromDate ? format(fromDate, 'yyyy-MM-dd') : null;
        const to = filterByPeriodDates !== null ? format(filterByPeriodDates[1], 'yyyy-MM-dd') : toDate ? format(toDate, 'yyyy-MM-dd') : null;

        const params = {
            search: searchValue,
            ...(from && { fromDate: from }),
            ...(to && { toDate: to })
        };

        const url = buildUrl('reports/count', params);
        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setCount(data);
    };

    const handleFetchReports = async () => {
        setIsLoading(true);

        const filterByPeriodDates = filterByPeriod !== 'none' ? filterByPeriod === 'a' ? selectedPeriods[0] : selectedPeriods[1] : null;

        const from = filterByPeriodDates !== null ? format(filterByPeriodDates[0], 'yyyy-MM-dd') : fromDate ? format(fromDate, 'yyyy-MM-dd') : null;
        const to = filterByPeriodDates !== null ? format(filterByPeriodDates[1], 'yyyy-MM-dd') : toDate ? format(toDate, 'yyyy-MM-dd') : null;

        const params = {
            search: searchValue,
            ...(from && { fromDate: from }),
            ...(to && { toDate: to }),
            skip: page * rowsPerPage,
            take: rowsPerPage,
            orderBy,
            isDescending: orderDesc
        };

        const url = buildUrl('reports', params);
        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setReports(data);
        setIsLoading(false);
    };

    const handleFetchSummary = async (period, isB = false) => {
        const fromDate = period[0];
        const toDate = period[1];

        const params = {
            ...(fromDate && { fromDate: format(fromDate, 'yyyy-MM-dd') }),
            ...(toDate && { toDate: format(toDate, 'yyyy-MM-dd') })
        };

        const url = buildUrl('reports/emails/summary', params);
        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        const data = await response.json();

        isB ? setSummaryB(data) : setSummaryA(data);

        !isInitialising && setIsLoading(false);
    };

    const handleSort = (field, defaultOrder) => {
        if (field === orderBy) {
            setOrderDesc(!orderDesc);
        }
        else {
            setOrderBy(field);
            setOrderDesc(defaultOrder);
        }
    };

    const handleChangeToDate = date => {
        setFilterByPeriod('none');
        setToDate(date);
    };

    const handleChangeFromDate = date => {
        setFilterByPeriod('none');
        setFromDate(date);
    };

    const handleChangeFilterByPeriod = period => {
        setFilterByPeriod(period);
        setToDate(null);
        setFromDate(null);
    };

    useEffect(() => {
        if (selectedPeriods.length === 0 || tab !== 'dashboard') {
            return;
        }

        // only fetch data if null or period has changed

        if (!summaryA || (selectedPeriods[0] !== prevSelectedPeriods[0])) {
            handleFetchSummary(selectedPeriods[0]);
        }

        if (compare && selectedPeriods.length > 1 && (!summaryB || selectedPeriods[1] !== prevSelectedPeriods[1])) {
            handleFetchSummary(selectedPeriods[1], true);
        }
    }, [selectedPeriods, tab]);

    useEffect(() => {
        reports && setIsInitialising(false);
    }, [reports]);

    useEffect(() => {
        handleFetchCount();
    }, [searchValue, fromDate, toDate, filterByPeriod]);

    useEffect(() => {
        setPage(0);
    }, [searchValue, fromDate, toDate, orderBy, orderDesc, rowsPerPage, filterByPeriod]);

    useEffect(() => {
        handleFetchReports();
    }, [searchValue, fromDate, toDate, orderBy, orderDesc, rowsPerPage, page, filterByPeriod]);

    useEffect(() => {
        if (filterByPeriod !== 'none') {
            if (filterByPeriod === 'a' && (selectedPeriods[0] !== prevSelectedPeriods[0]) || filterByPeriod === 'b' && (selectedPeriods[1] !== prevSelectedPeriods[1])) {
                setIsLoading(true);
                setPage(0);
                handleFetchCount();
                handleFetchReports();
            }
        }
    }, [selectedPeriods]);

    if (isInitialising) {
        return <LoadingOverlay />;
    }

    return (
        <>
            <AppBar
                position="sticky"
                color="inherit"
                sx={{
                    top: 112,
                    flexShrink: 0, // fixes a bug causing height to shrink on scroll
                    zIndex: theme.zIndex.appBar - 1,
                    borderTop: 1,
                    borderColor: theme.palette.divider
                }}
            >
                <Tabs value={tab} onChange={(e, value) => setTab(value)}>
                    <Tab label="Find a Report" value="navigation" />
                    <Tab label="Insights" value="dashboard" />
                </Tabs>
            </AppBar>
            <Box p={2}>
                {tab === 'dashboard' ? summaryA ? (
                    <Grid container spacing={2}>
                        <Grid xs={12} md={6}>
                            <SentimentCard
                                a={summaryA}
                                b={showB ? summaryB : null}
                                periodColours={periodColours}
                            />
                        </Grid>
                        <Grid xs={12} xl={9}>
                            <Card sx={{ m: 0, p: 0 }}>
                                <CardHeader
                                    sx={{ m: 0, py: 1 }}
                                    title="Average Send Size, Open and Click Rates"
                                    titleTypographyProps={{
                                        variant: 'h6',
                                        fontWeight: 'normal'
                                    }}
                                    avatar={<Margin />}
                                />
                                <CardContent
                                    sx={{
                                        m: 0,
                                        p: 0,
                                        '&:last-child': {
                                            paddingBottom: 0
                                        }
                                    }}
                                >
                                    <AverageSendOpenClickRatesChart
                                        a={summaryA}
                                        b={showB ? summaryB : null}
                                        periodColours={periodColours}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid xs={12} xl={3}>
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid xs={4} xl={12}>
                                        <CompareValues
                                            a={summaryA.averageSendSize}
                                            b={summaryB?.averageSendSize}
                                            showB={showB}
                                            text="Average Send Size"
                                            valueType="number"
                                            icon={<Margin fontSize="small" />}
                                            periodColours={periodColours}
                                            accuracy={0}
                                        />
                                    </Grid>
                                    <Grid xs={4} xl={12}>
                                        <CompareValues
                                            a={summaryA.averageOpenRate}
                                            b={summaryB?.averageOpenRate}
                                            showB={showB}
                                            text="Average Open Rate"
                                            valueType="rate"
                                            icon={<Drafts fontSize="small" />}
                                            periodColours={periodColours}
                                            accuracy={2}
                                        />
                                    </Grid>
                                    <Grid xs={4} xl={12}>
                                        <CompareValues
                                            a={summaryA.averageClickRate}
                                            b={summaryB?.averageClickRate}
                                            showB={showB}
                                            text="Average Click Rate"
                                            valueType="rate"
                                            icon={<AdsClick fontSize="small" />}
                                            periodColours={periodColours}
                                            accuracy={2}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid xs={12} xl={9}>
                            <TabbedHeatmapCard
                                a={summaryA}
                                b={summaryB}
                                showB={showB}
                            />
                        </Grid>
                        <Grid xs={12} xl={3}>
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid xs={4} xl={12}>
                                        <CompareValues
                                            a={summaryA.mostFrequentSendTime}
                                            b={summaryB?.mostFrequentSendTime}
                                            showB={showB}
                                            text="Most Frequent Send Time"
                                            valueType="hour"
                                            icon={<ScheduleSend fontSize="small" />}
                                            periodColours={periodColours}
                                        />
                                    </Grid>
                                    <Grid xs={4} xl={12}>
                                        <CompareValues
                                            a={summaryA.bestSendTimeForOpens}
                                            b={summaryB?.bestSendTimeForOpens}
                                            showB={showB}
                                            text="Best Send Time for Opens"
                                            valueType="hour"
                                            icon={<DepartureBoard fontSize="small" />}
                                            periodColours={periodColours}
                                        />
                                    </Grid>
                                    <Grid xs={4} xl={12}>
                                        <CompareValues
                                            a={summaryA.bestSendTimeForClicks}
                                            b={summaryB?.bestSendTimeForClicks}
                                            showB={showB}
                                            text="Best Send Time for Clicks"
                                            valueType="hour"
                                            icon={<Watch fontSize="small" />}
                                            periodColours={periodColours}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid xs={12} md={4} xl={3}>
                            <CompareValues
                                a={summaryA.sumOfTotalOpens}
                                b={summaryB?.sumOfTotalOpens}
                                showB={showB}
                                text="Total Opens"
                                valueType="number"
                                icon={<Drafts fontSize="small" />}
                                periodColours={periodColours}
                            />
                        </Grid>
                        <Grid xs={12} md={4} xl={3}>
                            <CompareValues
                                a={summaryA.sumOfTotalClicks}
                                b={summaryB?.sumOfTotalClicks}
                                showB={showB}
                                text="Total Clicks"
                                valueType="number"
                                icon={<AdsClick fontSize="small" />}
                                periodColours={periodColours}
                            />
                        </Grid>
                        <Grid xs={12} md={4} xl={3}>
                            <CompareValues
                                a={summaryA.sumOfUniqueOpens}
                                b={summaryB?.sumOfUniqueOpens}
                                showB={showB}
                                text="Unique Opens"
                                valueType="number"
                                icon={<Drafts fontSize="small" />}
                                periodColours={periodColours}
                            />
                        </Grid>
                        <Grid xs={12} md={4} xl={3}>
                            <CompareValues
                                a={summaryA.sumOfUniqueClicks}
                                b={summaryB?.sumOfUniqueClicks}
                                showB={showB}
                                text="Unique Clicks"
                                valueType="number"
                                icon={<AdsClick fontSize="small" />}
                                periodColours={periodColours}
                            />
                        </Grid>
                        <Grid xs={12} xl={3}>
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid xs={4} xl={12}>
                                        <BestSubject
                                            a={summaryA.bestSubjectLineLengthByOpenRate}
                                            b={summaryB?.bestSubjectLineLengthByOpenRate}
                                            showB={showB}
                                            periodColours={periodColours}
                                        />
                                    </Grid>
                                    <Grid xs={4} xl={12}>
                                        <CompareValues
                                            a={summaryA.totalEmailsCreated}
                                            b={summaryB?.totalEmailsCreated}
                                            showB={showB}
                                            text="Emails Created"
                                            valueType="number"
                                            icon={<Edit fontSize="small" />}
                                            periodColours={periodColours}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid xs={12} xl={9}>
                            <Card sx={{ m: 0, p: 0 }}>
                                <CardHeader
                                    sx={{ m: 0, py: 1 }}
                                    title="Engagement by Subject Line Length"
                                    titleTypographyProps={{
                                        variant: 'h6',
                                        fontWeight: 'normal'
                                    }}
                                    avatar={<ShortText />}
                                />
                                <CardContent
                                    sx={{
                                        m: 0,
                                        p: 0,
                                        '&:last-child': {
                                            paddingBottom: 0
                                        }
                                    }}
                                >
                                    <SubjectLengthEngagementChart
                                        a={summaryA}
                                        b={showB ? summaryB : null}
                                        periodColours={periodColours}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={4} xl={3}>
                            <CompareValues
                                a={summaryA.averageSendFrequency}
                                b={summaryB?.averageSendFrequency}
                                showB={showB}
                                text="Average Send Frequency"
                                valueType="number"
                                icon={<Send fontSize="small" />}
                                periodColours={periodColours}
                                explanation={(
                                    <>
                                        <Typography variant="body1" fontWeight="bold" sx={{ pb: 0.5 }}>Average Send Frequency</Typography>
                                        <Typography variant="body2" color={theme.palette.text.secondary}>Total campaigns sent divided by the number of weeks in the selected time period</Typography>
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid xs={12} md={4} xl={3}>
                            <CompareValues
                                a={summaryA.averageUnsubscribeRate}
                                b={summaryB?.averageUnsubscribeRate}
                                showB={showB}
                                text="Average Unsubscribe Rate"
                                valueType="rate"
                                icon={<Unsubscribe fontSize="small" />}
                                periodColours={periodColours}
                                accuracy={2}
                            />
                        </Grid>
                        <Grid xs={12} md={4} xl={3}>
                            <CompareValues
                                a={summaryA.sumOfHardBounces}
                                b={summaryB?.sumOfHardBounces}
                                showB={showB}
                                text="Hard Bounces"
                                valueType="number"
                                icon={<Block fontSize="small" />}
                                periodColours={periodColours}
                            />
                        </Grid>
                        <Grid xs={12} md={4} xl={3}>
                            <CompareValues
                                a={summaryA.sumOfSoftBounces}
                                b={summaryB?.sumOfSoftBounces}
                                showB={showB}
                                text="Soft Bounces"
                                valueType="number"
                                icon={<Block fontSize="small" />}
                                periodColours={periodColours}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={2}>
                        <Grid xs={12} md={8}>
                            <Stack spacing={2} padding={2}>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </Stack>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Stack spacing={2} padding={2}>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </Stack>
                        </Grid>
                    </Grid>
                ) : (
                    <Card sx={{ m: 0, p: 0 }}>
                        <CardContent
                            sx={{
                                m: 0,
                                p: 0,
                                '&:last-child': {
                                    paddingBottom: 0
                                }
                            }}
                        >
                            <Toolbar disableGutters sx={{ mx: 1 }}>
                                <OutlinedInput
                                    value={tempSearchValue}
                                    onChange={e => setTempSearchValue(e.target.value)}
                                    placeholder="Search..."
                                    size="small"
                                    sx={{ m: 1 }}
                                    endAdornment={(
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setTempSearchValue('')}
                                                edge="end"
                                                disabled={!Boolean(tempSearchValue)}
                                            >
                                                <Clear />
                                            </IconButton>
                                        </InputAdornment>
                                    )}
                                />
                                <DatePicker
                                    value={fromDate}
                                    onChange={handleChangeFromDate}
                                    label="From Date"
                                />
                                <DatePicker
                                    value={toDate}
                                    onChange={handleChangeToDate}
                                    label="To Date"
                                />
                                <FormControl margin="none">
                                    <InputLabel id="select-period-dates-label">Filter by Period</InputLabel>
                                    <Select
                                        labelId="select-period-dates-label"
                                        id="select-period-dates"
                                        value={filterByPeriod}
                                        label="Filter by Period"
                                        onChange={e => handleChangeFilterByPeriod(e.target.value)}
                                        size="small"
                                        sx={{ minWidth: 120 }}
                                    >
                                        <MenuItem value="none">None</MenuItem>
                                        {selectedPeriods.length > 0 && (
                                            <MenuItem value="a">
                                                <Typography variant="caption" sx={{ color: theme.palette.text.secondary, mr: 1, display: 'inline' }}>
                                                    Period A:
                                                </Typography>
                                                <RenderPeriodDateRange value1={selectedPeriods[0][0]} value2={selectedPeriods[0][1]} />
                                            </MenuItem>
                                        )}
                                        {(selectedPeriods.length > 1 && showB) && (
                                            <MenuItem value="b">
                                                <Typography variant="caption" sx={{ color: theme.palette.text.secondary, mr: 1, display: 'inline' }}>
                                                    Period B:
                                                </Typography>
                                                <RenderPeriodDateRange value1={selectedPeriods[1][0]} value2={selectedPeriods[1][1]} />
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Toolbar>
                            <Divider />
                            {isLoading ? (
                                <Box py={4}>
                                    <CenteredProgress colour="primary" />
                                </Box>
                            ) : (
                                <>
                                    <TableContainer>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map(col => (
                                                        <TableCell
                                                            key={col.name}
                                                            component="th"
                                                            scope="row"
                                                            align={col.align}
                                                            width={col.width || 'auto'}
                                                        >
                                                            <TableSortLabel
                                                                active={orderBy === col.name}
                                                                direction={orderDesc ? 'desc' : 'asc'}
                                                                onClick={() => handleSort(col.name, col.orderDesc)}
                                                            >
                                                                {col.label}
                                                            </TableSortLabel>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {reports.map(report => (
                                                    <TableRow
                                                        key={report.id}
                                                        hover={true}
                                                        onClick={() => history.push(`/newreports/emails/${report.id}`)}
                                                    >
                                                        {columns.map(col => (
                                                            <DataColumn
                                                                key={col.name}
                                                                align={col.align}
                                                                dataType={col.dataType}
                                                                value={report[col.name]}
                                                            />
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={rowsPerPageOptions}
                                        component="div"
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={(e, newPage) => setPage(newPage)}
                                        onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
                                    />
                                </>
                            )}
                        </CardContent>
                    </Card>
                )}
            </Box>
        </>
    );
};

export default Emails;