import React from 'react';
import { useHistory } from 'react-router-dom';
import Card from '@mui/material/Card';
import useTheme from '@mui/material/styles/useTheme';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Unstable_Grid2';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import useAccount from '../hooks/useAccount';
import Chip from '@mui/material/Chip';
import { format } from 'date-fns';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';

const AuthAndAdminStatus = ({ accountInfo }) => {
    const history = useHistory();
    const theme = useTheme();
    const { user } = useAccount();
    const domain = accountInfo.sendingDomain ? accountInfo.sendingDomain.substring(accountInfo.sendingDomain.indexOf('.') + 1) : '';

    const isAuthenticated =
        accountInfo.mxCheck &&
        accountInfo.dkim &&
        accountInfo.dmarc &&
        accountInfo.spf;

    const hasManageUsersPermission = (user) =>
        user.permissions.some((permission) => permission.name === 'Manage Users');

    const usersWithPermission = accountInfo.users
        .filter((u) => hasManageUsersPermission(u))
        .sort((a, b) => {
            const isLoggedInUser = (u) => u.emailAddress === user.emailAddress;

            if (isLoggedInUser(a)) return -1;
            if (isLoggedInUser(b)) return 1;

            return 0;
        });

    const isSingleUser = accountInfo.users.length === 1;

    return (
        <Grid container spacing={1}>
            <Grid xs={12} sm={isSingleUser ? 12 : 6} className="email-auth">
                <Card
                    elevation={4}
                    sx={{
                        minHeight: 150,
                        maxHeight: 150,
                        borderRadius: 3,
                        px: 1,
                        borderTop: isAuthenticated ? 'none' : `5px solid ${theme.palette.error.main}`,
                        '&:hover': {
                            elevation: 16,
                            boxShadow: 10,
                            '.action-icon': { opacity: 1 }
                        }
                    }}
                >
                    <CardHeader
                        sx={{ pb: 0 }}
                        title={(
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'primary.main'
                                }}
                            >
                                Email Authentication
                            </Typography>
                        )}
                        action={(
                            <Tooltip title="Configuration" arrow>
                                <IconButton
                                    className="action-icon"
                                    sx={{
                                        opacity: 0,
                                        transition: 'opacity 0.3s'
                                    }}
                                    onClick={() => {
                                        history.push('/account/configuration');
                                    }}
                                >
                                    <ArrowForwardIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    />
                    <CardContent sx={{ pt: 0 }}>
                        <Box
                            sx={{ display: 'flex', alignItems: 'center' }}
                        >
                            <Typography
                                variant="body1"
                                sx={{ fontWeight: 'medium' }}
                            >
                                {domain}
                            </Typography>
                        </Box>
                        {isAuthenticated ? (
                            <Box sx={{ display: 'flex' }}>
                                <CheckCircleOutlineIcon color="success" fontSize="medium" sx={{ mt: 1.5, mr: 1 }} />
                                <Typography variant="subtitle2" sx={{ mr: 1, mt: 1.7 }}>
                                    Authenticated - {' '}
                                    <strong>
                                        {accountInfo.spf ? format(new Date(accountInfo.spf), 'dd/MM/yyyy') : '-'}
                                    </strong>
                                </Typography>
                            </Box>
                        ) : (
                            <Box sx={{ display: 'flex' }}>
                                <ErrorOutlineIcon color="error" fontSize="medium" sx={{ mt: 1.5, mr: 1 }} />
                                <Typography variant="body2" sx={{ mr: 1, mt: 2, color: 'error.main' }}>
                                    <strong>
                                        Not Authenticated
                                    </strong>
                                </Typography>
                            </Box>
                        )}
                    </CardContent>
                </Card>
            </Grid>

            {
                !isSingleUser && (
                    <Grid xs={12} sm={6} className="admin-users">
                        <Card
                            elevation={4}
                            sx={{
                                minHeight: 150,
                                maxHeight: 150,
                                borderRadius: 3,
                                px: 1,
                                '&:hover': {
                                    elevation: 16,
                                    boxShadow: 10,
                                    '.action-icon': { opacity: 1 }
                                }
                            }}
                        >
                            <CardHeader
                                sx={{ pb: 0 }}
                                title={(
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        sx={{
                                            fontWeight: 'bold',
                                            color: 'primary.main'
                                        }}
                                    >
                                        Admin Users
                                    </Typography>
                                )}
                                action={(
                                    <Tooltip title="My Account/Users" arrow>
                                        <IconButton
                                            className="action-icon"
                                            sx={{
                                                opacity: 0,
                                                transition: 'opacity 0.3s'
                                            }}
                                            onClick={() => {
                                                history.push({
                                                    pathname: '/account',
                                                    state: { tab: 'userLimit' }
                                                });
                                            }}
                                        >
                                            <ArrowForwardIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            />
                            <CardContent sx={{ pt: 0, px: 1 }}>
                                <Box
                                    sx={{
                                        maxHeight: 80,
                                        overflowY: 'auto',
                                        '&::-webkit-scrollbar': {
                                            width: '6px'
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                            borderRadius: '3px'
                                        }
                                    }}
                                >
                                    {usersWithPermission.map((user, index) => (
                                        <ListItem key={user.id} sx={{ p: 0 }}>
                                            <ListItemAvatar>
                                                <Avatar
                                                    sx={{
                                                        width: 35,
                                                        height: 35
                                                    }}
                                                    alt={user.name}
                                                    src={user.avatarURL}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={(
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            whiteSpace: 'normal',
                                                            wordWrap: 'break-word'
                                                        }}
                                                    >
                                                        {user.name}
                                                        {index === 0 && (
                                                            <Chip
                                                                label="You"
                                                                color="primary"
                                                                size="small"
                                                                sx={{ ml: 1 }}
                                                            />
                                                        )}
                                                    </Box>
                                                )}
                                                secondary={user.emailAddress}
                                                sx={{
                                                    whiteSpace: 'normal',
                                                    wordWrap: 'break-word'
                                                }}
                                            />
                                        </ListItem>
                                    ))}
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                )
            }
        </Grid>
    );
};

export default AuthAndAdminStatus;