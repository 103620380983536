import { useState } from 'react';

const useInputDate = initialValue => {
    const initialDate = initialValue ? Date.parse(initialValue) : initialValue;
    const [value, setValue] = useState(initialDate);

    return {
        value,
        onChange: value => setValue(value),
        onRevert: () => setValue(initialDate),
        hasChanged: value !== initialDate
    };
};

export default useInputDate;