import React, { useEffect, useState, useRef } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import ReactECharts from 'echarts-for-react';
import usePrevious from '../../hooks/usePrevious';
import { lighten } from '@mui/system';

const AverageSendOpenClickRatesChart = ({ a, b, periodColours }) => {
    const ref = useRef(null);
    const theme = useTheme();
    const [series, setSeries] = useState(null);
    const [yAxis, setYAxis] = useState(['Period A']);
    const [maxSize, setMaxSize] = useState(0);
    const [maxRate, setMaxRate] = useState(0);
    const prevSeries = usePrevious(series);
    const padding = theme.spacing(3);
    const chartHeight = 400;

    const options = {
        grid: {
            containLabel: true,
            left: padding,
            top: padding,
            right: padding,
            bottom: padding
        },
        tooltip: {
            show: true,
            formatter: (params) => `${params.seriesName}<br />${params.marker}${params.name}: <strong>${params.seriesIndex !== 0 ? params.value.toFixed(2) + '%' : Math.ceil(params.value)}</strong>`
        },
        yAxis: {
            type: 'category',
            data: yAxis,
            inverse: true
        },
        xAxis: [
            {
                type: 'value',
                min: 0,
                max: maxSize,
                position: 'bottom',
                alignTicks: true,
                axisLabel: {
                    formatter: (value) => Math.ceil(value)
                }
            },
            {
                type: 'value',
                min: 0,
                max: maxRate,
                position: 'top',
                axisLabel: {
                    formatter: '{value}%'
                }
            }
        ],
        series
    };

    useEffect(() => {
        const renderLabel = params => {
            return params.value === 0
                ? ''
                : `${params.seriesName}: ${params.seriesIndex !== 0
                    ? Number.isInteger(params.value)
                        ? params.value + '%'
                        : params.value.toFixed(2) + '%'
                    : Math.ceil(params.value)}`;
        };

        let yAxis = [];
        let maxSize = Math.ceil(a.averageSendSize / 100) * 100;
        let maxRate = Math.ceil(Math.max(a.averageOpenRate, a.averageClickRate));

        let series = [
            {
                type: 'bar',
                name: 'Average Send Size',
                xAxisIndex: 0,
                data: [a.averageSendSize],
                itemStyle: {
                    color: lighten(periodColours[0], 0.8)
                },
                label: {
                    show: true,
                    formatter: (params) => renderLabel(params)
                }
            },
            {
                type: 'bar',
                name: 'Average Open Rate',
                xAxisIndex: 1,
                data: [a.averageOpenRate],
                itemStyle: {
                    color: lighten(periodColours[0], 0.4)
                },
                label: {
                    show: true,
                    formatter: (params) => renderLabel(params)
                }
            },
            {
                type: 'bar',
                name: 'Average Click Rate',
                xAxisIndex: 1,
                data: [a.averageClickRate],
                itemStyle: {
                    color: periodColours[0]
                },
                label: {
                    show: true,
                    formatter: (params) => renderLabel(params)
                }
            }
        ];

        if (b) {
            series = [
                {
                    type: 'bar',
                    name: 'Average Send Size',
                    xAxisIndex: 0,
                    data: [a.averageSendSize, b.averageSendSize],
                    itemStyle: {
                        color: e => e.dataIndex === 0 ? lighten(periodColours[0], 0.8) : lighten(periodColours[1], 0.8)
                    },
                    label: {
                        show: true,
                        formatter: (params) => renderLabel(params)
                    }
                },
                {
                    type: 'bar',
                    name: 'Average Open Rate',
                    xAxisIndex: 1,
                    data: [a.averageOpenRate, b.averageOpenRate],
                    itemStyle: {
                        color: e => e.dataIndex === 0 ? lighten(periodColours[0], 0.4) : lighten(periodColours[1], 0.4)
                    },
                    label: {
                        show: true,
                        formatter: (params) => renderLabel(params)
                    }
                },
                {
                    type: 'bar',
                    name: 'Average Click Rate',
                    xAxisIndex: 1,
                    data: [a.averageClickRate, b.averageClickRate],
                    itemStyle: {
                        color: e => e.dataIndex === 0 ? periodColours[0] : periodColours[1]
                    },
                    label: {
                        show: true,
                        formatter: (params) => renderLabel(params)
                    }
                }
            ];

            yAxis = ['Period A', 'Period B'];

            maxSize = Math.ceil(Math.max(a.averageSendSize, b.averageSendSize) / 100) * 100;
            maxRate = Math.ceil(Math.max(a.averageOpenRate, a.averageClickRate, b.averageOpenRate, b.averageClickRate));
        }

        setSeries(series);
        setYAxis(yAxis);
        setMaxSize(maxSize);
        setMaxRate(maxRate);
    }, [a, b]);

    useEffect(() => {
        // replaceMerge param is necessary to remove the 2nd dataset

        if (ref?.current && series && (series.length < prevSeries?.length)) {
            ref.current?.getEchartsInstance().setOption(options, { replaceMerge: ['series', 'yAxis', 'xAxis'] });
        }
    }, [series]);

    return (
        <ReactECharts
            option={options}
            style={{ height: chartHeight }}
            opts={{ renderer: 'svg' }}
            ref={ref}
        />
    );
};

export default AverageSendOpenClickRatesChart;
