import React, { useEffect, useState, useCallback } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import InboxPreviewDialog from '../dialogs/InboxPreviewDialog';
import FormHelperText from '@mui/material/FormHelperText';
import Picker from '../../picker/Picker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import useAccount from '../../hooks/useAccount';
import useApi from '../../hooks/useApi';
import useSnackbar from '../../hooks/useSnackbar';
import ShoppingList from '../../editor/ShoppingList';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

const Setup = ({ email, onUpdateEmail, onNext, onBack, onFetchTopics, drawerWidth, steps, step, campaign, isProofSent, audienceName, titleBarDepth, isTemplate = false, setStep, type = 'email' }) => {
    const { smtpUsername, safeSenders, handleCreateTopic } = useAccount();
    const { handleGet, handlePut } = useApi();
    const { showSnackbar } = useSnackbar();

    const [isInitialising, setisInitialising] = useState(true);
    const [showInboxPreview, setShowInboxPreview] = useState(false);
    const [focusFromEmail, setFocusFromEmail] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [form, setForm] = useState(null);
    const [fromEmailField, setFromEmailField] = useState(null);
    const [isFromEmailValid, setIsFromEmailValid] = useState();
    const [topics, setTopics] = useState(null);

    const [showUnsafeSendersField, setShowUnsafeSendersField] = useState(safeSenders.length === 0 || (email.fromName > '' && !safeSenders.includes(email.fromEmail)));
    const [safeSendersFromEmail, setSafeSendersFromEmail] = useState(email.fromEmail > '' ? safeSenders.includes(email.fromEmail) ? email.fromEmail : 'other' : safeSenders.length > 0 ? safeSenders[0] : 'other');

    const [subject, setSubject] = useState(type === 'page' ? email.title : email.subject);
    const [fromName, setFromName] = useState(email.fromName);
    const [fromEmail, setFromEmail] = useState(email.fromEmail);
    const [gaCampaign, setGaCampaign] = useState(email.gaCampaign);

    const handleFetchTopics = async () => {
        const response = await handleGet('topics');

        if (!response.ok) {
            showSnackbar('Error fetching topics', 'error');
            return;
        }

        const data = await response.json();
        setTopics(data);
    };

    const handleUpdateEmailTopics = async (id, topicIds) => {
        const params = {
            autoSave: false,
            ...(topicIds && { topicIds })
        };

        const response = await handlePut(`emails/${id}`, params);

        if (!response.ok) {
            showSnackbar('Email or Topic not found', 'error');
        }
        else {
            onFetchTopics();
        }
    };

    const handleUpdatePageTopics = async (id, topicIds) => {
        const params = {
            ...email,
            autoSave: false,
            ...(topicIds && { topicIds })
        };

        const response = await handlePut(`pages/${id}`, params);

        if (!response.ok) {
            showSnackbar('Landing Page or Topic not found', 'error');
        }
        else {
            onFetchTopics();
        }
    };

    const handleUpdateTopics = async ({ id, topics = null }) => {
        const topicIds = topics?.map(e => e.id);

        if (type === 'page') {
            handleUpdatePageTopics(id, topicIds);
        }
        else {
            handleUpdateEmailTopics(id, topicIds);
        }
    };

    const onRefChange = useCallback(node => setForm(node), []);

    const onFromEmailFieldChange = useCallback(node => setFromEmailField(node), []);

    const handleShowInboxPreview = () => {
        setShowInboxPreview(true);
    };

    const handleSave = () => {
        if (type === 'page') {
            onUpdateEmail({
                title: subject,
                gaCampaign
            });
        }
        else {
            onUpdateEmail({
                subject,
                fromName,
                fromEmail: smtpUsername || fromEmail,
                gaCampaign
            });
        }
    };

    const handleNext = () => {
        handleSave();

        onNext({
            subject,
            ...(type === 'page' && { title: subject }),
            fromName,
            fromEmail,
            gaCampaign,
            name: email.name
        });
    };

    const handleBack = () => {
        handleSave();

        onBack({
            subject,
            ...(type === 'page' && { title: subject }),
            fromName,
            fromEmail,
            gaCampaign,
            name: email.name
        });
    };

    const handleCheckIsValid = () => {
        return (form && form.checkValidity());
    };

    const handleSubmitCreateTopic = async ({ name, description, colour }) => {
        await handleCreateTopic({
            name,
            description,
            colour
        });

        await handleFetchTopics();
    };

    useEffect(() => {
        (async () => {
            await handleFetchTopics();
            setisInitialising(false);
        })();
    }, []);

    useEffect(() => {
        setIsValid(handleCheckIsValid());
    }, [form, subject, fromName, fromEmail, gaCampaign]);

    useEffect(() => {
        fromEmailField && setIsFromEmailValid(() => fromEmailField.checkValidity());
    }, [fromEmail]);

    useEffect(() => {
        setShowUnsafeSendersField(safeSenders.length === 0 || safeSendersFromEmail === 'other');
    }, [safeSenders, safeSendersFromEmail, fromEmail]);

    useEffect(() => {
        if (safeSendersFromEmail !== 'other') {
            setFromEmail(safeSendersFromEmail);
        }

        if (!isInitialising && safeSendersFromEmail === 'other') {
            setFocusFromEmail(true);
            setFromEmail('');
        }
    }, [safeSendersFromEmail]);

    return (
        <>
            <AppBar position="sticky" color="inherit" sx={{ top: 64, zIndex: titleBarDepth }}>
                <Toolbar>
                    <Typography component="h1" variant="h6">{type === 'email' ? 'Campaign' : 'Landing Page'} Setup</Typography>
                </Toolbar>
            </AppBar>
            <div style={{ marginRight: drawerWidth, flexGrow: 1, height: 'calc(100vh - 130px)', overflowY: 'auto' }}>
                {isInitialising ? <LoadingOverlay /> : (
                    <Container disableGutters>
                        <Paper sx={{ m: 2, p: 3 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <form ref={onRefChange}>
                                        <FormControl fullWidth>
                                            <TextField required value={subject} onChange={e => setSubject(e.target.value)} label={type === 'page' ? 'Title' : 'Subject line'} margin="none" />
                                        </FormControl>
                                        {type !== 'page' && (
                                            <>
                                                <FormControl fullWidth>
                                                    <TextField required value={fromName} onChange={e => setFromName(e.target.value)} label="Email from name" margin="none" />
                                                </FormControl>
                                                {smtpUsername ? (
                                                    <FormControl fullWidth>
                                                        <TextField disabled value={smtpUsername} label="From email address" margin="none" />
                                                        <FormHelperText margin="dense">You cannot change your from email address when using a custom SMTP configuration.</FormHelperText>
                                                    </FormControl>
                                                ) : (
                                                    <>
                                                        {safeSenders.length > 0 && (
                                                            <FormControl fullWidth>
                                                                <InputLabel>From email address</InputLabel>
                                                                <Select
                                                                    required
                                                                    value={safeSendersFromEmail}
                                                                    onChange={e => setSafeSendersFromEmail(e.target.value)}
                                                                    label="From email address"
                                                                    margin="none"
                                                                    displayEmpty
                                                                >
                                                                    {safeSenders.map((emailAddress, i) => (
                                                                        <MenuItem key={i} value={emailAddress}>
                                                                            {emailAddress}
                                                                        </MenuItem>
                                                                    ))}
                                                                    <MenuItem value="other">Enter address not on Safe Senders list</MenuItem>
                                                                </Select>
                                                                <FormHelperText margin="dense">To ensure email delivery, your from address should be on your organisation's Safe Senders list. Contact your account manager to add an address.</FormHelperText>
                                                            </FormControl>
                                                        )}
                                                        {showUnsafeSendersField && (
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    autoFocus={focusFromEmail}
                                                                    type="email"
                                                                    error={isFromEmailValid === false}
                                                                    inputRef={onFromEmailFieldChange}
                                                                    required
                                                                    value={fromEmail}
                                                                    onChange={e => setFromEmail(e.target.value)}
                                                                    label="From email address"
                                                                    margin="none"
                                                                    sx={{ ml: safeSenders.length > 0 ? 2 : 0 }}
                                                                />
                                                            </FormControl>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                        <FormControl fullWidth>
                                            <TextField value={gaCampaign} onChange={e => setGaCampaign(e.target.value)} label="Google Analytics campaign" margin="none" />
                                        </FormControl>
                                        {!isTemplate && (
                                            <Box sx={{ border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '4px', marginTop: 2, marginBottom: 1, py: 1 }}>
                                                <Table size="small">
                                                    <TableBody>
                                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <Picker
                                                                availableItems={topics}
                                                                selectedItems={email.topics}
                                                                onChangeItems={topics => handleUpdateTopics({ id: email.id, topics })}
                                                                onCreateTopic={handleSubmitCreateTopic}
                                                            />
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        )}
                                    </form>
                                    {type === 'email' && (
                                        <FormControl>
                                            <Button onClick={handleShowInboxPreview}>
                                                Inbox Preview
                                            </Button>
                                        </FormControl>
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Container>
                )}
            </div>
            <ShoppingList
                drawerWidth={drawerWidth}
                stepNumber={step}
                steps={steps}
                disabled={!isValid}
                onNext={handleNext}
                onBack={handleBack}
                campaign={campaign}
                email={email}
                isProofSent={isProofSent}
                audienceName={audienceName}
                setStep={setStep}
            />
            {showInboxPreview && (
                <InboxPreviewDialog
                    open={showInboxPreview}
                    onClose={() => setShowInboxPreview(false)}
                    fromName={fromName}
                    fromEmail={smtpUsername || fromEmail}
                    subject={subject}
                    html={email.html}
                />
            )}
        </>
    );
};

export default Setup;