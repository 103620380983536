import React from 'react';
import { Typography, Avatar, ListItemText, ListItemAvatar, ListItemButton, Box } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import ChangeChip from './ChangeChip';
import { useHistory } from 'react-router-dom';

const CustomListItem = ({ a, b, showB, text, icon, periodColours, route = null, lastItem = false, showChange = true }) => {
    const history = useHistory();
    const theme = useTheme();
    const change = showB ? b - a : 0;

    const handleClick = () => {
        history.push(route);
    };

    return (
        <ListItemButton
            divider={!lastItem}
            secondaryAction={(showChange && showB) ? (
                <ChangeChip
                    a={a}
                    b={b}
                    change={change}
                />
            ) : undefined}
            onClick={handleClick}
            disabled={!route}
        >
            <ListItemAvatar>
                <Avatar>
                    {icon}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={(
                    <React.Fragment>
                        {new Intl.NumberFormat().format(a)}
                        {showB && (
                            <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline', mx: 0.5, color: theme.palette.text.secondary }}
                                    variant="body1"
                                    fontWeight="normal"
                                    lineHeight={1}
                                >
                                    vs
                                </Typography>
                                <Box component="span" sx={{ display: 'inline', color: periodColours[1], lineHeight: 1 }}>
                                    {new Intl.NumberFormat().format(b)}
                                </Box>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
                primaryTypographyProps={{
                    color: periodColours[0],
                    variant: 'h6',
                    fontWeight: 'bold',
                    lineHeight: 1
                }}
                secondary={text}
            />
        </ListItemButton>
    );
};

export default CustomListItem;