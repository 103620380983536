import React from 'react';
import { Typography, Card, CardHeader, CardContent, Collapse, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import useTheme from '@mui/material/styles/useTheme';
import TypedValue from './../TypedValue';
import ChangeChip from './ChangeChip';
import BetterTooltip from '../BetterTooltip';

const Action = ({ explanation, showChangeChip, a, b }) => {

    if (explanation === null) {
        if (!showChangeChip) {
            return null;
        }

        return (
            <ChangeChip
                change={b - a}
                a={a}
                b={b}
            />
        );
    }
    else {
        if (!showChangeChip) {
            return (
                <BetterTooltip>
                    {explanation}
                </BetterTooltip>
            );
        }

        return (
            <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
                <BetterTooltip>
                    {explanation}
                </BetterTooltip>
                <ChangeChip
                    change={b - a}
                    a={a}
                    b={b}
                />
            </Stack>
        );
    }
};

const CompareValues = ({ a, b, showB, text, icon, periodColours, valueType = 'number', showChange = true, accuracy = null, explanation = null }) => {
    const theme = useTheme();

    const showChangeChip = showChange && showB && valueType !== 'hour';

    return (
        <Card>
            <CardHeader
                avatar={icon}
                title={text}
                titleTypographyProps={{ variant: 'body1' }}
                sx={{
                    m: 0,
                    p: 1,
                    '& .MuiCardHeader-action': {
                        m: 0
                    },
                    '& .MuiCardHeader-avatar': {
                        mr: 1
                    }
                }}
                action={(
                    <Action
                        showChangeChip={showChangeChip}
                        explanation={explanation}
                        a={a}
                        b={b}
                    />
                )}
            />
            <CardContent
                sx={{
                    m: 0,
                    px: 2,
                    pt: 0,
                    pb: 2,
                    '&:last-child': {
                        paddingBottom: 2
                    }
                }}
            >
                <Grid container spacing={1}>
                    <Grid xs={12} sm={showB ? 6 : 12} sx={{ textAlign: 'center' }}>
                        <Collapse in={showB}>
                            <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
                                Period A:
                            </Typography>
                        </Collapse>
                        <Typography variant="h5" sx={{ color: periodColours[0] }}>
                            <TypedValue value={a} type={valueType} accuracy={accuracy} />
                        </Typography>
                    </Grid>
                    {showB && (
                        <Grid xs={12} sm={6} sx={{ textAlign: 'center' }}>
                            <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
                                Period B:
                            </Typography>
                            <Typography variant="h5" sx={{ color: periodColours[1] }}>
                                <TypedValue value={b} type={valueType} accuracy={accuracy} />
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default CompareValues;