import React from 'react';
import { Typography, Chip, Card, CardHeader, CardContent, Collapse } from '@mui/material';
import { ShortText } from '@mui/icons-material';
import useTheme from '@mui/material/styles/useTheme';
import Grid from '@mui/material/Unstable_Grid2';
import TypedValue from './../TypedValue';

const SubjectData = ({ data, colour, text, showPeriodLabel }) => {
    const theme = useTheme();

    if (!data) {
        return (
            <Grid xs={12}>
                <Collapse in={showPeriodLabel}>
                    <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
                        {text}
                    </Typography>
                </Collapse>
                <Typography variant="body1" sx={{ color: colour, lineHeight: 1, mb: 1 }}>
                    No Data
                </Typography>
            </Grid>
        );
    }

    return (
        <Grid xs={12}>
            {showPeriodLabel && (
                <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
                    {text}
                </Typography>
            )}
            <Chip label={`${TypedValue({ value: data.openRate, type: 'rate' })} Open Rate`} size="small" sx={{ ml: 1 }} />
            <Typography variant="h5" sx={{ color: colour }}>
                {`${data.subjectWordCount} word${data.subjectWordCount === 1 ? '' : 's'}`}
            </Typography>
            <Typography variant="body2" sx={{ color: colour, lineHeight: 1, mb: 1 }}>
                {`${data.subjectLength} character${data.subjectLength === 1 ? '' : 's'}`}
            </Typography>
            <Typography variant="caption" sx={{ color: theme.palette.text.primary }} fontStyle="italic">
                '{data.subject}'
            </Typography>
        </Grid>
    );
};

const BestSubject = ({ a, b, showB, periodColours }) => {
    return (
        <Card>
            <CardHeader
                title="Best Subject Line Length"
                titleTypographyProps={{
                    variant: 'h6',
                    fontWeight: 'normal'
                }}
                sx={{
                    m: 0,
                    px: 2,
                    py: 1
                }}
                avatar={<ShortText />}
            />
            <CardContent
                sx={{
                    m: 0,
                    px: 2,
                    py: 1,
                    '&:last-child': {
                        paddingBottom: 1
                    }
                }}
            >
                <Grid container spacing={2}>
                    <SubjectData
                        colour={periodColours[0]}
                        data={a}
                        text="Period A:"
                        showPeriodLabel={showB}
                    />
                    {showB && (
                        <SubjectData
                            colour={periodColours[1]}
                            data={b}
                            text="Period B:"
                            showPeriodLabel={true}
                        />
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default BestSubject;