import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import DatePicker from '../../DatePicker';
import useInputDate from '../../hooks/useInputDate';
import isValid from 'date-fns/esm/fp/isValid/index';
import useSnackbar from '../../hooks/useSnackbar';
import { isPast } from 'date-fns';

const ConfirmSuppressContactDialog = ({ onClose, emailAddress, onConfirm }) => {
    const suppressUntilDateInput = useInputDate(null);
    const { showSnackbar } = useSnackbar();

    const handleConfirm = e => {
        e.preventDefault();
        isPast(suppressUntilDateInput.value) ? showSnackbar('Invalid date in the past', 'error') : onConfirm(suppressUntilDateInput.value);
        onClose();
    };

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Suppress {emailAddress}</DialogTitle>
            <DialogContent>
                <Alert sx={{ mb: 2 }} severity="warning">
                    Suppress this contact? This action is only temporary.
                </Alert>
                <Typography>
                    This contact will not receive campaigns until the beginning of the day you specify. You can unsuppress this contact at any time.
                </Typography>
                <DatePicker
                    label="Suppress Until Date"
                    value={suppressUntilDateInput.value}
                    onChange={suppressUntilDateInput.onChange}
                    fullWidth={true}
                    muiMarginType="normal"
                    disablePast={true}
                    disableFuture={false}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} disabled={!isValid(suppressUntilDateInput.value)}>Confirm Suppress</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmSuppressContactDialog;