import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import isValid from 'date-fns/isValid';
import enLocale from 'date-fns/locale/en-GB';

const DatePicker = ({ value, onChange, label, muiMarginType = 'none', disabled = false, fullWidth = false, width = null, size = 'small', disableFuture = true, disablePast = false, required = false, id = undefined }) => {
    const handleChangeDate = date => {
        if (!date) {
            onChange(null);
        }
        else if (isValid(date)) {
            onChange(date);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
            <MuiDatePicker
                mask="__/__/____"
                format="dd/MM/yyyy"
                disableFuture={disableFuture}
                disablePast={disablePast}
                value={value}
                onChange={handleChangeDate}
                clearable={true}
                clearText="Clear"
                label={label}
                disabled={disabled}
                slotProps={{
                    textField: {
                        id,
                        required,
                        size,
                        fullWidth,
                        margin: muiMarginType,
                        sx: width
                            ? { width }
                            : undefined
                    }
                }}
            />
        </LocalizationProvider>
    );
};

export default DatePicker;