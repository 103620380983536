import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import IconButton from '@mui/material/IconButton';
import useApi from '../../hooks/useApi';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';

const TeamsSummary = () => {
    const [groupedData, setGroupedData] = useState([]);
    const [expandedTeam, setExpandedTeam] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { handleGet } = useApi();

    const handleGetSummary = async () => {
        setIsLoading(true);

        const response = await handleGet('teams/summary');

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const data = await response.json();

        const grouped = Object.values(
            data.reduce((acc, item) => {
                const { teamsId, teamsName, channelName, channelId, created, campaigns } = item;

                const totalOpens = campaigns.reduce((sum, campaign) => sum + campaign.opens, 0);
                const averageOpens = campaigns.length > 0 ? totalOpens / campaigns.length : 0;

                if (!acc[teamsId]) {
                    acc[teamsId] = {
                        teamsId,
                        teamsName,
                        channels: []
                    };
                }

                acc[teamsId].channels.push({
                    channelName,
                    channelId,
                    created,
                    campaignsCount: campaigns.length,
                    averageOpens,
                    totalOpens
                });

                return acc;
            }, {})
        );

        setGroupedData(grouped);

        if (grouped.length > 0) {
            setExpandedTeam(grouped[0].teamsId);
        }

        setIsLoading(false);
    };

    const handleToggleExpand = (teamId) => {
        setExpandedTeam(expandedTeam === teamId ? null : teamId);
    };

    useEffect(() => {
        handleGetSummary();
    }, []);

    if (isLoading) {
        return <LoadingOverlay />;
    }

    return (
        <Box sx={{ padding: 2, display: 'grid', gap: 2 }} className="full-screen-teams-and-summary">
            {groupedData.map((group) => {
                return (
                    <Card key={group.teamsId}>
                        <CardHeader
                            sx={{ pb: 0 }}
                            title={(
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        sx={{ fontWeight: 'bold', color: 'primary.main' }}
                                    >
                                        {group.teamsName}
                                    </Typography>
                                    <Chip
                                        sx={{ ml: 2, mb: 1 }}
                                        label={<strong>Channels: {group.channels.length}</strong>}
                                        variant="outlined"
                                        size="small"
                                    />
                                </Box>
                            )}
                            action={(
                                <IconButton aria-label="settings" onClick={() => handleToggleExpand(group.teamsId)}>
                                    {expandedTeam === group.teamsId ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            )}
                        />
                        <Collapse in={expandedTeam === group.teamsId} timeout="auto" unmountOnExit>
                            <CardContent>
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Channel Name</TableCell>
                                                <TableCell component="th" scope="row" align="right">Messages</TableCell>
                                                <TableCell align="right">Total Opens</TableCell>
                                                <TableCell align="right">Average Opens per message</TableCell>
                                                <TableCell component="th" scope="row" align="right">Created</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {group.channels.map((channel) => (
                                                <TableRow key={channel.channelId}>
                                                    <TableCell>{channel.channelName}</TableCell>
                                                    <TableCell align="right">{channel.campaignsCount}</TableCell>
                                                    <TableCell align="right">{channel.totalOpens}</TableCell>
                                                    <TableCell align="right">{channel.averageOpens}</TableCell>
                                                    <TableCell align="right">{new Date(channel.created).toLocaleString()}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Collapse>
                    </Card>
                );
            })}
        </Box>
    );
};

export default TeamsSummary;
