import React, { useEffect, useState } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import ReactECharts from 'echarts-for-react';

const EmailOpensHeatmap = ({ data }) => {
    const theme = useTheme();
    const padding = theme.spacing(3);
    const chartHeight = 300;
    const [max, setMax] = useState(0);

    const hours = [
        '12am', '1am', '2am', '3am', '4am', '5am', '6am', '7am', '8am', '9am', '10am', '11am', '12pm', '1pm', '2pm', '3pm', '4pm', '5pm', '6pm', '7pm', '8pm', '9pm', '10pm', '11pm'
    ];

    const days = [
        'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
    ];

    // our day data is 1-indexed, chart is expecting 0 index
    const manipulatedData = data?.map(e => [e.hour, (e.dayOfWeek - 1), e.totalOpens || '-']) ?? [];

    // our data only contains hours where opens happened, so this rebuilds the full array
    const everyHourOfEveryDay = days.map((e, dayIndex) => {
        const hoursOfDay = hours.map((e, hourIndex) => {
            const match = manipulatedData.find(d => d[0] === hourIndex && d[1] === dayIndex);

            if (match) {
                return match;
            }

            return [hourIndex, dayIndex, '-'];
        });

        return hoursOfDay;
    });

    const chartData = everyHourOfEveryDay.flat(1);

    const options = {
        tooltip: {
            position: 'top'
        },
        grid: {
            containLabel: true,
            left: padding,
            top: padding,
            right: padding,
            bottom: padding
        },
        xAxis: {
            type: 'category',
            data: hours,
            splitArea: {
                show: true
            }
        },
        yAxis: {
            type: 'category',
            data: days,
            inverse: true,
            splitArea: {
                show: true
            }
        },
        visualMap: { //required for Heatmap chart! But can be hidden
            min: 0,
            max: max,
            //calculable: true, // drag handles
            //orient: 'horizontal',
            //left: 'center',
            //bottom: '15%',
            show: false
        },
        series: [
            {
                name: 'Opens per Hour',
                type: 'heatmap',
                data: chartData,
                label: {
                    show: true
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };

    useEffect(() => {
        if (data?.length > 0) {
            const allOpens = data.map(e => e.totalOpens);
            const maxOfOpens = Math.max(...allOpens);
            const max = isNaN(maxOfOpens) ? 0 : maxOfOpens;

            setMax(max);
        }
    }, [data]);

    return (
        <ReactECharts
            option={options}
            style={{ height: chartHeight }}
            opts={{ renderer: 'svg' }}
        />
    );
};

export default EmailOpensHeatmap;
