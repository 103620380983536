import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Typography } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Box from '@mui/material/Box';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';

const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
};

const useStyles = makeStyles((theme) => ({
    dots: {
        bottom: 15,
        '& li.slick-active button::before': {
            fontSize: theme.typography.pxToRem(10),
            color: '#fff',
            opacity: 1,
            borderRadius: '50%'
        },
        '& li': {
            '& button::before': {
                fontSize: theme.typography.pxToRem(6),
                color: '#fff',
                opacity: 0.5,
                borderRadius: '50%'
            }
        }
    }
}));

const NewsFeed = ({ hasCustomSmtpConfiguration }) => {
    const [loading, setLoading] = useState(true);
    const [feedItems, setFeedItems] = useState([]);
    const classes = useStyles();

    const settings = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 8000,
        autoplay: true,
        dotsClass: `slick-dots ${classes.dots}`
    };

    useEffect(() => {
        const fetchFeed = async () => {
            setLoading(true);
            const primaryFeedUrl = hasCustomSmtpConfiguration
                ? 'https://trusteddelivery.co.uk/tag/focus/feed/'
                : 'https://newzapp.com/tag/focus/feed/';

            const fetchData = async (url) => {
                try {
                    const response = await fetch(`https://api.rss2json.com/v1/api.json?rss_url=${encodeURIComponent(url)}`);
                    const data = await response.json();
                    return data.items || [];
                }
                catch (error) {
                    return [];
                }
            };

            let items = await fetchData(primaryFeedUrl);

            setFeedItems(items);
            setLoading(false);
        };

        fetchFeed();
    }, [hasCustomSmtpConfiguration]);

    return (
        <Paper
            elevation={3}
            sx={{
                borderRadius: 3,
                overflow: 'hidden',
                height: 499
            }}
        >
            {loading ? (
                <Skeleton variant="rectangular" height={500} width="100%" />
            ) : (
                <Slider {...settings}>
                    {feedItems.map((item, index) => (
                        <Box
                            key={index}
                            sx={{
                                position: 'relative',
                                height: 500,
                                backgroundImage: `url(${item.thumbnail})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                borderRadius: 3,
                                overflow: 'hidden'
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    width: '100%',
                                    height: '100%',
                                    background: 'linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.3))',
                                    zIndex: 1
                                }}
                            ></Box>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    zIndex: 2,
                                    color: 'white',
                                    padding: 3,
                                    textAlign: 'left',
                                    width: '100%',
                                    bottom: 0
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        sx={{
                                            fontWeight: 'bold',
                                            whiteSpace: 'normal',
                                            overflow: 'visible',
                                            color: 'inherit'
                                        }}
                                    >
                                        {stripHtml(item.title)}
                                    </Typography>
                                    <IconButton color="inherit">
                                        <OpenInNewIcon onClick={() => window.open(item.link, '_blank')} />
                                    </IconButton>
                                </Box>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: 'rgba(255, 255, 255, 0.8)',
                                        display: '-webkit-box',
                                        whiteSpace: 'normal',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        mt: 1,
                                        mb: 2
                                    }}
                                >
                                    {stripHtml(item.description)}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Slider>
            )}
        </Paper>
    );
};

export default NewsFeed;
