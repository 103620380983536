import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { format } from 'date-fns';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Unstable_Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MailIcon from '@mui/icons-material/Mail';
import WebIcon from '@mui/icons-material/Web';
import PollIcon from '@mui/icons-material/Poll';
import { subDays, endOfDay } from 'date-fns';
import buildUrl from '../buildUrl';
import useApi from '../hooks/useApi';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import Button from '@mui/material/Button';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';

const DashboardOverview = ({ accountInfo }) => {
    const history = useHistory();
    const [type, setType] = useState('email');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { handleGet } = useApi();

    const fromDate = subDays(new Date(), 30);
    const toDate = new Date();

    const handleEditItem = (id) => {
        const typeUrls = {
            email: `/editor/email/${id}`,
            page: `/editor/page/${id}`,
            survey: `/editor/surveys/${id}`,
            qrcode: `/editor/qrcode/${id}`
        };

        const url = typeUrls[type];
        history.push(url);
    };

    const handleTakeMeThere = () => {
        history.push({
            pathname: '/editor',
            state: { type }
        });
    };

    const handleGetEmails = async () => {
        const params = {
            isDescending: true,
            status: 'Draft',
            orderBy: 'Modified'
        };

        const url = buildUrl('emails', params);
        const response = await handleGet(url);

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const data = await response.json();

        setData(data);
        setIsLoading(false);
    };

    const handleGetPages = async () => {
        setIsLoading(true);
        const params = {
            fromDate: format(fromDate, 'yyyy-MM-dd'),
            toDate: format(endOfDay(toDate), 'yyyy-MM-dd HH:mm:ss'),
            take: 10,
            isDescending: true,
            published: false
        };

        const url = buildUrl('pages', params);
        const response = await handleGet(url);

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const data = await response.json();

        setData(data);
        setIsLoading(false);
    };

    const handleGetSurveys = async () => {
        setIsLoading(true);
        const surveyParams = {
            fromDate: format(fromDate, 'yyyy-MM-dd HH:mm:ss'),
            toDate: format(endOfDay(toDate), 'yyyy-MM-dd HH:mm:ss'),
            take: 10,
            isDescending: true,
            status: 'Active',
            orderBy: 'Modified'
        };

        const url = buildUrl('surveys', surveyParams);
        const response = await handleGet(url);

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const data = await response.json();

        setData(data);
        setIsLoading(false);
    };

    useEffect(() => {
        if (type === 'email') {
            handleGetEmails();
        }
        else if (type === 'page') {
            handleGetPages();
        }
        else if (type === 'survey') {
            handleGetSurveys();
        }
    }, [type]);

    return (
        <Grid container spacing={1}>
            <Grid xs={12} className="account-info">
                <Card
                    elevation={4}
                    sx={{
                        minHeight: 150,
                        maxHeight: 150,
                        borderRadius: 3,
                        px: 1,
                        mt: { xs: 1, md: 0 },
                        '&:hover': {
                            elevation: 16,
                            boxShadow: 10,
                            '.action-icon': { opacity: 1 }
                        }
                    }}
                >
                    <CardHeader
                        sx={{ pb: 0 }}
                        title={(
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{ fontWeight: 'bold', color: 'primary.main' }}
                            >
                                Licence Details
                            </Typography>
                        )}
                        action={(
                            <Tooltip title="My Account" arrow>
                                <IconButton
                                    className="action-icon"
                                    sx={{
                                        opacity: 0,
                                        transition: 'opacity 0.3s'
                                    }}
                                    onClick={() => history.push('/account')}
                                >
                                    <ArrowForwardIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    />
                    <CardContent sx={{ pt: 0 }}>
                        <Stack spacing={1}>
                            <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
                                {accountInfo.licence.productName}
                            </Typography>
                            <Box sx={{ display: 'flex' }}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{ display: 'block', color: 'text.secondary' }}
                                >
                                    Customer ID: {accountInfo.id}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    sx={{ ml: 2, display: 'block', color: 'text.secondary' }}
                                >
                                    Expiry: {accountInfo.licenceEndDate
                                        ? format(new Date(accountInfo.licenceEndDate), 'dd/MM/yyyy')
                                        : '-'}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    sx={{ ml: 2, display: 'block', color: 'text.secondary' }}
                                >
                                    Type: {accountInfo.licence.isInternalComms ? 'Internal' : 'External'}
                                </Typography>
                            </Box>
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>

            <Grid xs={12} className="jump-back-in">
                <Card
                    elevation={4}
                    sx={{
                        borderRadius: 3,
                        minHeight: 340,
                        maxHeight: 340,
                        px: 1,
                        '&:hover': {
                            elevation: 16,
                            boxShadow: 10,
                            '.action-icon': { opacity: 1 }
                        }
                    }}
                >
                    <CardHeader
                        sx={{ pb: 0 }}
                        title={(
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{ fontWeight: 'bold', color: 'primary.main' }}
                            >
                                Jump Back In
                            </Typography>
                        )}
                        action={(
                            <Tooltip title="Create & Send" arrow>
                                <IconButton
                                    className="action-icon"
                                    sx={{
                                        opacity: 0,
                                        transition: 'opacity 0.3s'
                                    }}
                                    onClick={() => history.push('/editor')}
                                >
                                    <ArrowForwardIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    />
                    <CardContent sx={{ pt: 0 }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={type}
                                onChange={(e, value) => setType(value)}
                                variant="fullWidth"
                                sx={{ padding: 0 }}
                            >
                                <Tab label="Email" value="email" />
                                <Tab label="Page" value="page" />
                                <Tab label="Survey" value="survey" />
                            </Tabs>
                        </Box>
                        {isLoading ? (
                            <LoadingOverlay />
                        ) : data.length === 0 ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    minHeight: 200,
                                    textAlign: 'center',
                                    color: 'text.secondary'
                                }}
                            >
                                <Typography>
                                    There are no recent {type === 'survey' ? '' : 'draft '} {type}s to show
                                </Typography>
                                <Button onClick={handleTakeMeThere} sx={{ mt: 1 }} variant="contained" endIcon={<FlightTakeoffIcon />}>Take Me There</Button>
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    height: 220,
                                    overflow: 'auto',
                                    '&::-webkit-scrollbar': {
                                        width: '6px'
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                        borderRadius: '3px'
                                    }

                                }}>
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell align="right">Last Modified</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                    onClick={() => handleEditItem(row.id)}
                                                    hover={true}
                                                >
                                                    <TableCell>
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            {type === 'email' && <MailIcon sx={{ mr: 1, color: 'text.secondary' }} />}
                                                            {type === 'page' && <WebIcon sx={{ mr: 1, color: 'text.secondary' }} />}
                                                            {type === 'survey' && <PollIcon sx={{ mr: 1, color: 'text.secondary' }} />}
                                                            {row.name}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align="right" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                        {format(new Date(row.modified), 'dd/MM/yyyy HH:mm')}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default DashboardOverview;
