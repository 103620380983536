/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { Tab, Tabs, Box, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, ImageList, ImageListItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import config from '../../config';
import axios from 'axios';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import { useDropzone } from 'react-dropzone';
import useSnackbar from '../../hooks/useSnackbar';
import Thumbnail from './Thumbnail';
import useApi from '../../hooks/useApi';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';

const { PIXABAY_API_KEY } = config;

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const dropzoneStyle = {
    border: '2px dashed #cccccc',
    borderRadius: '8px',
    transition: 'border 0.3s ease',
    width: '100%'
};

const activeDropzoneStyle = {
    border: '2px dashed #57a5f9'
};

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
};

const TeamsImagePicker = ({ handleImageSelect }) => {
    const { handleGet, handleFetch } = useApi();
    const [tabValue, setTabValue] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [onlineImages, setOnlineImages] = useState([]);
    const [customImages, setCustomImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [files, setFiles] = useState([]);
    const [isImageUploading, setIsImageUploading] = useState(false);
    const { showSnackbar } = useSnackbar();
    const maxSize = 20 * 1024 * 1024;
    const [progress, setProgress] = useState(0);

    const onDrop = async (acceptedFiles, fileRejections) => {
        setIsLoading(true);

        const invalidFiles = acceptedFiles.filter(
            (file) => !file.type.startsWith('image/') || file.size > maxSize
        );

        if (invalidFiles.length > 0) {
            showSnackbar('Invalid File or size greater than 20mb', 'error');
        }
        else if (fileRejections.length > 0) {
            showSnackbar('File type not supported', 'error');
        }
        else {
            setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
        }

        setIsLoading(false);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: ['image/jpeg', 'image/jpg', 'image/png'],
        onDrop
    });

    const removeImage = (index, e) => {
        e.stopPropagation();
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    };

    const thumbs = files.map((file, index) => (
        <Thumbnail
            file={file}
            key={file.name}
            removeImage={removeImage}
            index={index}
            isImageUploading={isImageUploading}
            progress={progress}
        />
    ));

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleGetCustomImages = async () => {
        const response = await handleGet('files/images');

        if (!response.ok) {
            showSnackbar('Error loading custom images', 'error');
            setIsLoading(false);
            return;
        }

        const data = await response.json();
        setCustomImages(data);
        setIsLoading(false);
    };

    const handleCustomImageUpload = async () => {
        setIsImageUploading(true);

        let results = [];
        let totalSize = 0;

        files.forEach(file => {
            totalSize += file.size;
        });

        let uploadSize = 0;

        for (let i = 0; i < files.length; i++) {
            const data = new FormData();

            data.append('file', files[i]);

            const response = await handleFetch('files/images', {
                method: 'post',
                body: data
            });

            const result = await response.json();

            if (!response.ok) {
                showSnackbar(response.result, 'error');
                setIsImageUploading(false);
                return;
            }

            results.push(result);

            uploadSize += files[i].size;
            const progressData = Math.round((uploadSize / totalSize) * 100);
            setProgress(progressData);
        }

        setIsLoading(false);
        setFiles([]);

        showSnackbar('Images uploaded successfully', 'success');
        setIsImageUploading(false);
        await handleGetCustomImages();
    };

    const fetchOnlineImages = async (query = 'nature') => {
        try {
            const response = await axios.get('https://pixabay.com/api/', {
                params: {
                    key: PIXABAY_API_KEY,
                    q: query,
                    per_page: 21,
                    image_type: 'photo',
                    orientation: 'horizontal',
                    safesearch: 'true'
                }
            });
            setOnlineImages(response.data.hits);
        }
        catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await fetchOnlineImages(searchQuery);
    };

    useEffect(() => {
        if (tabValue === 0) {
            handleGetCustomImages();
        }
    }, [tabValue]);

    useEffect(() => {
        if (tabValue === 1 && onlineImages.length === 0) {
            fetchOnlineImages('nature');
        }
    }, [tabValue]);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label="Custom Image" />
                    <Tab label="Search Online" />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                {files && files.length > 0 && (
                    <Box sx={{ mb: 1, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" onClick={handleCustomImageUpload}>Upload</Button>
                    </Box>
                )}
                <FormGroup>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                        <Box sx={{ ...dropzoneStyle, ...(isDragActive ? activeDropzoneStyle : {}) }} {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps({ multiple: true })} />
                            <Box sx={{ textAlign: 'center', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1 }}>
                                <Typography>
                                    {isDragActive
                                        ? 'Drop files here'
                                        : (files.length > 0
                                            ? ''
                                            : (
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3 }}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <CloudUploadIcon size="small" sx={{ marginRight: 1 }} />
                                                        <Typography component="p" variant="subtitle1">Drop images, or click to browse</Typography>
                                                    </Box>
                                                    <Typography component="p" variant="subtitle1">PNG, JPG, JPEG, GIF, JFIF. Not more than 20 MB</Typography>
                                                </Box>
                                            )
                                        )
                                    }
                                </Typography>
                            </Box>
                            <aside style={thumbsContainer}>
                                {thumbs}
                            </aside>
                        </Box>
                    </Box>
                </FormGroup>
                <Box>
                    <ImageList sx={{ width: '100%', height: 370 }} cols={4} rowHeight={164} gap={5}>
                        {customImages.length > 0 && customImages.map((image) => (
                            <ImageListItem key={image.name}>
                                <img
                                    srcSet={`${image.link}?w=100&h=164&fit=crop&auto=format&dpr=2 2x`}
                                    src={`${image.link}?w=164&h=164&fit=crop&auto=format`}
                                    alt={image.name}
                                    loading="lazy"
                                    onClick={() => handleImageSelect(image.link)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <form onSubmit={handleSubmit}>
                    <FormControl sx={{ width: '100%', mt: 0 }} variant="outlined" size="small">
                        <InputLabel>Search</InputLabel>
                        <OutlinedInput
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            endAdornment={(
                                <InputAdornment position="end">
                                    <IconButton edge="end" onClick={handleSubmit}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            )}
                            label="Search"
                        />
                    </FormControl>
                </form>
                <ImageList sx={{ width: '100%', height: 450 }} cols={3} rowHeight={164} gap={5}>
                    {onlineImages.map((image) => (
                        <ImageListItem key={image.id}>
                            <img
                                srcSet={`${image.largeImageURL}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                src={`${image.largeImageURL}?w=164&h=164&fit=crop&auto=format`}
                                alt={image.user}
                                loading="lazy"
                                onClick={() => handleImageSelect(image.previewURL)}
                                style={{ cursor: 'pointer' }}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </TabPanel>
        </>
    );
};

export default TeamsImagePicker;
