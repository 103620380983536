import React, { useEffect, useReducer, useState } from 'react';
import { format } from 'date-fns';
// Material UI
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import enLocale from 'date-fns/locale/en-GB';
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

// Components
import LoadingOverlay from '../../../loadingOverlay/LoadingOverlay';
import useApi from '../../../hooks/useApi';
import useAccount from '../../../hooks/useAccount';
import useSnackbar from '../../../hooks/useSnackbar';

const rowsPerPageOptions = [5, 10, 20];

const Picker = ({ value, disableEdit, onSubmit }) => {
    const [selectedDate, setSelectedDate] = useState(value);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
            <MobileDatePicker
                mask="__/__/____"
                format="dd/MM/yyyy"
                disabled={disableEdit}
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                onAccept={onSubmit}
                slotProps={{
                    textField: {
                        size: 'small',
                        margin: 'none'
                    }
                }}
            />
        </LocalizationProvider>
    );
};

const reducer = (prevState, newState) => {
    let {
        isInitialising,
        isLoading,
        products,
        filteredProducts,
        unfilteredProducts,
        count,
        searchValue,
        orderBy,
        orderDesc,
        page,
        rowsPerPage
    } = { ...prevState, ...newState };

    if (searchValue) {
        filteredProducts = unfilteredProducts.filter(e => e.name.toLowerCase().includes(searchValue.toLowerCase()));
    }
    else {
        filteredProducts = unfilteredProducts;
    }

    filteredProducts = filteredProducts.sort((a, b) => {
        if (orderBy === 'Product') {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            return orderDesc ? nameB.localeCompare(nameA) : nameA.localeCompare(nameB);
        }

        if (orderBy === 'Status') {
            return orderDesc ? b.isExpired - a.isExpired : a.isExpired - b.isExpired;
        }

        if (orderBy === 'StartDate') {
            const dateA = new Date(a.startDate);
            const dateB = new Date(b.startDate);
            return orderDesc ? dateB.getTime() - dateA.getTime() : dateA.getTime() - dateB.getTime();
        }

        if (orderBy === 'RenewalDate') {
            const dateA = new Date(a.renewalDate);
            const dateB = new Date(b.renewalDate);
            return orderDesc ? dateB.getTime() - dateA.getTime() : dateA.getTime() - dateB.getTime();
        }

        return 0;
    });

    count = filteredProducts.length;

    if (orderBy !== prevState.orderBy || orderDesc !== prevState.orderDesc || rowsPerPage !== prevState.rowsPerPage || searchValue !== prevState.searchValue) {
        page = 0;
    }

    products = filteredProducts.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

    if (products) {
        isInitialising = false;
    }

    return {
        orderBy,
        orderDesc,
        page,
        isInitialising,
        isLoading,
        searchValue,
        rowsPerPage,
        count,
        products,
        unfilteredProducts,
        filteredProducts
    };
};

const initialState = {
    orderBy: 'StartDate',
    orderDesc: true,
    page: 0,
    isInitialising: true,
    isLoading: false,
    searchValue: '',
    rowsPerPage: rowsPerPageOptions[0],
    count: 0,
    products: null,
    unfilteredProducts: null,
    filteredProducts: null
};

const cellStyles = {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
};

const ProductsList = ({ title, products, customerId, onRefresh, onAddPack }) => {
    const { userPermissions } = useAccount();
    const canManageProduct = userPermissions.includes('manage:products');
    const [disableEdit, setDisableEdit] = useState(true);

    const ProductsTable = () => {
        const { handlePut } = useApi();
        const [state, setState] = useReducer(reducer, initialState);
        const [loading, setLoading] = useState(false);
        const { showSnackbar } = useSnackbar();

        const handleSort = async (orderBy, orderDesc) => {
            if (orderBy === state.orderBy) {
                setState({ orderDesc: !state.orderDesc });
            }
            else {
                setState({ orderBy, orderDesc });
            }
        };

        const handleChangeStartDate = async (productId, startDate) => {
            setLoading(true);

            const response = await handlePut(`superadmin/accounts/${customerId}/products/${productId}`, { startDate });

            if (!response.ok) {
                showSnackbar('Could not update product start date', 'error');
            }
            else {
                showSnackbar('Product start date updated successfully', 'success');
            }

            setDisableEdit(true);

            onRefresh();
        };

        const onButtonClick = async (productId, date) => {
            setLoading(true);

            const renewalDate = new Date(date);

            renewalDate.setMonth(renewalDate.getMonth() + 1);

            await handlePut(`superadmin/accounts/${customerId}/products/${productId}`, { renewalDate });

            setLoading(false);

            onRefresh();
        };

        const onWeekButtonClick = async (productId, date) => {
            setLoading(true);

            const renewalDate = new Date(date);
            renewalDate.setDate(renewalDate.getDate() + 7);

            await handlePut(`superadmin/accounts/${customerId}/products/${productId}`, { renewalDate });
            onRefresh();
        };

        useEffect(() => {
            setState({ unfilteredProducts: products });
        }, []);

        if (state.isInitialising) {
            return (
                <LoadingOverlay />
            );
        }

        if (products && products.length > 0) {
            return (
                <Box>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell component="th" sx={cellStyles}>ID</TableCell>
                                    <TableCell component="th" sx={cellStyles}>
                                        <TableSortLabel
                                            active={state.orderBy === 'Product'}
                                            direction={state.orderDesc ? 'desc' : 'asc'}
                                            onClick={() => handleSort('Product', false)}
                                        >
                                            Product
                                        </TableSortLabel>
                                    </TableCell>
                                    {title === 'Licences' && (
                                        <TableCell component="th" sx={cellStyles}>
                                            <TableSortLabel
                                                active={state.orderBy === 'Status'}
                                                direction={state.orderDesc ? 'desc' : 'asc'}
                                                onClick={() => handleSort('Status', false)}
                                            >
                                                Status
                                            </TableSortLabel>
                                        </TableCell>
                                    )}
                                    <TableCell component="th" sx={cellStyles}>
                                        <TableSortLabel
                                            active={state.orderBy === 'StartDate'}
                                            direction={state.orderDesc ? 'desc' : 'asc'}
                                            onClick={() => handleSort('StartDate', false)}
                                        >
                                            Start Date
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell component="th" sx={cellStyles}>
                                        {title === 'Licences' && (
                                            <TableSortLabel
                                                active={state.orderBy === 'RenewalDate'}
                                                direction={state.orderDesc ? 'desc' : 'asc'}
                                                onClick={() => handleSort('RenewalDate', false)}
                                            >
                                                Renewal Date
                                            </TableSortLabel>
                                        )}
                                    </TableCell>
                                    <TableCell component="th" sx={cellStyles} align="right">Usage Limit</TableCell>
                                    <TableCell component="th" sx={cellStyles} align="right">Used</TableCell>
                                    <TableCell component="th" sx={cellStyles} align="right">Invoice Number</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center">
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    state.products.map(p => {
                                        const expired = p.renewalDate && (new Date(p.renewalDate) < new Date());

                                        return (
                                            <TableRow key={p.id}>
                                                <TableCell sx={cellStyles}>{p.productId}</TableCell>
                                                <TableCell sx={cellStyles}>{p.name}</TableCell>
                                                {title === 'Licences' && (
                                                    <TableCell sx={cellStyles}>{expired ? 'Expired' : 'Active'}</TableCell>
                                                )}
                                                {disableEdit ? (
                                                    <TableCell sx={cellStyles}>
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <span>{p.startDate ? format((new Date(p.startDate)), 'dd/MM/yyyy') : '-'}</span>
                                                        </div>
                                                    </TableCell>
                                                ) : (<TableCell sx={cellStyles}><Picker value={p.startDate} disableEdit={disableEdit} onSubmit={selectedDate => handleChangeStartDate(p.id, selectedDate)} /></TableCell>)}

                                                <TableCell sx={cellStyles}>
                                                    {title === 'Licences' && (
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            {p.renewalDate ? (
                                                                <span>{p.renewalDate ? format((new Date(p.renewalDate)), 'dd/MM/yyyy') : '-'}</span>
                                                            ) : (
                                                                <span>-</span>
                                                            )}
                                                            {
                                                                title === 'Licences' && p.productId === 14 ? (
                                                                    canManageProduct && (
                                                                        <Button variant="outlined" onClick={() => onButtonClick(p.id, p.renewalDate)}>
                                                                            +1 Month
                                                                        </Button>
                                                                    )
                                                                ) : title === 'Licences' ? (
                                                                    canManageProduct && (
                                                                        <Button variant="outlined" onClick={() => onWeekButtonClick(p.id, p.renewalDate)}>
                                                                            +1 Week
                                                                        </Button>
                                                                    )
                                                                ) : null
                                                            }
                                                        </div>
                                                    )}
                                                </TableCell>
                                                <TableCell sx={cellStyles} align="right">{p.usageLimit}</TableCell>
                                                <TableCell sx={cellStyles} align="right">{p.used}</TableCell>
                                                <TableCell sx={cellStyles} align="right">{p.invoiceNumber || '-'}</TableCell>
                                            </TableRow>
                                        );
                                    })
                                )}
                            </TableBody>

                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={state.count}
                        rowsPerPage={state.rowsPerPage}
                        page={state.page}
                        onPageChange={(e, newPage) => {
                            if (!loading) {
                                setState({ page: newPage });
                            }
                        }}
                        onRowsPerPageChange={e => {
                            if (!loading) {
                                setState({ rowsPerPage: e.target.value });
                            }
                        }}
                    />
                </Box>
            );
        }

        return (
            <Box sx={{ p: 2 }}>
                <Typography variant="body2">This account has no products.</Typography>
            </Box>
        );
    };

    return (
        <>
            <Toolbar
                variant="dense"
                disableGutters
                sx={{ px: 2, display: 'flex' }}
            >
                <Typography variant="subtitle2" sx={{ justifyContent: 'flex-start' }}>
                    Products - {title}
                </Typography>
                <div style={{ flexGrow: 1 }}></div>
                {canManageProduct && (
                    <Button
                        onClick={() => setDisableEdit(!disableEdit)}
                        size="small"
                        startIcon={disableEdit ? <EditOutlinedIcon /> : <EditOffOutlinedIcon />}
                        color="warning"
                    >
                        Allow Edit
                    </Button>
                )}
                {title === 'Packs' && (
                    canManageProduct && (
                        <Button variant="contained" onClick={onAddPack} color="primary" size="small" sx={{ justifyContent: 'flex-end', marginLeft: 2 }}>
                            Add Packs
                        </Button>
                    )
                )}
            </Toolbar>

            <Divider />
            <ProductsTable />
        </>
    );
};

export default ProductsList;
