import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { makeStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';

const StyledSlider = styled(Slider)`
  .slick-slide {
    padding: 0 5px;
  }
`;

const useStyles = makeStyles((theme) => ({
    dots: {
        bottom: -25,
        '& li.slick-active button::before': {
            fontSize: theme.typography.pxToRem(10),
            color: '#000',
            opacity: 1,
            borderRadius: '50%'
        },
        '& li': {
            '& button::before': {
                fontSize: theme.typography.pxToRem(6),
                color: '#000',
                opacity: 0.5,
                borderRadius: '50%'
            }
        }
    }
}));

const YouTubeCarousel = React.memo(() => {
    const [videos, setVideos] = useState([]);
    const [isSliding, setIsSliding] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        const fetchFeed = async (feedUrl) => {
            try {
                const response = await fetch(`https://api.rss2json.com/v1/api.json?rss_url=${encodeURIComponent(feedUrl)}`);
                const data = await response.json();

                if (data && data.items) {
                    const paresedVideos = data.items.map((item) => ({
                        title: item.title,
                        link: item.link,
                        thumbnail: item.thumbnail
                    }));
                    setVideos(paresedVideos);
                }
                else {
                    setVideos([]);
                }
            }
            catch (error) {
                console.error('Error fetching RSS feed:', error);
                setVideos([]);
            }
        };

        fetchFeed('https://www.youtube.com/feeds/videos.xml?playlist_id=PLHN7sO5bejWuSs7JMHeBpxgmXQWU5GvuK');
    }, []);

    const settings = {
        arrows: false,
        dots: true,
        infinite: true,
        lazyLoad: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        dotsClass: `slick-dots ${classes.dots}`,
        beforeChange: () => setIsSliding(true),
        afterChange: () => setIsSliding(false),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const handleThumbnailClick = (videoLink) => {
        if (!isSliding) {
            window.open(videoLink, '_blank', 'noopener,noreferrer');
        }
    };

    return (
        <Card
            className="youtube-carousel"
            elevation={4}
            sx={{
                minHeight: 245,
                maxHeight: 245,
                borderRadius: 3,
                pt: 0,
                pb: 2,
                mt: { xs: 0, xl: 1 },
                '&:hover': {
                    elevation: 16,
                    boxShadow: 10,
                    '.action-icon': { opacity: 1 }
                }
            }}
        >
            <CardHeader
                sx={{
                    pb: 0,
                    px: 3
                }}
                title={(
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            color: 'primary.main'
                        }}
                    >
                        Training Videos
                    </Typography>
                )}
                action={(
                    <Tooltip title="Training Videos" arrow>
                        <IconButton
                            className="action-icon"
                            sx={{
                                opacity: 0,
                                transition: 'opacity 0.3s'
                            }}
                            onClick={() => window.open('https://www.youtube.com/playlist?list=PLHN7sO5bejWuSs7JMHeBpxgmXQWU5GvuK', '_blank')}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                    </Tooltip>
                )}
            />
            <CardContent sx={{ pt: 0 }}>
                <StyledSlider {...settings}>
                    {videos.map((video, index) => (
                        <Box
                            key={index}
                            sx={{
                                cursor: 'pointer',
                                position: 'relative',
                                textAlign: 'center',
                                borderRadius: 4,
                                overflow: 'hidden',
                                width: '100%'
                            }}
                            onClick={() => handleThumbnailClick(video.link)}
                        >
                            <img
                                src={video.thumbnail}
                                alt={video.title}
                                style={{
                                    width: '100%',
                                    height: '150px',
                                    objectFit: 'cover',
                                    borderRadius: 'inherit'
                                }}
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    width: '100%',
                                    height: '100%',
                                    background: 'linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.9))',
                                    borderRadius: 'inherit'
                                }}
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    zIndex: 2,
                                    color: 'white',
                                    px: 2,
                                    pb: 2,
                                    textAlign: 'left',
                                    width: '100%',
                                    bottom: 0
                                }}
                            >
                                {video.title}
                            </Box>
                        </Box>
                    ))}
                </StyledSlider>
            </CardContent>
        </Card>
    );
});

YouTubeCarousel.displayName = 'YouTubeCarousel';

export default YouTubeCarousel;