import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import enLocale from 'date-fns/locale/en-GB';

const DateTimeSelector = ({ label, value, setValue, helperText, disabled = false, disablePast = false, minDateTime = null, disableError = false, fullWidth = true }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
            <DateTimePicker
                mask="__/__/____ __:__"
                format="dd/MM/yyyy HH:mm"
                label={label}
                value={value}
                onChange={newValue => setValue(newValue)}
                disablePast={disablePast}
                minDateTime={minDateTime}
                disabled={disabled}
                slotProps={{
                    textField: {
                        size: 'small',
                        margin: 'none',
                        fullWidth,
                        helperText,
                        error: disableError ? false : undefined
                    }
                }}
            />
        </LocalizationProvider>
    );
};

export default DateTimeSelector;