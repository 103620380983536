import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import useTheme from '@mui/material/styles/useTheme';
import Button from '@mui/material/Button';

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
    overflow: 'hidden',
    borderRadius: 2,
    objectFit: 'contain'
};

const thumb = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    mx: 0.5,
    mb: 2,
    width: 180,
    height: 130,
    padding: 1,
    boxSizing: 'border-box'
};

const Thumbnail = ({ file, removeImage, index, isImageUploading, progress }) => {
    const [blobURL, setBlobURL] = useState('');
    const [hovered, setHovered] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        const blobUrl = URL.createObjectURL(file);
        setBlobURL(blobUrl);

        return () => {
            URL.revokeObjectURL(blobUrl);
        };
    }, [file]);

    return (
        <Box sx={thumb} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <img src={blobURL} style={img} />
            <Backdrop
                sx={{ zIndex: 1, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: hovered ? 'block' : 'none' }}
                open={hovered}
            >
                <Button color="error" onClick={(e) => removeImage(index, e)} sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} variant="contained" startIcon={<DeleteIcon />}>
                    Delete
                </Button>
            </Backdrop>
            {isImageUploading && (
                <Backdrop sx={{ zIndex: theme.zIndex.drawer + 2, color: '#fff', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} open={true}>
                    <CircularProgress value={progress} />
                </Backdrop>
            )}
        </Box>
    );
};

export default Thumbnail;