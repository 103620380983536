import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const RenameItemDialog = ({ item, onSubmit, onClose }) => {
    const [name, setName] = useState(item.name);

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Rename {item.isFolder ? 'Folder' : 'Campaign'}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    value={name}
                    required
                    onChange={e => setName(e.target.value)}
                    margin="dense"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onSubmit(name)}>Rename</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default RenameItemDialog;