import React, { useEffect, useRef } from 'react';
import * as AdaptiveCards from 'adaptivecards';

const AdaptiveCardPreview = ({ card }) => {
    const cardContainerRef = useRef(null);

    useEffect(() => {
        if (card) {
            const adaptiveCard = new AdaptiveCards.AdaptiveCard();
            adaptiveCard.parse(card);
            const renderedCard = adaptiveCard.render();
            cardContainerRef.current.innerHTML = '';
            cardContainerRef.current.appendChild(renderedCard);
        }
    }, [card]);

    return <div ref={cardContainerRef} />;
};

export default AdaptiveCardPreview;