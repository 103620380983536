// todo get config from appsettings.json using a server call or use variable substitution

let audience;

if (window.location.host.endsWith('my.newzapp.co.uk')) {
    audience = 'https://my.newzapp.co.uk/';
}
else if (window.location.host.endsWith('next.newzapp.co.uk')) {
    audience = 'https://next.newzapp.co.uk/';
}
else {
    audience = 'https://dev.nz-dev.co.uk/';
}

const config = {
    pesdkLicence: '{"api_token":"mWwoBWI8pVgwbi5jej6aYw","app_identifiers":["*.newzapp.co.uk","*.destinet.co.uk","localhost"],"available_actions":[],"domains":["https://api.photoeditorsdk.com"],"enterprise_license":false,"expires_at":null,"features":["camera","library","export","customassets","whitelabel","adjustment","filter","transform"],"issued_at":1632819770,"minimum_sdk_version":"1.0","owner":"DestiNet Ltd","platform":"HTML5","products":["pesdk"],"version":"2.4","signature":"eJHp4bLYzHpMWK+fc59lhiriQUQC5p3KklEzYFUkNQymvrJKUScRjryJXXsJ2At1byOJOK3e3PwBpJH0LcjUdb1UkFvWDbaokPpU2AUKU7nVaW7VTsi/WHNCeZVc353nl7nc9uAJ7/OXGng+Va3G11vVg1VnAoNl8tcK/ykXjQqS8nhhAmvejO3PmXC00uxe9u+svrl7jxk7gBPfYKXHFVOzwkC9MLOEL3BYOiWGN/5N57xdZc0ThxAxpN1RhhV4E2z3W+UoroUlz8azJvu57GTe7s0lT4NaLuO9Ao8z/AQBiYwxYQ2pdM3l0OH8pyhef2IeEb+nJ//wB3MJaQCJkCVUNN1Fe1kPRboPyOrw469PmSo9wdQQio2h7GrqshEV+Sl6pi4D/qd90uI2bnOzVhUZOlqQh9q4vHvewVIfqD9SNclyipwx1nK9EuzLCDeRRUFrCni/+NFl4OLzdbMawM0JBsAlepMVGOtpMkBs+4xKw4GHjhpU4WyTq8U4IMGlD0VnqaQkvoKLlt4Qsg+g4cWRQgWuGHMjiPvM2xJvciIBLlEqZdbua2mx5ETkDvl21elOFChL0vtsBb6G/TnDvqzpJrD4AY+MjH3TKgbovwkWuMwe16187Cn8jyU2wli/sI6Dh7vmmG6SzVHehtug4M9RIPb+D5oQnfA4rUBoGUs="}',
    googleAnalyticsClientId: '381869180852-i5mt9tnu51en1ksp674r1egp8sm68mk3.apps.googleusercontent.com',
    googleAnalyticsScopes: ['https://www.googleapis.com/auth/analytics.readonly'],
    //AUTH_AUDIENCE: process.env.AUTH_AUDIENCE,
    //AUTH_SCOPE: process.env.AUTH_SCOPE
    AUTH0_DOMAIN: 'login.newzapp.co.uk',
    AUTH0_CLIENT_ID: '3sBZKs8aBo25WyPvRtzoBaiuohrQtuun',
    AUTH0_AUDIENCE: audience,
    AUTH0_SCOPE: 'openid profile email',
    SURVEY_API_KEY: 'MjdmMjE3NGQtZTBiZS00YzNmLWI3YzctMWFiY2JkYTgwNzY0OzE9MjAyNS0wMy0xNSwyPTIwMjUtMDMtMTUsND0yMDI1LTAzLTE1',
    PIXABAY_API_KEY: '26589249-f55fff9f32bbb19737ace7ba0'
};

export default config;