import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CircleIcon from '@mui/icons-material/Circle';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ListItemText from '@mui/material/ListItemText';
import EditTopicDialog from '../account/topics/dialogs/EditTopicDialog';
import ListItemIcon from '@mui/material/ListItemIcon';
import Topic from '../topics/Topic';
import Tag from '../tags/Tag';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ItemsMenu = ({ isTags, items, availableItems, onSetItems, setOpenTopicsDialog, type, onClickItem, handleClick }) => {
    const [open, setOpen] = useState(false);

    const handleAddTopicClick = e => {
        e.preventDefault();
        e.stopPropagation();
        setOpenTopicsDialog(true);
    };

    const handleChangeItems = event => {
        const selectedIds = event.target.value;
        const selectedOptions = availableItems.filter((item) => selectedIds.includes(item.id));
        onSetItems(selectedOptions);
    };

    const handleChipClick = (event, item) => {
        setOpen(false);
        event.preventDefault();
        event.stopPropagation();

        if (onClickItem) {
            handleClick(event, item);
        }
    };

    const renderValue = (selected) => {
        const validSelected = selected.filter(item => availableItems.some(availableItem => availableItem.id === item));

        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {validSelected.length > 0 ? validSelected.map((item, index) => {
                    const availableItem = availableItems.find(obj => obj.id === item);
                    return isTags ? (
                        <Tag
                            key={index}
                            name={availableItem?.name}
                            colour={availableItem?.colour}
                            onClick={onClickItem ? event => handleChipClick(event, item) : undefined}
                        />
                    ) : (
                        <Topic
                            key={index}
                            name={availableItem?.name}
                            colour={availableItem?.colour}
                            onClick={onClickItem ? event => handleChipClick(event, item) : undefined}
                        />
                    );
                }) : (
                    <Typography variant="body2" sx={{ color: 'text.disabled' }}>No {type} Selected</Typography>
                )}
            </Box>
        );
    };

    return (
        <Select
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            multiple
            value={items.map((item) => item.id)}
            onChange={handleChangeItems}
            renderValue={renderValue}
            displayEmpty
            sx={{
                width: '100%',
                padding: 0,
                ...(type === 'Topics' && {
                    border: 'none',
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                    }
                }),
                '& .MuiSelect-select': {
                    paddingTop: 1,
                    paddingBottom: 1
                }
            }}
        >
            {type === 'Topics' && (
                <>
                    <MenuItem
                        onClick={handleAddTopicClick}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            padding: 1
                        }}
                    >
                        <ListItemIcon>
                            <AddCircleIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Add Topic</ListItemText>
                    </MenuItem>
                    <Divider />
                </>
            )}
            {availableItems.length > 0 && (
                <Box>
                    <Box
                        sx={{
                            maxHeight: '300px',
                            overflowY: 'auto',
                            '&::-webkit-scrollbar': {
                                width: '6px'
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                borderRadius: '3px'
                            }
                        }}
                    >
                        {availableItems.length > 0 && availableItems.map((option) => (
                            <RenderOption
                                key={option.id}
                                option={option}
                                items={items}
                                onSetItems={onSetItems}
                            />
                        ))}
                    </Box>
                </Box>
            )}
        </Select>
    );
};

const RenderOption = ({ option, items, onSetItems }) => {
    const selected = Boolean(items.find(e => e.id === option.id));

    const handleClickCheckbox = (event, option) => {
        event.stopPropagation();

        if (selected) {
            onSetItems(items.filter(e => e.id !== option.id));
        }
        else {
            const newItems = [...items, option];

            const orderedItems = newItems.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();

                if (nameA < nameB) {
                    return -1;
                }

                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            });

            onSetItems(orderedItems);
        }
    };

    return (
        <MenuItem
            onClick={event => handleClickCheckbox(event, option)}
            sx={{ padding: 0, paddingRight: 1 }}
        >
            <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                sx={{ marginRight: 1 }}
                checked={selected}
            />
            <CircleIcon fontSize="small" sx={{ color: option.colour ?? 'rgba(0, 0, 0, 0.08)', marginRight: 1 }} />
            <Typography variant="inherit" noWrap>
                {option.name}
            </Typography>
        </MenuItem>
    );
};

const NoItemsAvailable = ({ type }) => {
    return (
        <Typography variant="body2" sx={{ color: 'text.disabled' }}>No {type} Available</Typography>
    );
};

const Picker = ({ availableItems, selectedItems, onChangeItems, onCreateTopic, isTags = false, isBeingUsed = false, onClickItem = null }) => {
    const [items, setItems] = useState(selectedItems);
    const [openTopicsDialog, setOpenTopicsDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event, item) => {
        event.stopPropagation();
        onClickItem(items.find(obj => obj.id === item));
    };

    const handleChangeItems = items => {
        setItems(items);
        onChangeItems(items);
    };

    const handleAddTopic = ({ name, description, colour, id = null }) => {
        onCreateTopic({ name, description, colour, ...(id && { id }) });
        setOpenTopicsDialog(false);
    };

    const type = isTags ? 'Tags' : 'Topics';

    useEffect(() => {
        selectedItems?.length > 0 && setItems(selectedItems);
    }, [selectedItems, availableItems]);

    if (availableItems.length === 0) {
        return (
            <NoItemsAvailable type={type} />
        );
    }

    return (
        <>
            <ItemsMenu
                isTags={isTags}
                items={items}
                availableItems={availableItems}
                onSetItems={handleChangeItems}
                setOpenTopicsDialog={setOpenTopicsDialog}
                anchorEl={anchorEl}
                isBeingUsed={isBeingUsed}
                type={type}
                setAnchorEl={setAnchorEl}
                onClickItem={onClickItem}
                handleClick={handleClick}
            />
            {
                openTopicsDialog && (
                    <EditTopicDialog
                        onClose={() => setOpenTopicsDialog(false)}
                        onSubmit={handleAddTopic}
                    />
                )
            }
        </>
    );
};

export default Picker;
