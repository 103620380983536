import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { subDays, endOfDay } from 'date-fns';
import { format } from 'date-fns';
import useApi from '../hooks/useApi';
import buildUrl from '../buildUrl';
import ReactECharts from 'echarts-for-react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import Box from '@mui/material/Box';
import { chartColours } from '../theme/constants';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CardHeader from '@mui/material/CardHeader';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';

const chartHeaders = [
    'Emails Overview',
    'Opens and Clicks Overview',
    'Subject Line Length vs Open Rate',
    'Send Time Analysis'
];

const useStyles = makeStyles((theme) => ({
    dots: {
        bottom: 10,
        '& li.slick-active button::before': {
            fontSize: theme.typography.pxToRem(10),
            color: '#000',
            opacity: 1,
            borderRadius: '50%'
        },
        '& li': {
            '& button::before': {
                fontSize: theme.typography.pxToRem(6),
                color: '#000',
                opacity: 0.5,
                borderRadius: '50%'
            }
        }
    }
}));

const StyledTooltip = (props) => (
    <Tooltip
        arrow
        {...props}
        slotProps={{
            popper: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -8]
                        }
                    }
                ]
            }
        }}
    />
);

const ContentGraph = () => {
    const { handleGet } = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [summary, setSummary] = useState(null);
    const [currentChartIndex, setCurrentChartIndex] = useState(0);
    const [days, setDays] = React.useState('30');
    const toDate = new Date();
    const classes = useStyles();
    const [showGraph, setShowGraph] = useState(false);

    const handleFetchSummary = async () => {
        setIsLoading(true);
        const fromDate = subDays(new Date(), Number(days));

        const params = {
            fromDate: format(fromDate, 'yyyy-MM-dd'),
            toDate: format(endOfDay(toDate), 'yyyy-MM-dd HH:mm:ss')
        };

        const url = buildUrl('reports/emails/summary', params);
        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        const data = await response.json();

        setSummary(data);
        setIsLoading(false);
        setCurrentChartIndex(0);
    };

    const handleChangeDays = (event, newDays) => {
        if (newDays) {
            setDays(newDays);
        }
    };

    const handleClick = () => {
        setShowGraph(true);
        handleFetchSummary();
    };

    const subjectData =
        summary?.subjectLineLengthByOpenRate?.map((item) => ({
            name: item.subject,
            value: [item.subjectLength, item.openRate]
        })) || [];

    const sendTimeData = [
        { time: summary?.mostFrequentSendTime, label: 'Most Frequent' },
        { time: summary?.bestSendTimeForOpens, label: 'Best for Opens' },
        { time: summary?.bestSendTimeForClicks, label: 'Best for Clicks' }
    ];

    const chartOptions = [
        {
            tooltip: {
                trigger: 'axis',
                formatter: function (params) {
                    return params.map(param => {
                        if (param.name === 'Avg Send Frequency') {
                            return `${param.marker} ${param.name}: ${param.value}<br/><br/>Total Campaigns sent / Number of weeks in given time period.`;
                        }

                        return `${param.marker} ${param.name}: ${param.value}`;
                    }).join('<br/>');
                },
                extraCssText: 'width: 250px; white-space: normal; text-align: left;'
            },
            xAxis: {
                type: 'category',
                data: ['Emails Created', 'Campaigns Sent', 'Avg Send Freq'],
                axisLabel: {
                    interval: 0,
                    width: 60
                }
            },
            yAxis: { type: 'value' },
            series: [
                {
                    name: 'Count',
                    type: 'bar',
                    data: [summary?.totalEmailsCreated, summary?.totalSent, summary?.averageSendFrequency]
                }
            ],
            graphic: {
                type: 'text',
                left: 'center',
                top: 'middle',
                style: {
                    text:
                        summary?.totalEmailsCreated || summary?.totalSent || summary?.averageSendFrequency
                            ? ''
                            : 'No data available',
                    fontSize: 18,
                    fill: '#aaa'
                }
            }
        },
        {
            tooltip: { trigger: 'axis' },
            yAxis: [{ type: 'value', boundaryGap: [0, 0.01] }],
            xAxis: { type: 'category', data: ['Opens', 'Clicks'] },
            series: [
                {
                    name: 'Total',
                    type: 'bar',
                    data: [summary?.sumOfTotalOpens, summary?.sumOfTotalClicks]
                },
                {
                    name: 'Unique',
                    type: 'bar',
                    data: [summary?.sumOfUniqueOpens, summary?.sumOfUniqueClicks]
                }
            ],
            graphic: {
                type: 'text',
                left: 'center',
                top: 'middle',
                style: {
                    text:
                        summary?.sumOfTotalOpens || summary?.sumOfTotalClicks
                            ? ''
                            : 'No data available',
                    fontSize: 18,
                    fill: '#aaa'
                }
            }
        },
        {
            tooltip: {
                trigger: 'item',
                formatter: (params) =>
                    `Length: ${params.value[0]}<br/>Open Rate: ${params.value[1]}%`
            },
            xAxis: {
                name: 'Subject Length',
                type: 'value',
                nameLocation: 'center',
                nameGap: 25
            },
            yAxis: { name: 'Open Rate (%)', type: 'value' },
            series: [
                {
                    type: 'scatter',
                    data: subjectData,
                    symbolSize: 10,
                    itemStyle: { color: chartColours[18].value }
                }
            ],
            graphic: {
                type: 'text',
                left: 'center',
                top: 'middle',
                style: {
                    text: subjectData.length > 0 ? '' : 'No data available',
                    fontSize: 18,
                    fill: '#aaa'
                }
            }
        },
        {
            tooltip: { trigger: 'item', formatter: (params) => `${params.name}: ${params.value[0]}:00` },
            xAxis: {
                type: 'value',
                min: 0,
                max: 24,
                interval: 4,
                axisLabel: { formatter: '{value}:00' }
            },
            yAxis: {
                type: 'category',
                data: sendTimeData.map((item) => item.label),
                axisLabel: {
                    rotate: 60,
                    marginLeft: 10,
                    overflow: 'break'
                }
            },
            series: [
                {
                    type: 'scatter',
                    data: sendTimeData
                        .filter((item) => item.time !== 0)
                        .map((item) => [item.time, item.label]),
                    symbolSize: 15,
                    label: {
                        show: true,
                        position: 'right',
                        formatter: (params) => `${params.value[0]}:00`
                    },
                    itemStyle: { color: chartColours[2].value }
                }
            ],
            graphic: {
                type: 'text',
                left: 'center',
                top: 'middle',
                style: {
                    text: sendTimeData.some((item) => item.time > 0) ? '' : 'No data available',
                    fontSize: 18,
                    fill: '#aaa'
                }
            }
        }
    ];

    const settings = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000,
        dotsClass: `slick-dots ${classes.dots}`,
        beforeChange: (oldIndex, newIndex) => {
            setCurrentChartIndex(newIndex);
        }
    };

    useEffect(() => {
        if (showGraph) {
            handleFetchSummary();
        }
    }, [days]);

    return (
        <Card
            elevation={4}
            sx={{
                minHeight: 460,
                maxHeight: 460,
                borderRadius: 3,
                px: 1
            }}
        >
            <CardHeader
                sx={{ pb: 0 }}
                title={(
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ fontWeight: 'bold', color: 'primary.main' }}
                    >
                        {chartHeaders[currentChartIndex]}
                    </Typography>
                )}
                action={showGraph && (
                    <ToggleButtonGroup
                        value={days}
                        exclusive
                        onChange={handleChangeDays}
                        size="small"
                    >
                        <StyledTooltip title="30 Days">
                            <ToggleButton value="30">
                                <Typography>30</Typography>
                            </ToggleButton>
                        </StyledTooltip>
                        <StyledTooltip title="60 Days">
                            <ToggleButton value="60">
                                <Typography>60</Typography>
                            </ToggleButton>
                        </StyledTooltip>
                        <StyledTooltip title="90 Days">
                            <ToggleButton value="90">
                                <Typography>90</Typography>
                            </ToggleButton>
                        </StyledTooltip>
                    </ToggleButtonGroup>
                )
                }
            />
            <CardContent sx={{ pt: 0, px: 0 }}>
                {!showGraph ? (
                    <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 20 }}>
                        <Button variant="contained" color="primary" onClick={handleClick}>
                            Load graphs
                        </Button>
                    </Box>
                ) : isLoading ? (
                    <Skeleton
                        variant="rounded"
                        sx={{
                            borderRadius: 2,
                            mt: 2,
                            minHeight: 360,
                            maxHeight: 360
                        }}
                    />
                ) : (
                    <Slider {...settings}>
                        {chartOptions.map((option, index) => (
                            <Box key={index}>
                                <ReactECharts option={option} style={{ height: '390px', width: '100%' }} />
                            </Box>
                        ))}
                    </Slider>
                )}
            </CardContent>
        </Card>
    );
};

export default ContentGraph;
