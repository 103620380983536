import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Unstable_Grid2';
import PhoneIcon from '@mui/icons-material/Phone';
import Button from '@mui/material/Button';
import LanguageIcon from '@mui/icons-material/Language';
import SupportIcon from '@mui/icons-material/Support';

const ContactSupport = () => {
    return (
        <Grid container spacing={1}>
            <Grid xs={12}>
                <Card
                    elevation={4}
                    sx={{
                        minHeight: 125,
                        maxHeight: 125,
                        borderRadius: 3,
                        px: 1
                    }}
                >
                    <CardHeader
                        sx={{
                            pb: 0
                        }}
                        title={(
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'primary.main'
                                }}
                            >
                                Contact Support
                            </Typography>
                        )}
                    />
                    <CardContent sx={{ pt: 0, display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            sx={{ lineHeight: 1.2 }}
                            variant="text"
                            href="https://help.newzapp.co.uk/"
                            target="_blank"
                            size="large"
                            startIcon={<LanguageIcon fontSize="small" sx={{ color: 'black' }} />}
                        >
                            NewZapp Knowledge Base
                        </Button>
                        <Button
                            sx={{ lineHeight: 1.2 }}
                            variant="text"
                            href="https://help.newzapp.co.uk/raise-a-ticket"
                            target="_blank"
                            size="large"
                            startIcon={<SupportIcon fontSize="small" sx={{ color: 'black' }} />}
                        >
                            Raise A Ticket
                        </Button>
                        <Button
                            sx={{ lineHeight: 1.2 }}
                            variant="text"
                            href="tel:01392447200"
                            target="_blank"
                            size="large"
                            startIcon={<PhoneIcon fontSize="small" sx={{ color: 'black' }} />}
                        >
                            01392 447 200
                        </Button>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default ContactSupport;