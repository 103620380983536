import React, { useRef } from 'react';
import { Box } from '@mui/material';
import { grey, red, amber, lightGreen, green } from '@mui/material/colors';
import ReactECharts from 'echarts-for-react';

const SentimentGauge = ({ score, colour, period }) => {
    const ref = useRef(null);
    const chartHeight = 400;

    const options = {
        series: [
            {
                type: 'gauge',
                startAngle: 180,
                endAngle: 0,
                min: 0,
                max: 10,
                progress: {
                    show: true,
                    roundCap: true,
                    width: 32,
                    itemStyle: {
                        color: (score > 7) ? lightGreen[500] : (score > 5) ? green[500] : (score > 2) ? amber[500] : red[500]
                    }
                },
                axisLine: {
                    roundCap: true,
                    lineStyle: {
                        width: 32,
                        color: [
                            [1, grey[300]]
                        ]
                    }
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    show: false
                },
                pointer: {
                    show: false
                },
                title: {
                    show: false
                },
                splitNumber: {
                    show: false
                },
                data: [
                    {
                        value: score,
                        detail: {
                            offsetCenter: [0, -25],
                            valueAnimation: true,
                            formatter: (value) => {
                                if (period) {
                                    return `{unit|Period ${period}:}\n` + '{value|' + value.toFixed(1) + '}';
                                }
                                else {
                                    return '\n' + '{value|' + value.toFixed(1) + '}';
                                }
                            },
                            rich: {
                                value: {
                                    fontSize: 40,
                                    lineHeight: 28,
                                    fontWeight: 'bold',
                                    color: colour
                                },
                                unit: {
                                    fontSize: 12,
                                    color: grey[600]
                                }
                            }
                        }
                    }
                ]
            }
        ]
    };

    return (
        <Box
            sx={{
                overflow: 'hidden',
                // We shouldn't have to set height or neg margin, but eCharts gauges seem to be broken in this respect
                height: 180
            }}
        >
            <Box
                sx={{
                    mb: `-${chartHeight / 2.5}px`,
                    mt: `-${chartHeight / 8}px`
                }}
            >
                <ReactECharts
                    option={options}
                    style={{ height: chartHeight }}
                    opts={{ renderer: 'svg' }}
                    ref={ref}
                />
            </Box>
        </Box>
    );
};

export default SentimentGauge;
