import React from 'react';
import { Box, TextField, IconButton, InputAdornment } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const Form = ({ formData, setFormData, onOpenImagePicker }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <Box component="form" noValidate autoComplete="off">
            <TextField
                required
                fullWidth
                size="small"
                name="title"
                label="Title"
                value={formData.title}
                onChange={handleChange}
                variant="outlined"
            />
            <TextField
                fullWidth
                size="small"
                name="subtitle"
                label="Subtitle"
                value={formData.subtitle}
                onChange={handleChange}
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Message"
                name="body"
                multiline
                rows={3}
                value={formData.body}
                onChange={handleChange}
                variant="outlined"
            />
            <TextField
                sx={{ pr: 0 }}
                fullWidth
                size="small"
                name="image"
                label="Image URL"
                value={formData.image}
                onChange={handleChange}
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={onOpenImagePicker}>
                                <CloudUploadIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </Box>
    );
};

export default Form;
