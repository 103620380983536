import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, Tab, Tabs, Divider, Collapse } from '@mui/material';
import { Schedule } from '@mui/icons-material';
import EmailOpensHeatmap from './charts/EmailOpensHeatmap';

const TabbedHeatmapCard = ({ a, b, showB }) => {
    const [tab, setTab] = useState('a');

    useEffect(() => {
        !showB && setTab('a');
    }, [showB]);

    return (
        <Card sx={{ m: 0, p: 0 }}>
            <CardHeader
                sx={{ m: 0, py: 1 }}
                title="Peak Engagement"
                titleTypographyProps={{
                    variant: 'h6',
                    fontWeight: 'normal'
                }}
                avatar={<Schedule />}
            />
            <CardContent
                sx={{
                    m: 0,
                    p: 0,
                    '&:last-child': {
                        paddingBottom: 0
                    }
                }}
            >
                <Collapse in={showB}>
                    <Tabs
                        value={tab}
                        onChange={(e, value) => setTab(value)}
                        variant="fullWidth"
                    >
                        <Tab label="Period A" value="a" />
                        <Tab label="Period B" value="b" />
                    </Tabs>
                    <Divider />
                </Collapse>
                <EmailOpensHeatmap
                    data={tab === 'a' ? a.heatmapData : b?.heatmapData}
                />
            </CardContent>
        </Card>
    );
};

export default TabbedHeatmapCard;