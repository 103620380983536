import React, { useState, useEffect, useCallback } from 'react';
import useInput from '../../hooks/useInput';
import useInputDate from '../../hooks/useInputDate';
import useInputPhoneNumber from '../../hooks/useInputPhoneNumber';
import DatePicker from '../../DatePicker';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';

const getDobString = dob => {
    //todo: we shouldn't have to do this
    const handleGetDobString = () => {
        const year = dayjs(dob).get('year');
        const month = dayjs(dob).get('month') + 1;
        const monthString = month.toString().length < 2 ? `0${month}` : month;
        const date = dayjs(dob).get('date').toString().length < 2 ? `0${dayjs(dob).get('date')}` : dayjs(dob).get('date');

        return `${year}-${monthString}-${date}T00:00:00.000Z`;
    };

    //if dob is a string, use it. Else it's a date or dayjs object
    return typeof dob === 'string' ? dob : dob === null ? null : handleGetDobString();
};

const Details = ({ contact, setNewDetails, setUnsavedDetails, isReverting, setIsReverting }) => {
    const [input, setInput] = useState(null);
    const [isValid, setIsValid] = useState(true);

    const onRefChange = useCallback(node => setInput(node), []);

    const emailAddress = useInput(contact.emailAddress);
    const title = useInput(contact.title);
    const firstName = useInput(contact.firstName);
    const lastName = useInput(contact.lastName);
    const dateOfBirth = useInputDate(contact.dateOfBirth);
    const jobTitle = useInput(contact.jobTitle);
    const companyName = useInput(contact.companyName);
    const telNo = useInputPhoneNumber(contact.telNo);
    const mobilePhone = useInputPhoneNumber(contact.mobilePhone);
    const address1 = useInput(contact.address1);
    const address2 = useInput(contact.address2);
    const address3 = useInput(contact.address3);
    const city = useInput(contact.city);
    const county = useInput(contact.county);
    const postcode = useInput(contact.postcode);
    const country = useInput(contact.country);

    useEffect(() => {

        if (!isReverting) return;

        emailAddress.onRevert();
        title.onRevert();
        firstName.onRevert();
        lastName.onRevert();
        dateOfBirth.onRevert();
        jobTitle.onRevert();
        companyName.onRevert();
        telNo.onRevert();
        mobilePhone.onRevert();
        address1.onRevert();
        address2.onRevert();
        address3.onRevert();
        city.onRevert();
        county.onRevert();
        postcode.onRevert();
        country.onRevert();

        setNewDetails(null);
        setIsValid(true);
        setIsReverting(false);
    }, [isReverting]);

    const isEqual = (oldValue, newValue, lowercase = false) => {
        if (oldValue === null && newValue === '') {
            return true;
        }
        else if (lowercase) {
            return Boolean(oldValue.toLowerCase() === newValue.toLowerCase());
        }
        else {
            return Boolean(oldValue === newValue);
        }
    };

    useEffect(() => {
        if (!contact) return;

        let isDateOfBirthEqual = dayjs(contact.dateOfBirth).isSame(dateOfBirth.value, 'day');

        if (!contact.dateOfBirth && !dateOfBirth.value) {
            isDateOfBirthEqual = true;
        }

        const newData = !isEqual(contact.emailAddress, emailAddress.value, true)
            || !isEqual(contact.title, title.value)
            || !isEqual(contact.firstName, firstName.value)
            || !isEqual(contact.lastName, lastName.value)
            || !isDateOfBirthEqual
            || !isEqual(contact.jobTitle, jobTitle.value)
            || !isEqual(contact.companyName, companyName.value)
            || !isEqual(contact.telNo, telNo.value)
            || !isEqual(contact.mobilePhone, mobilePhone.value)
            || !isEqual(contact.address1, address1.value)
            || !isEqual(contact.address2, address2.value)
            || !isEqual(contact.address3, address3.value)
            || !isEqual(contact.city, city.value)
            || !isEqual(contact.county, county.value)
            || !isEqual(contact.postcode, postcode.value)
            || !isEqual(contact.country, country.value)
            || false;

        setUnsavedDetails(newData);

        //every change updates state at top level
        //todo this negates the need for a save at this level
        if (newData) {

            const dateOfBirthString = getDobString(dateOfBirth.value);

            setNewDetails({
                emailAddress: emailAddress.value,
                title: title.value,
                firstName: firstName.value,
                lastName: lastName.value,
                dateOfBirth: dateOfBirthString,
                jobTitle: jobTitle.value,
                companyName: companyName.value,
                telNo: telNo.value,
                mobilePhone: mobilePhone.value,
                address1: address1.value,
                address2: address2.value,
                address3: address3.value,
                city: city.value,
                county: county.value,
                postcode: postcode.value,
                country: country.value
            });

        }
    }, [
        contact,
        emailAddress.value,
        title.value,
        firstName.value,
        lastName.value,
        dateOfBirth.value,
        jobTitle.value,
        companyName.value,
        telNo.value,
        mobilePhone.value,
        address1.value,
        address2.value,
        address3.value,
        city.value,
        county.value,
        postcode.value,
        country.value
    ]);

    useEffect(() => {
        (input?.value !== undefined) && setIsValid(input.checkValidity());
    }, [input?.value]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        size="small"
                        margin="none"
                        id="emailAddress"
                        label="Email Address"
                        placeholder="e.g. johnsmith@company.co.uk"
                        type="email"
                        error={!isValid}
                        inputRef={onRefChange}
                        required
                        value={emailAddress.value}
                        onChange={emailAddress.onChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        margin="none"
                        id="title"
                        label="Title"
                        placeholder="e.g. Mr, Mrs, Miss, Ms, Dr..."
                        value={title.value}
                        onChange={title.onChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        margin="none"
                        id="firstName"
                        label="First Name"
                        placeholder="e.g. John"
                        value={firstName.value}
                        onChange={firstName.onChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        margin="none"
                        id="lastName"
                        label="Last Name"
                        placeholder="e.g. Smith"
                        value={lastName.value}
                        onChange={lastName.onChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DatePicker
                        label="Date of Birth"
                        value={dateOfBirth.value}
                        onChange={dateOfBirth.onChange}
                        fullWidth={true}
                        disableFuture={false}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        margin="none"
                        id="jobTitle"
                        label="Job Title"
                        placeholder="e.g. Managing Director"
                        value={jobTitle.value}
                        onChange={jobTitle.onChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        margin="none"
                        id="companyName"
                        label="Company"
                        placeholder="e.g. NewZapp"
                        value={companyName.value}
                        onChange={companyName.onChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        margin="none"
                        id="telNo"
                        label="Telephone"
                        placeholder="e.g. 01234567890"
                        value={telNo.value}
                        onChange={telNo.onChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        margin="none"
                        id="mobilePhone"
                        label="Mobile"
                        placeholder="e.g. 07777123456"
                        value={mobilePhone.value}
                        onChange={mobilePhone.onChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        margin="none"
                        id="address1"
                        label="Address 1"
                        placeholder="e.g. Apartment 1"
                        value={address1.value}
                        onChange={address1.onChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        margin="none"
                        id="address2"
                        label="Address 2"
                        placeholder="e.g. 1 Example Road"
                        value={address2.value}
                        onChange={address2.onChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        margin="none"
                        id="address3"
                        label="Address 3"
                        placeholder="e.g. Example Industrial Estate"
                        value={address3.value}
                        onChange={address3.onChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        margin="none"
                        id="city"
                        label="City"
                        placeholder="e.g. Exeter"
                        value={city.value}
                        onChange={city.onChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        margin="none"
                        id="county"
                        label="County"
                        placeholder="e.g. Devon"
                        value={county.value}
                        onChange={county.onChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        margin="none"
                        id="postcode"
                        label="Postcode"
                        placeholder="e.g. AB1 2CD"
                        value={postcode.value}
                        onChange={postcode.onChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        margin="none"
                        id="country"
                        label="Country"
                        placeholder="e.g. United Kingdom"
                        value={country.value}
                        onChange={country.onChange}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Details;