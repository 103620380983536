import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import EmailIcon from '@mui/icons-material/Email';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import WebIcon from '@mui/icons-material/Web';
import Box from '@mui/material/Box';
import PollIcon from '@mui/icons-material/Poll';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TeamsImagePicker from './TeamsImagePicker';
import Form from './Form';
import AdaptiveCardPreview from './AdaptiveCardPreview';
import SurveySelect from '../../survey/SurveySelect';
import CampaignSelect from '../../campaign/CampaignSelect';
import PageSelect from '../../page/PageSelect';
import DialogContentText from '@mui/material/DialogContentText';

const TeamsSendContentDialog = ({ onClose, onSubmit, item }) => {
    const [content, setContent] = useState(null);
    const [type, setType] = useState(content?.type ?? 'email');
    const [step, setStep] = useState(0);
    const [isImagePickerOpen, setIsImagePickerOpen] = useState(false);

    const placeholderImage = {
        'email' : 'https://cdn.newzapp.co.uk/assets/email-blue-on-white.png',
        'page' : 'https://cdn.newzapp.co.uk/assets/landing-page-blue-on-white.png',
        'survey' : 'https://cdn.newzapp.co.uk/assets/survey-blue-on-white.png'
    };

    const [formData, setFormData] = useState({
        title: '',
        subtitle: '',
        image: placeholderImage['email'],
        body: ''
    });

    const handleOpenImagePicker = () => {
        setIsImagePickerOpen(true);
        setStep(-1);
    };

    const handleCloseImagePicker = (selectedImageUrl) => {
        setIsImagePickerOpen(false);

        if (selectedImageUrl) {
            setFormData(prev => ({ ...prev, image: selectedImageUrl }));
        }

        setStep(1);
    };

    const handleFormChange = (newFormData) => {
        setFormData(newFormData);
    };

    const isValidUrl = (url) => {
        try {
            new URL(url);
            return true;
        }
        catch (e) {
            return false;
        }
    };

    const isFormValid = () => {
        return (
            content?.id &&
            formData.title.trim() !== '' &&
            (!formData.image || isValidUrl(formData.image))
        );
    };

    const handleSubmit = async e => {
        e.preventDefault();

        onSubmit(content, type, item, formData);
        onClose();
    };

    const card = {
        type: 'AdaptiveCard',
        $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
        version: '1.2',
        body: [
            {
                type: 'Container',
                style: 'default',
                items: [
                    {
                        type: 'ColumnSet',
                        columns: [
                            {
                                type: 'Column',
                                width: 'auto',
                                items: [
                                    {
                                        type: 'Image',
                                        url: formData.image ? formData.image : placeholderImage[type],
                                        size: 'Medium'
                                    }
                                ]
                            },
                            {
                                type: 'Column',
                                width: 'stretch',
                                items: [
                                    {
                                        type: 'TextBlock',
                                        text: formData.title ? formData.title : 'Title',
                                        weight: 'Bolder',
                                        wrap: true,
                                        size: 'Medium'
                                    },
                                    {
                                        type: 'TextBlock',
                                        text: formData.subtitle ? formData.subtitle : 'Subtitle',
                                        wrap: true,
                                        spacing: 'None'
                                    },
                                    {
                                        type: 'TextBlock',
                                        text: formData.body ? formData.body : 'Message',
                                        wrap: true,
                                        spacing: 'None'
                                    },
                                    {
                                        type: 'TextBlock',
                                        text: 'Content Link: View Content',
                                        wrap: true,
                                        spacing: 'None',
                                        isSubtle: true
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    };

    useEffect(() => {
        setFormData(prev => ({
            ...prev,
            image: placeholderImage[type]
        }));
    }, [type]);

    return (
        <>
            <Dialog onClose={onClose} open={step >= 0} maxWidth={step === 0 ? 'lg' : 'md'}>
                <DialogTitle>Send Content to: {item.channel.displayName}</DialogTitle>
                <DialogContent>
                    {step === 0 ? (
                        <>
                            <DialogContentText>Only sent campaigns, published landing pages and active surveys can be assigned.</DialogContentText>
                            <Tabs value={type} onChange={(e, value) => setType(value)}>
                                <Tab icon={<EmailIcon />} iconPosition="start" label="Emails" value="email" />
                                <Tab icon={<WebIcon />} iconPosition="start" label="Landing Pages" value="page" />
                                <Tab icon={<PollIcon />} iconPosition="start" label="Surveys" value="survey" />
                            </Tabs>
                            {type === 'survey' && (
                                <SurveySelect
                                    onSelect={setContent}
                                    currentlySelected={content}
                                    showTools={false}
                                />
                            )}
                            {type === 'email' && (
                                <CampaignSelect
                                    onSelect={setContent}
                                    currentlySelected={content}
                                />
                            )}
                            {type === 'page' && (
                                <PageSelect
                                    onSelect={setContent}
                                    currentlySelected={content}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            <Stack spacing={2}>
                                <Alert severity="info">
                                    <AlertTitle>Selected {type === 'email' ? 'Email' : type === 'survey' ? 'Survey' : 'Landing Page'}: {content?.name}</AlertTitle>
                                </Alert>
                            </Stack>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ flex: 1, padding: 1 }}>
                                    <Form formData={formData} setFormData={handleFormChange} onOpenImagePicker={handleOpenImagePicker} />
                                </Box>
                                <Box sx={{ flex: 1, padding: 2, maxWidth: 450, maxHeight: 350, overflow: 'auto' }}>
                                    <AdaptiveCardPreview card={card} />
                                </Box>
                            </Box>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    {step === 0 ? (
                        <Button disabled={!content?.id} onClick={() => setStep(1)}>Send Content</Button>
                    ) : (
                        <>
                            <Button disabled={!isFormValid()} onClick={handleSubmit}>Post</Button>
                            <Button variant="outlined" onClick={() => setStep(0)}>Back</Button>
                        </>
                    )}
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isImagePickerOpen} onClose={() => handleCloseImagePicker(null)} maxWidth="md">
                <DialogTitle>Select an Image for your teams content</DialogTitle>
                <IconButton
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500]
                    })}
                    onClick={() => {
                        setIsImagePickerOpen(false);
                        setStep(1);
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={{ py: 0 }}>
                    <TeamsImagePicker handleImageSelect={(url) => handleCloseImagePicker(url)} />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default TeamsSendContentDialog;