import React, { useEffect, useState } from 'react';
import { format, endOfDay } from 'date-fns';
import Box from '@mui/material/Box';
import useSearch from '../../hooks/useSearch';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import DatePicker from '../../DatePicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TablePagination from '@mui/material/TablePagination';
import FormControlLabel from '@mui/material/FormControlLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import { useHistory } from 'react-router-dom';
import buildUrl from '../../buildUrl';
import useSnackbar from '../../hooks/useSnackbar';
import useApi from '../../hooks/useApi';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import TableBody from '@mui/material/TableBody';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ListItemText from '@mui/material/ListItemText';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDeleteDialog from '../dialogs/ConfirmDeleteDialog';
import RenameDialog from '../dialogs/RenameDialog';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ArchiveIcon from '@mui/icons-material/Archive';
import PollIcon from '@mui/icons-material/Poll';
import Tooltip from '@mui/material/Tooltip';
import { useMediaQuery } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SendIcon from '@mui/icons-material/Send';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import EmailIcon from '@mui/icons-material/Email';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import PlaceIcon from '@mui/icons-material/Place';
import useTheme from '@mui/material/styles/useTheme';
import LinkIcon from '@mui/icons-material/Link';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { grey } from '@mui/material/colors';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import CopyToAccountsDialog from '../dialogs/CopyToAccountsDialog';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import useAccount from '../../hooks/useAccount';

import Android12Switch from '../selects/ArchiveToggle';

const greyColor = grey[600];

const iconStyle = {
    marginRight: 2,
    verticalAlign: 'middle'
};

const ToolbarItem = ({ children, marginRight = 0 }) => {
    return <Box sx={{ display: 'inline-flex', margin: 0.5, marginRight }}>{children}</Box>;
};

const SurveyActionIcons = ({ name, action, status = 'Active', isMedium }) => {
    const iconMapping = {
        'Edit': <ModeEditIcon />,
        'Distribute': <SendIcon />,
        'Results': <AnalyticsIcon />,
        'Copy Link': <LinkIcon />
    };

    return (
        <>
            {isMedium ? (
                <Tooltip title={name} arrow>
                    <IconButton onClick={action} disabled={name === 'Distribute' && status === 'Closed'} sx={{ marginRight: 1, color: greyColor }}>
                        {iconMapping[name]}
                    </IconButton>
                </Tooltip>
            ) : (
                <Button sx={{ marginRight: 1, color: greyColor }} variant="text" onClick={action} startIcon={iconMapping[name]} disabled={name === 'Distribute' && status === 'Closed'}>
                    {name}
                </Button>
            )}
        </>
    );
};

const SurveyNameStatus = ({ name, status, onRun }) => {
    const handleAction = (e, action) => {
        e.stopPropagation();
        action();
    };

    const iconStyle = { marginRight: 1 };

    const StatusIcon = () => {
        if (status === 'Active') {
            return <PublishedWithChangesIcon fontSize="small" color="success" sx={iconStyle} />;
        }

        if (status === 'Closed') {
            return <UnpublishedIcon fontSize="small" color="info" sx={iconStyle} />;
        }

        return <PollIcon fontSize="small" color="disabled" sx={iconStyle} />;
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Tooltip title={status} arrow>
                <Box>
                    <StatusIcon />
                </Box>
            </Tooltip>
            <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</Box>
            {status === 'Active' && (
                <Tooltip title="Preview" arrow>
                    <IconButton onClick={e => handleAction(e, onRun)} sx={{ marginLeft: 2, verticalAlign: 'middle' }}>
                        <LaunchIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    );
};

const EmailNameStatus = ({ status, name }) => {
    const StatusIcon = () => {
        if (status === 'Sent') {
            return <MarkEmailReadIcon fontSize="small" color="success" sx={iconStyle} />;
        }

        if (status === 'Sending') {
            return <SendIcon fontSize="small" color="info" sx={iconStyle} />;
        }

        if (status === 'Scheduled') {
            return <ScheduleSendIcon fontSize="small" color="info" sx={iconStyle} />;
        }

        return <EmailIcon fontSize="small" color="disabled" sx={iconStyle} />;
    };

    // Inner fragment used below to get around this issue: https://github.com/mui/material-ui/issues/31261

    return (
        <>
            <Tooltip title={status} arrow>
                <>
                    <StatusIcon />
                </>
            </Tooltip>
            {name}
        </>
    );
};

const EmailMenu = ({
    onDelete,
    status
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(e.currentTarget);
    };

    const handleClose = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(null);
    };

    const handleAction = (e, action) => {
        e.stopPropagation();

        action();
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleClick} size="small">
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {status === 'Scheduled' ? (
                    <Box>
                        <MenuItem onClick={e => handleAction(e, onDelete)}>
                            <ListItemIcon>
                                <CancelScheduleSendIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Cancel &amp; Delete Campaign</ListItemText>
                        </MenuItem>
                    </Box>
                ) : (
                    <MenuItem onClick={e => handleAction(e, onDelete)}>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

const QRCodeIcon = ({ name }) => {
    return (
        <>
            <Tooltip arrow>
                <QrCode2Icon fontSize="small" sx={iconStyle} />
            </Tooltip>
            {name}
        </>
    );
};

const EmptyTable = ({ isInitialising, filtersApplied, handleButtonClick }) => {
    if (isInitialising) {
        return null;
    }

    return (
        <Box sx={{ padding: 6, textAlign: 'center' }}>
            {filtersApplied ? (
                <Typography>No data</Typography>
            ) : (
                <>
                    <Typography>You haven't created any Surveys yet.</Typography>
                    <Typography>Click 'New Survey' to create your first one!</Typography>
                    <Button
                        onClick={handleButtonClick}
                        startIcon={<AddIcon />}
                        sx={{ whiteSpace: 'pre', mt: 2 }}
                    >
                        New Survey
                    </Button>
                </>
            )}
        </Box>
    );
};

const SurveyMenu = ({ userAccounts, isSmall, data, onDelete, onRename, onCopy, onCopyToAccounts, onEdit, onArchive, onUnarchive, onRun, onShowResults, onSend, onCopyLink }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const archiveLabel = data.archived ? 'Unarchive' : 'Archive';

    const handleClick = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(e.currentTarget);
    };

    const handleClose = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(null);
    };

    const handleAction = (e, action) => {
        e.stopPropagation();

        action();
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleClick} size="small" sx={{ marginRight: 1 }}>
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {data.status === 'Active' && (
                    <>
                        <MenuItem onClick={e => handleAction(e, onRun)}>
                            <ListItemIcon>
                                <VisibilityIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Preview</ListItemText>
                        </MenuItem>
                        {isSmall && (
                            <>
                                <MenuItem onClick={e => handleAction(e, onSend)}>
                                    <ListItemIcon>
                                        <SendIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Distribute</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={e => handleAction(e, onCopyLink)}>
                                    <ListItemIcon>
                                        <LinkIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Copy Link</ListItemText>
                                </MenuItem>
                            </>
                        )}
                        <Divider />
                    </>
                )}
                {isSmall && (
                    <MenuItem onClick={onEdit}>
                        <ListItemIcon>
                            <ModeEditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Edit</ListItemText>
                    </MenuItem>
                )}
                <MenuItem onClick={e => handleAction(e, onRename)}>
                    <ListItemIcon>
                        <DriveFileRenameOutlineIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Rename</ListItemText>
                </MenuItem>
                <MenuItem onClick={e => handleAction(e, onCopy)}>
                    <ListItemIcon>
                        <ContentCopyIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Create Copy</ListItemText>
                </MenuItem>
                {userAccounts.length > 1 && (
                    <MenuItem onClick={e => handleAction(e, onCopyToAccounts)}>
                        <ListItemIcon>
                            <CopyAllIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Copy to Account(s)</ListItemText>
                    </MenuItem>
                )}
                {isSmall && (
                    <>
                        <MenuItem onClick={e => handleAction(e, onShowResults)}>
                            <ListItemIcon>
                                <AnalyticsIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>View Results</ListItemText>
                        </MenuItem>
                    </>
                )}
                <Divider />
                <MenuItem onClick={e => {
                    if (archiveLabel === 'Archive') {
                        handleAction(e, onArchive);
                    }
                    else {
                        handleAction(e, onUnarchive);
                    }
                }}>
                    <ListItemIcon>
                        <ArchiveIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{archiveLabel}</ListItemText>
                </MenuItem>
                <Tooltip title={data.submit > 0 ? 'Responses received; deleting disabled' : ''} disableHoverListener={data.submit === 0}>
                    <span>
                        <MenuItem onClick={e => handleAction(e, onDelete)} disabled={data.submit > 0}>
                            <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                        </MenuItem>
                    </span>
                </Tooltip>
            </Menu>
        </>
    );
};

const rowsPerPageOptions = [10, 25, 50];

const Surveys = () => {
    const { user } = useAccount();
    const history = useHistory();
    const [isInitialising, setIsInitialising] = useState(true);
    const [items, setItems] = useState([]);
    const [count, setCount] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [page, setPage] = useState(0);
    const [orderBy, setOrderBy] = useState('Modified');
    const [orderDesc, setOrderDesc] = useState(true);
    const [tempSearchValue, setTempSearchValue] = useSearch(setSearchValue);
    const [isLoading, setIsLoading] = useState(true);
    const { showSnackbar } = useSnackbar();
    const { handleGet, handleDelete, handlePut, handlePost } = useApi();
    const [itemToDelete, setItemToDelete] = useState(null);
    const [itemToRename, setItemToRename] = useState(null);
    const [itemToCopy, setItemToCopy] = useState(null);
    const [itemToCopyToAccounts, setItemToCopyToAccounts] = useState(null);
    const [isCopying, setIsCopying] = useState(false);
    const [status, setStatus] = useState('');
    const [archived, setArchived] = useState(false);
    const [openRowId, setOpenRowId] = useState(null);
    const [tab, setTab] = useState(0);
    const theme = useTheme();
    const baseUrl = window.location.origin;
    const [emailToDelete, setEmailToDelete] = useState(null);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const isMediumscreen = useMediaQuery(theme.breakpoints.down('xl'));

    const handleOpen = (id) => {
        setOpenRowId(id === openRowId ? null : id);
    };

    const handleCreateNew = () => history.push('/editor/surveys/new-survey');

    const handleRunSurvey = (e) => {
        const url = `${baseUrl}/#/surveys/${e.id}/${e.verifier}`;
        window.open(url);
    };

    const handleShowResults = (e) => history.push(`/editor/surveys/${e}/results`);

    const handleConfirmDelete = item => setItemToDelete(item);

    const handleOpenRenameDialog = item => setItemToRename(item);

    const handleOpenCopyDialog = item => setItemToCopy(item);

    const handleRowClick = (e) => history.push(`/editor/surveys/${e.id}`);

    const handleConfirmDeleteEmail = item => {
        setEmailToDelete(item);
    };

    const handleCopyLink = (e) => {
        const link = `${baseUrl}/#/surveys/${e.id}/${e.verifier}`;

        navigator.clipboard.writeText(link);
        showSnackbar('Link copied to clipboard', 'success');
    };

    const handleSendSurvey = async (e) => {
        setIsInitialising(true);

        const templateUrl = buildUrl('templates', { type: 'survey' });

        const template = await handleGet(templateUrl);

        if (!template.ok) {
            showSnackbar('Error fetching template', 'error');
            return;
        }

        const templateData = await template.json();

        const response = await handlePost('/emails', { surveyId: e.id, name: e.name, templateId: templateData[0].id, type: 'survey' });

        const data = await response.json();

        if (!response.ok) {
            showSnackbar('Error sending survey', 'error');
            return;
        }
        else {
            history.push(`/editor/email/${data.id}`);
        }
    };

    const handleFetchCount = async () => {
        const surveyParams = {
            search: searchValue,
            ...(fromDate && { fromDate: format(fromDate, 'yyyy-MM-dd') }),
            ...(toDate && { toDate: format(endOfDay(toDate), 'yyyy-MM-dd HH:mm:ss') }),
            ...(status !== '' && { status }),
            archived
        };

        const url = buildUrl('surveys/count', surveyParams);
        const response = await handleGet(url);

        if (!response.ok) {
            showSnackbar('Error fetching count', 'error');
            return;
        }

        const data = await response.json();

        setCount(data);
    };

    const handleFetchItems = async () => {
        const surveyParams = {
            search: searchValue,
            ...(fromDate && { fromDate: format(fromDate, 'yyyy-MM-dd') }),
            ...(toDate && { toDate: format(endOfDay(toDate), 'yyyy-MM-dd HH:mm:ss') }),
            skip: page * rowsPerPage,
            take: rowsPerPage,
            orderBy,
            isDescending: orderDesc,
            ...(status !== '' && { status }),
            archived
        };

        const url = buildUrl('surveys', surveyParams);
        const response = await handleGet(url);

        if (!response.ok) {
            showSnackbar('Error fetching items', 'error');
            setIsLoading(false);
            return;
        }

        const data = await response.json();

        setItems(data);
        setIsLoading(false);
    };

    const handleSort = (field, defaultOrder) => {
        if (field === orderBy) {
            setOrderDesc(!orderDesc);
        }
        else {
            setOrderBy(field);
            setOrderDesc(defaultOrder);
        }
    };

    const handleDeleteItem = async id => {
        const response = await handleDelete(`surveys/${id}`);

        if (!response.ok) {
            showSnackbar('Error Deleting Survey', 'error');
        }
        else {
            setItemToDelete(null);
            showSnackbar('Survey Deleted Successfully', 'success');
            handleFetchCount();
            handleFetchItems();
        }
    };

    const handleDeleteEmail = async () => {
        const endpoint = emailToDelete.status === 'Draft' ? 'emails' : 'campaigns';
        const id = emailToDelete.status === 'Draft' ? emailToDelete.id : emailToDelete.campaignId;
        const response = await handleDelete(`${endpoint}/${id}`);

        if (!response.ok) {
            showSnackbar('Error Deleting Email', 'error');
        }

        setEmailToDelete(null);
        handleFetchCount();
        handleFetchItems();
    };

    const handleUpdateItem = async ({ item, name = null, status = null, archived = null }) => {
        setIsLoading(true);

        const params = {
            ...item,
            ...(name && { name }),
            ...(status && { status }),
            archived,
            type: 'survey'
        };

        const response = await handlePut(`surveys/${item.id}`, params);

        if (!response.ok) {
            showSnackbar('Survey not found', 'error');
        }

        setItemToRename(null);
        showSnackbar(status !== null ? `Survey ${status}` : archived === true ? 'Survey Archived' : 'Survey Updated', 'success');
        await handleFetchItems();
    };

    const handleCopy = async name => {
        setIsCopying(true);

        const response = await handlePost('surveys', {
            name,
            ...({ surveyId: itemToCopy.id })
        });

        if (response.ok) {
            handleFetchItems();
            handleFetchCount();
            showSnackbar('Survey Copied Successfully', 'success');
        }
        else {
            showSnackbar('Survey could not be copied', 'error');
        }

        setIsCopying(false);
        setItemToCopy(null);
    };

    const handleCopyToAccounts = async (name, userAccounts) => {
        setIsCopying(true);

        const response = await handlePost('surveys', {
            name,
            surveyId: itemToCopyToAccounts.id,
            ...(userAccounts && { userAccounts })
        });

        if (response.ok) {
            const message = userAccounts.length === 1
                ? 'Survey copied successfully'
                : 'Survey successfully copied to accounts';

            showSnackbar(message, 'success');
            handleFetchItems();
            handleFetchCount();
        }

        setIsCopying(false);
        setItemToCopyToAccounts(null);
    };

    const totalQuestionCount = (e) => {
        const jsonItem = JSON.parse(e);

        if (!jsonItem.pages) {
            return 0;
        }

        return jsonItem.pages.reduce((acc, page) => {
            if (!Array.isArray(page.elements)) {
                return acc;
            }

            const questionCount = page.elements.filter(element => element.type !== 'image').length;

            return acc + questionCount;
        }, 0);
    };

    const CustomTabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 1 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    };

    const handleClickRow = item => {
        if (item.published) {
            history.push(`/reports/email/${item.campaignId}`);
        }
        else {
            history.push(`/editor/email/${item.id}`);
        }
    };

    const handleQRClickRow = item => {
        history.push(`/editor/qr/${item.id}`);
    };

    const handleInit = async () => {
        await handleFetchItems();
        await handleFetchCount();
        setIsInitialising(false);
    };

    useEffect(() => {
        if (isInitialising) {
            return;
        }

        handleFetchCount();
    }, [searchValue, fromDate, toDate, status]);

    useEffect(() => {
        setPage(0);
    }, [searchValue, fromDate, toDate, orderBy, orderDesc, rowsPerPage, status, archived]);

    useEffect(() => {
        setIsLoading(true);
    }, [searchValue, fromDate, toDate, orderBy, orderDesc, rowsPerPage, page, status, archived]);

    useEffect(() => {
        setStatus('');
        handleInit();
    }, []);

    useEffect(() => {
        if (isInitialising) {
            return;
        }

        isLoading && handleFetchItems();
    }, [isLoading]);

    if (isInitialising) {
        return <LoadingOverlay />;
    }

    return (
        <>
            <Toolbar disableGutters sx={{ mx: 1.5, paddingTop: 0.5, paddingBottom: 0.5 }}>
                <Box sx={{ flexGrow: 1, mr: 1 }}>
                    <ToolbarItem>
                        <OutlinedInput
                            value={tempSearchValue}
                            onChange={e => setTempSearchValue(e.target.value)}
                            placeholder="Search..."
                            size="small"
                            sx={{ mr: 1 }}
                            endAdornment={(
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setTempSearchValue('')}
                                        edge="end"
                                        disabled={!Boolean(tempSearchValue)}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            )}
                        />
                    </ToolbarItem>
                    <ToolbarItem marginRight={1}>
                        <DatePicker value={fromDate} onChange={setFromDate} label="Modified from date" />
                    </ToolbarItem>
                    <ToolbarItem marginRight={1}>
                        <DatePicker value={toDate} onChange={setToDate} label="Modified to date" />
                    </ToolbarItem>
                    <ToolbarItem>
                        <FormControl margin="none" size="small">
                            <InputLabel>Status</InputLabel>
                            <Select
                                value={status}
                                onChange={e => setStatus(e.target.value)}
                                sx={{ minWidth: 160 }}
                                label="Status"
                            >
                                <MenuItem value={''}>All</MenuItem>
                                <MenuItem value="Active">Active</MenuItem>
                                <MenuItem value="Closed">Closed</MenuItem>
                            </Select>
                        </FormControl>
                    </ToolbarItem>
                    <ToolbarItem>
                        <Box>
                            <FormControlLabel
                                sx={{ ml: 1 }}
                                control={<Android12Switch checked={archived} onChange={e => setArchived(e.target.checked)} />}
                                label="Archived"
                            />
                        </Box>
                    </ToolbarItem>
                </Box>
                <Button
                    onClick={handleCreateNew}
                    startIcon={<AddIcon />}
                    sx={{ whiteSpace: 'pre' }}
                >
                    New Survey
                </Button>
            </Toolbar>
            <Divider />
            {items.length > 0 ? (
                <>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell component="th" scope="row">
                                        <TableSortLabel
                                            active={orderBy === 'Name'}
                                            direction={orderDesc ? 'desc' : 'asc'}
                                            onClick={() => handleSort('Name', false)}
                                        >
                                            Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <TableSortLabel
                                            active={orderBy === 'Created'}
                                            direction={orderDesc ? 'desc' : 'asc'}
                                            onClick={() => handleSort('Created', false)}
                                        >
                                            Created
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <TableSortLabel
                                            active={orderBy === 'Modified'}
                                            direction={orderDesc ? 'desc' : 'asc'}
                                            onClick={() => handleSort('Modified', false)}
                                        >
                                            Modified
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <TableSortLabel
                                            active={orderBy === 'Status'}
                                            direction={orderDesc ? 'desc' : 'asc'}
                                            onClick={() => handleSort('Status', false)}
                                        >
                                            Status
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell component="th" scope="row" sx={{ width: '1px' }}>
                                        Questions
                                    </TableCell>
                                    <TableCell component="th" scope="row" sx={{ width: '1px', marginRight: 2 }}>
                                        Responses
                                    </TableCell>
                                    {!isSmallScreen && (
                                        <>
                                            <TableCell sx={{ width: '10px' }} align="right" />
                                            <TableCell sx={{ width: '10px' }} align="right" />
                                            <TableCell sx={{ width: '10px' }} align="right" />
                                            <TableCell sx={{ width: '10px' }} align="right" />
                                            <TableCell sx={{ width: '10px' }} align="right" />
                                        </>
                                    )}
                                    <TableCell sx={{ width: '10px' }} align="right" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.map(e => {
                                    const statusValue = e.status === 'Active' ? 'Active' : 'Closed';
                                    return (
                                        <>
                                            <TableRow
                                                key={e.id}
                                                sx={{ cursor: 'pointer', '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' }, '& > *': { borderBottom: 'unset' } }}
                                            >
                                                <TableCell sx={{ padding: 0 }}>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleOpen(e.id);
                                                        }}
                                                    >
                                                        {openRowId === e.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell onClick={() => handleRowClick(e)} sx={{ maxWidth: '500px', wordBreak: 'keep-all', overflow: 'hidden' }}>
                                                    <SurveyNameStatus name={e.name} status={e.status} onRun={() => handleRunSurvey(e)} />
                                                </TableCell>
                                                <TableCell>
                                                    {e.created ? format(new Date(e.created), 'dd/MM/yyyy') : '-'}
                                                </TableCell>
                                                <TableCell>
                                                    {e.modified ? format(new Date(e.modified), 'dd/MM/yyyy') : '-'}
                                                </TableCell>
                                                <TableCell>
                                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
                                                        <Select
                                                            value={statusValue}
                                                            onChange={(i) => handleUpdateItem({ item: e, status: i.target.value })}
                                                        >
                                                            <MenuItem value={'Active'}>Active</MenuItem>
                                                            <MenuItem value={'Closed'}>Closed</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {totalQuestionCount(e.json)}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {e.submit}
                                                </TableCell>
                                                {!isSmallScreen && (
                                                    <>
                                                        <TableCell align="right" sx={{ padding: 0 }}>
                                                            <SurveyActionIcons name="Edit" action={() => handleRowClick(e)} isMedium={isMediumscreen} />
                                                        </TableCell>
                                                        <TableCell align="right" sx={{ padding: 0 }}>
                                                            <SurveyActionIcons name="Distribute" action={() => handleSendSurvey(e)} status={statusValue} isMedium={isMediumscreen} />
                                                        </TableCell>
                                                        <TableCell align="right" sx={{ padding: 0 }}>
                                                            <SurveyActionIcons name="Results" action={() => handleShowResults(e.id)} isMedium={isMediumscreen} />
                                                        </TableCell>

                                                        <TableCell align="right" sx={{ padding: 0 }}>
                                                            <Divider orientation="vertical" flexItem sx={{ height: '25px', width: '15px' }} />
                                                        </TableCell>
                                                        <TableCell align="right" sx={{ padding: 0 }}>
                                                            <Tooltip title="Copy Link" arrow>
                                                                <IconButton sx={{ marginRight: 1 }} onClick={() => handleCopyLink(e)} disabled={statusValue === 'Closed'}>
                                                                    <LinkIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </>
                                                )}
                                                <TableCell align="right" sx={{ padding: 0 }}>
                                                    <SurveyMenu
                                                        userAccounts={user.accounts}
                                                        isSmall={isSmallScreen}
                                                        isMedium={isMediumscreen}
                                                        data={e}
                                                        onDelete={() => handleConfirmDelete(e)}
                                                        onRename={() => handleOpenRenameDialog(e)}
                                                        onCopy={() => handleOpenCopyDialog(e)}
                                                        onCopyToAccounts={() => setItemToCopyToAccounts(e)}
                                                        onEdit={() => handleRowClick(e)}
                                                        onArchive={() => handleUpdateItem({ item: e, archived: true })}
                                                        onUnarchive={() => handleUpdateItem({ item: e, archived: false })}
                                                        onRun={() => handleRunSurvey(e)}
                                                        onShowResults={() => handleShowResults(e.id)}
                                                        onSend={() => handleSendSurvey(e)}
                                                        onCopyLink={() => handleCopyLink(e)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={20}>
                                                    <Collapse in={openRowId === e.id} timeout="auto" unmountOnExit>
                                                        <Box sx={{ px: 2 }}>
                                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                                <Tabs value={tab} onChange={(e, value) => setTab(value)}>
                                                                    <Tab label="Survey Email" />
                                                                    <Tab label="Linked QR Code" />
                                                                </Tabs>
                                                            </Box>
                                                            <CustomTabPanel value={tab} index={0}>
                                                                {e.emails.length > 0 ? (
                                                                    <Table size="small">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell>Email</TableCell>
                                                                                <TableCell>Created</TableCell>
                                                                                <TableCell align="right">Modified</TableCell>
                                                                                <TableCell align="right">Status</TableCell>
                                                                                <TableCell align="right" />                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {e.emails.map((email) => (
                                                                                <TableRow key={email.id} onClick={() => handleClickRow(email)} sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' } }}>
                                                                                    <TableCell align="left">
                                                                                        <EmailNameStatus status={email.status} name={email.name} />
                                                                                    </TableCell>
                                                                                    <TableCell>{format(new Date(email.created), 'dd/MM/yyyy')}</TableCell>
                                                                                    <TableCell align="right">{format(new Date(email.modified), 'dd/MM/yyyy')}</TableCell>
                                                                                    <TableCell align="right">
                                                                                        {email.status}
                                                                                    </TableCell>
                                                                                    <TableCell align="right">
                                                                                        <EmailMenu
                                                                                            status={email.status}
                                                                                            onDelete={() => handleConfirmDeleteEmail(email)}
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                ) : (
                                                                    <Box sx={{ p: 2 }}>
                                                                        <Typography variant="body1">You haven't sent this Survey via email yet.</Typography>
                                                                    </Box>
                                                                )}
                                                            </CustomTabPanel>

                                                            <CustomTabPanel value={tab} index={1}>
                                                                {e.qrCodes.length > 0 ? (
                                                                    <Table size="small">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell>Name</TableCell>
                                                                                <TableCell>Location</TableCell>
                                                                                <TableCell align="right">Start Date</TableCell>
                                                                                <TableCell align="right">End Date</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {e.qrCodes.map(qr => (
                                                                                <TableRow key={qr.id} onClick={() => handleQRClickRow(qr)} sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' } }}>
                                                                                    <TableCell>
                                                                                        <QRCodeIcon name={qr.name} />
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {qr.location ? (
                                                                                            <Box display="inlineFlex" alignItems="center">
                                                                                                <PlaceIcon fontSize="small" color="info" />
                                                                                                <Typography variant="body2" ml={0.5}>{qr.location}</Typography>
                                                                                            </Box>
                                                                                        ) : (
                                                                                            <Box display="inlineFlex" alignItems="center">
                                                                                                <PlaceIcon fontSize="small" color="disabled" />
                                                                                                <Typography variant="body2" color={theme.palette.grey[500]} ml={0.5}>No location set</Typography>
                                                                                            </Box>
                                                                                        )}

                                                                                    </TableCell>
                                                                                    <TableCell align="right">{format(new Date(qr.startDate), 'dd/MM/yyyy')}</TableCell>
                                                                                    <TableCell align="right">{qr.endDate ? format(new Date(qr.endDate), 'dd/MM/yyyy') : '-'}</TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                ) : (
                                                                    <Box sx={{ p: 2 }}>
                                                                        <Typography variant="body1">You haven't assigned this Survey to a QR Code yet.</Typography>
                                                                    </Box>
                                                                )}
                                                            </CustomTabPanel>
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(e, newPage) => setPage(newPage)}
                        onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
                    />
                </>
            ) : (
                <EmptyTable
                    handleButtonClick={handleCreateNew}
                    filtersApplied={searchValue || fromDate || toDate || status || archived}
                />
            )}
            {isLoading && <LoadingOverlay />}

            {itemToDelete && (
                <ConfirmDeleteDialog
                    onClose={() => setItemToDelete(null)}
                    onDelete={handleDeleteItem}
                    item={itemToDelete}
                    type="survey"
                />
            )}
            {itemToRename && (
                <RenameDialog
                    onClose={() => setItemToRename(null)}
                    onSubmit={name => handleUpdateItem({ item: itemToRename, name })}
                    itemName={itemToRename.name}
                    type="survey"
                />
            )}
            {itemToCopy && (
                <RenameDialog
                    onClose={() => setItemToCopy(null)}
                    onSubmit={handleCopy}
                    itemName={itemToCopy.name}
                    isCopy={true}
                    isCopying={isCopying}
                    type="survey"
                />
            )}
            {itemToCopyToAccounts && (
                <CopyToAccountsDialog
                    onClose={() => setItemToCopyToAccounts(null)}
                    onSubmit={handleCopyToAccounts}
                    itemName={itemToCopyToAccounts.name}
                    isCopying={isCopying}
                    type="survey"
                />
            )}
            {emailToDelete && (
                <ConfirmDeleteDialog
                    onClose={() => setEmailToDelete(null)}
                    onDelete={handleDeleteEmail}
                    item={emailToDelete}
                    type="email"
                />
            )}
        </>
    );
};

export default Surveys;

//this is to create a new build as builds are failing.

