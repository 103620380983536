import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Toolbar, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TableSortLabel, Paper, Box, OutlinedInput, InputAdornment, FormControl, InputLabel, Select, MenuItem, Typography, AppBar, Tab, Tabs, IconButton } from '@mui/material';
import { Clear } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import { format } from 'date-fns';
import buildUrl from '../buildUrl';
import DataColumn from '../dataTable/DataColumn';
import DatePicker from '../DatePicker';
import useSearch from '../hooks/useSearch';
import useApi from '../hooks/useApi';
import CenteredProgress from '../loadingOverlay/CenteredProgress';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import useTheme from '@mui/material/styles/useTheme';
import RenderPeriodDateRange from '../account/reportingPeriod/RenderPeriodDateRange';

const rowsPerPageOptions = [10];

const columns = [
    { name: 'name', label: 'Name', orderDesc: false, dataType: 'text', width: '25%' },
    { name: 'title', label: 'Title', orderDesc: false, dataType: 'text', width: '25%' },
    { name: 'published', label: 'Status', orderDesc: false, dataType: 'pageChip', width: 120 },
    { name: 'publishedDateTime', label: 'Published', align: 'right', orderDesc: false, dataType: 'dateTime' },
    { name: 'totalOpens', label: 'Opens', align: 'right', orderDesc: true, dataType: 'number' },
    { name: 'totalClicks', label: 'Clicks', align: 'right', orderDesc: true, dataType: 'number' },
    { name: 'numberOfLinks', label: 'Links', align: 'right', orderDesc: true, dataType: 'number' }
];

const Pages = ({ selectedPeriods, prevSelectedPeriods, compare }) => {
    const history = useHistory();
    const theme = useTheme();
    const [reports, setReports] = useState(null);
    const [count, setCount] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [page, setPage] = useState(0);
    const [orderBy, setOrderBy] = useState('publishedDateTime');
    const [orderDesc, setOrderDesc] = useState(true);
    const [isInitialising, setIsInitialising] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [tempSearchValue, setTempSearchValue] = useSearch(setSearchValue);
    const { handleGet } = useApi();
    const [filterByPeriod, setFilterByPeriod] = useState('none');
    const [tab, setTab] = useState('navigation');

    const handleFetchCount = async () => {
        const filterByPeriodDates = filterByPeriod !== 'none' ? filterByPeriod === 'a' ? selectedPeriods[0] : selectedPeriods[1] : null;

        const from = filterByPeriodDates !== null ? format(filterByPeriodDates[0], 'yyyy-MM-dd') : fromDate ? format(fromDate, 'yyyy-MM-dd') : null;
        const to = filterByPeriodDates !== null ? format(filterByPeriodDates[1], 'yyyy-MM-dd') : toDate ? format(toDate, 'yyyy-MM-dd') : null;

        const params = {
            search: searchValue,
            ...(from && { fromDate: from }),
            ...(to && { toDate: to })
        };

        const url = buildUrl('pages/reports/count', params);
        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setCount(data);
    };

    const handleFetchReports = async () => {
        setIsLoading(true);

        const filterByPeriodDates = filterByPeriod !== 'none' ? filterByPeriod === 'a' ? selectedPeriods[0] : selectedPeriods[1] : null;

        const from = filterByPeriodDates !== null ? format(filterByPeriodDates[0], 'yyyy-MM-dd') : fromDate ? format(fromDate, 'yyyy-MM-dd') : null;
        const to = filterByPeriodDates !== null ? format(filterByPeriodDates[1], 'yyyy-MM-dd') : toDate ? format(toDate, 'yyyy-MM-dd') : null;

        const params = {
            search: searchValue,
            ...(from && { fromDate: from }),
            ...(to && { toDate: to }),
            skip: page * rowsPerPage,
            take: rowsPerPage,
            orderBy,
            isDescending: orderDesc
        };

        const url = buildUrl('pages/reports', params);
        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setReports(data);
        setIsLoading(false);
    };

    const handleSort = (field, defaultOrder) => {
        if (field === orderBy) {
            setOrderDesc(!orderDesc);
        }
        else {
            setOrderBy(field);
            setOrderDesc(defaultOrder);
        }
    };

    const handleChangeToDate = date => {
        setFilterByPeriod('none');
        setToDate(date);
    };

    const handleChangeFromDate = date => {
        setFilterByPeriod('none');
        setFromDate(date);
    };

    const handleChangeFilterByPeriod = period => {
        setFilterByPeriod(period);
        setToDate(null);
        setFromDate(null);
    };

    useEffect(() => {
        reports && setIsInitialising(false);
    }, [reports]);

    useEffect(() => {
        handleFetchCount();
    }, [searchValue, fromDate, toDate, filterByPeriod]);

    useEffect(() => {
        setPage(0);
    }, [searchValue, fromDate, toDate, orderBy, orderDesc, rowsPerPage, filterByPeriod]);

    useEffect(() => {
        handleFetchReports();
    }, [searchValue, fromDate, toDate, orderBy, orderDesc, rowsPerPage, page, filterByPeriod]);

    useEffect(() => {
        if (filterByPeriod !== 'none') {
            if (filterByPeriod === 'a' && (selectedPeriods[0] !== prevSelectedPeriods[0]) || filterByPeriod === 'b' && (selectedPeriods[1] !== prevSelectedPeriods[1])) {
                setIsLoading(true);
                setPage(0);
                handleFetchCount();
                handleFetchReports();
            }
        }
    }, [selectedPeriods]);

    if (isInitialising) {
        return <LoadingOverlay />;
    }

    return (
        <>
            <AppBar
                position="sticky"
                color="inherit"
                sx={{
                    top: 136,
                    flexShrink: 0, // fixes a bug causing height to shrink on scroll
                    zIndex: theme.zIndex.appBar - 1,
                    borderTop: 1,
                    borderColor: theme.palette.divider
                }}
            >
                <Tabs value={tab} onChange={(e, value) => setTab(value)}>
                    <Tab label="Find a Report" value="navigation" />
                </Tabs>
            </AppBar>
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Paper>
                            <Toolbar disableGutters sx={{ mx: 1 }}>
                                <OutlinedInput
                                    value={tempSearchValue}
                                    onChange={e => setTempSearchValue(e.target.value)}
                                    placeholder="Search..."
                                    size="small"
                                    sx={{ m: 1 }}
                                    endAdornment={(
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setTempSearchValue('')}
                                                edge="end"
                                                disabled={!Boolean(tempSearchValue)}
                                            >
                                                <Clear />
                                            </IconButton>
                                        </InputAdornment>
                                    )}
                                />
                                <DatePicker
                                    value={fromDate}
                                    onChange={handleChangeFromDate}
                                    label="From Date"
                                />
                                <DatePicker
                                    value={toDate}
                                    onChange={handleChangeToDate}
                                    label="To Date"
                                />
                                <FormControl margin="none">
                                    <InputLabel id="select-period-dates-label">Filter by Period</InputLabel>
                                    <Select
                                        labelId="select-period-dates-label"
                                        id="select-period-dates"
                                        value={filterByPeriod}
                                        label="Filter by Period"
                                        onChange={e => handleChangeFilterByPeriod(e.target.value)}
                                        size="small"
                                        sx={{ minWidth: 120 }}
                                    >
                                        <MenuItem value="none">None</MenuItem>
                                        {selectedPeriods.length > 0 && (
                                            <MenuItem value="a">
                                                <Typography variant="caption" sx={{ color: theme.palette.text.secondary, mr: 1, display: 'inline' }}>
                                                    Period A:
                                                </Typography>
                                                <RenderPeriodDateRange value1={selectedPeriods[0][0]} value2={selectedPeriods[0][1]} />
                                            </MenuItem>
                                        )}
                                        {(selectedPeriods.length > 1 && compare) && (
                                            <MenuItem value="b">
                                                <Typography variant="caption" sx={{ color: theme.palette.text.secondary, mr: 1, display: 'inline' }}>
                                                    Period B:
                                                </Typography>
                                                <RenderPeriodDateRange value1={selectedPeriods[1][0]} value2={selectedPeriods[1][1]} />
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Toolbar>
                            <Divider />
                            {isLoading ? (
                                <Box py={4}>
                                    <CenteredProgress colour="primary" />
                                </Box>
                            ) : (
                                <>
                                    <TableContainer>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map(col => (
                                                        <TableCell
                                                            key={col.name}
                                                            component="th"
                                                            scope="row"
                                                            align={col.align}
                                                            width={col.width || 'auto'}
                                                        >
                                                            <TableSortLabel
                                                                active={orderBy === col.name}
                                                                direction={orderDesc ? 'desc' : 'asc'}
                                                                onClick={() => handleSort(col.name, col.orderDesc)}
                                                            >
                                                                {col.label}
                                                            </TableSortLabel>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {reports.map(report => (
                                                    <TableRow
                                                        key={report.id}
                                                        hover={true}
                                                        onClick={() => history.push(`/newreports/pages/${report.id}`)}
                                                    >
                                                        {columns.map(col => (
                                                            <DataColumn
                                                                key={col.name}
                                                                align={col.align}
                                                                dataType={col.dataType}
                                                                value={report[col.name]}
                                                            />
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={rowsPerPageOptions}
                                        component="div"
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={(e, newPage) => setPage(newPage)}
                                        onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
                                    />
                                </>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Pages;