import { createTheme } from '@mui/material/styles';
import { colours } from './constants';

const theme = createTheme({
    palette: {
        background: {
            default: '#f5f5f5'
        },
        primary: {
            main: colours.primary,
            contrastText: '#FFF'
        },
        secondary: {
            main: colours.secondary,
            contrastText: '#FFF'
        }
    },
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '&:focus': {
                        outline: 'none'
                    }
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                margin: 'normal'
            }
        },
        MuiFormControl: {
            defaultProps: {
                margin: 'normal'
            }
        },
        MuiDialog: {
            defaultProps: {
                fullWidth: true
            }
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    displayPrint: 'none'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    minWidth: 'auto'
                },
                containedPrimary: {
                    color: '#fff',
                    '&:hover': {
                        color: '#fff'
                    }
                },
                containedSecondary: {
                    color: '#fff',
                    '&:hover': {
                        color: '#fff'
                    }
                }
            },
            defaultProps: {
                variant: 'contained'
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    paddingLeft: 24,
                    paddingRight: 24,
                    minWidth: 'auto',
                    '@media (min-width: 600px)': {
                        minWidth: 'unset'
                    }
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&.MuiTableRow-hover': {
                        cursor: 'pointer'
                    }
                }
            }
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    '@media print': {
                        display: 'none'
                    }
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    cursor: 'pointer'
                }
            }
        },
        // This is a workaround for https://github.com/mui/mui-x/issues/9311
        MuiMultiSectionDigitalClock: {
            styleOverrides: {
                root: {
                    '&&': {
                        '.MuiList-root': {
                            width: '65px'
                        }
                    }
                }
            }
        }
    },
    zIndex: {
        // drawer needed to be less than 900 for Stripo conflict
        mobileStepper: 600, //1000 default
        speedDial: 650, //1050
        appBar: 700, //1100
        drawer: 800 //1200
        //modal: 1300,
        //snackbar: 1400,
        //tooltip: 1500
    },
    breakpoints: {
        // added xxl for big screens. Specifically this was added for DatesSelector
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            xxl: 1800
        }
    }
});

export default theme;