import React from 'react';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// Components
import ReactECharts from 'echarts-for-react';
import { userChartColour } from '../../../theme/constants';

const UserChartDonut = ({ users, userLimit, header = false, height }) => {
    let azureADCount = 0;
    let usernameAndPasswordCount = 0;

    users.forEach(user => {
        if (user.provider === 'AzureAD') {
            azureADCount++;
        }
        else if (user.provider === null) {
            usernameAndPasswordCount++;
        }
    });

    const options = {
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        series: [
            {
                name: 'User Allocation',
                type: 'pie',
                color: [userChartColour.allocated, userChartColour.unallocated],
                selectedMode: 'single',
                radius: ['0%', '30%'],
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 15,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                label: {
                    position: 'inner',
                    fontSize: 12
                },
                data: [
                    { value: users.length, name: 'Allocated' },
                    { value: userLimit - users.length, name: 'Unallocated' }
                ]
            },
            {
                name: 'Login Provider',
                type: 'pie',
                color: [userChartColour.azureAD, userChartColour.userName, userChartColour.unallocated],
                radius: ['45%', '70%'],
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 15,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                label: {
                    position: 'inner',
                    fontSize: 12
                },
                data: [
                    { value: azureADCount, name: 'AzureAD' },
                    { value: usernameAndPasswordCount, name: 'Username & Password' },
                    { value: userLimit - users.length, name: 'Unallocated' }
                ]
            }
        ]
    };

    return (
        <>
            {header && (
                <>
                    <Toolbar variant="dense" disableGutters sx={{ px: 2 }}>
                        <div style={{ flexGrow: 1 }}>
                            <Typography variant="subtitle2">User Allocation and Login Provider</Typography>
                        </div>
                    </Toolbar>
                    <Divider />
                </>
            )}
            <Box>
                <ReactECharts
                    option={options}
                    style={{ height: height ? height : 350 }}
                    opts={{ renderer: 'svg' }}
                />
            </Box>
        </>
    );
};

export default UserChartDonut;
