import React from 'react';
import { createContext, useState, useEffect } from 'react';
import useApi from '../hooks/useApi';
import { useAuth0 } from '@auth0/auth0-react';
import SplashScreen from '../splashScreen/SplashScreen';
import SelectAccountDialog from '../dialogs/SelectAccountDialog';
import AgreeToTermsDialog from '../dialogs/AgreeToTermsDialog';
import InvalidLicenceDialog from '../dialogs/InvalidLicenceDialog';
import { generatePeriods } from '../account/reportingPeriod/ReportingPeriods';

const AccountContext = createContext();

// todo combine user and account into one call eliminating need for the loading state

const InnerPage = ({ isAccountLoading, showSplash, user, children }) => {
    if (showSplash) {
        return (
            <SplashScreen />
        );
    }

    if (user.refreshRequired) {
        // todo resolves invalid token immediately after account linking, could be better
        window.location.assign('/');

        return (
            <SplashScreen />
        );
    }

    if (!user.account) {
        return (
            <SelectAccountDialog />
        );
    }

    if (!user.hasAgreed) {
        return (
            <AgreeToTermsDialog />
        );
    }

    if (!user.account.licence) {
        return (
            <InvalidLicenceDialog />
        );
    }

    // todo splash screen should be hidden when user has finished loading
    // handling splash screen here is a hack to avoid turning it off earlier

    if (isAccountLoading) {
        return (
            <SplashScreen />
        );
    }

    return children;
};

const AccountProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [account, setAccount] = useState(null);
    const { handleGet, handlePut, handlePost, handleDelete } = useApi();
    const [isAccountLoading, setIsAccountLoading] = useState(true);
    const [showSplash, setShowSplash] = useState(false);
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    const [topics, setTopics] = useState([]);
    const [tags, setTags] = useState([]);
    const [customFields, setCustomFields] = useState([]);

    const handleChangeAccount = async customerId => {
        setShowSplash(true);

        const response = await handlePut('user', { customerId });

        if (response.ok) {
            // todo refresh token silently instead
            window.location.assign('/');
        }
    };

    const handleLoadUser = async () => {
        const response = await handleGet('user');

        if (!response.ok) {
            setIsAccountLoading(false);
            return;
        }

        const data = await response.json();

        setUser(data);
    };

    //topics
    const handleFetchTopics = async () => {
        const response = await handleGet('topics');

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        setTopics(await response.json());
    };

    const handleCreateTopic = async ({ name, description, colour }) => {
        await handlePost('topics', {
            name,
            description,
            colour
        });

        await handleFetchTopics();
    };

    const handleUpdateTopic = async (id, topic) => {

        const response = await handlePut(`topics/${id}`, topic);

        if (!response.ok) {
            console.error(response?.error);
        }

        await handleFetchTopics();
    };

    const handleDeleteTopic = async id => {
        const response = await handleDelete(`topics/${id}`);

        if (!response.ok) {
            console.error(response?.error);
        }

        await handleFetchTopics();
    };

    //tags
    const handleLoadTags = async () => {
        const response = await handleGet('tags');

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        setTags(await response.json());
    };

    const handleCreateTag = async ({ name, description, colour }) => {
        await handlePost('tags', {
            name,
            description,
            colour
        });

        await handleLoadTags();
    };

    const handleUpdateTag = async (id, tag) => {
        const response = await handlePut(`tags/${id}`, tag);

        if (!response.ok) {
            console.error(response?.error);
        }

        await handleLoadTags();
    };

    const handleDeleteTag = async id => {
        const response = await handleDelete(`tags/${id}`);

        if (!response.ok) {
            console.error(response?.error);
        }

        await handleLoadTags();
    };

    // handle custom fields
    const handleLoadCustomFields = async () => {
        const response = await handleGet('custom-fields');

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        setCustomFields(await response.json());
    };

    const handleAddField = async ({ name, type }) => {
        const response = await handlePost('custom-fields', { name, type });

        if (!response.ok) {
            return response;
        }

        await handleLoadCustomFields();
        return response;
    };

    const handleUpdateField = async (id, field) => {
        const response = await handlePut(`custom-fields/${id}`, field);

        if (!response.ok) {
            console.error(response?.error);
        }

        await handleLoadCustomFields();

        return response;
    };

    const handleDeleteField = async (id) => {
        const response = await handleDelete(`custom-fields/${id}`);

        if (!response.ok) {
            console.error(response?.error);
        }

        await handleLoadCustomFields();

        return response;
    };

    const handleLoadAccount = async () => {
        // todo detect if logged in account has been revoked and refresh token

        const response = await handleGet('account');

        if (!response.ok) {
            return;
        }

        const data = await response.json();

        // todo do the following in editor?

        const customFields = data.customFields && data.customFields.filter(({ display }) => display).map(({ name }) => ({ 'label': name, 'value': `[${name}]` }));
        const customFonts = data.customFonts && data.customFonts.map(f => ({ name: f.name, fontFamily: f.fontFamily, url: f.url, label: f.label }));
        const customColours = data.customColours && data.customColours.map(c => ({ id: c.id, customerId: c.customerId, value: c.value, label: c.label }));

        const reportingPeriod = data.reportingPeriodType !== null ? {
            type: data.reportingPeriodType,
            startDate: data.reportingPeriodStartDate
        } : null;

        const reportingPeriods = generatePeriods(reportingPeriod, data.startDate);

        setAccount({
            customerId: data.customerId,
            company: data.company,
            customFields,
            customFonts,
            customColours,
            hasCustomSmtpConfiguration: Boolean(data.smtpHost),
            smtpUsername: data.smtpUsername,
            smtpModified: data.smtpModified,
            isInternalComms: data.licence?.isInternalComms ?? false,
            userLimit: data.userLimit,
            hasLicence: Boolean(data.licence),
            handleChangeAccount,
            userPermissions: user.permissions,
            safeSenders: data.safeSenders,
            allowedRecipients: data.allowedRecipients,
            user: user,
            handleCreateTopic,
            handleUpdateTopic,
            handleDeleteTopic,
            handleCreateTag,
            handleUpdateTag,
            handleDeleteTag,
            handleAddField,
            handleUpdateField,
            handleDeleteField,
            sendingDomain: data.sendingDomain,
            customDKIM: data.customDKIM,
            doubleOptIn: data.doubleOptIn ?? false,
            startDate: data.startDate,
            reportingPeriod,
            reportingPeriods
        });

        setIsAccountLoading(false);
    };

    const handleSignOut = () => loginWithRedirect({ prompt: 'login' });

    const handleUpdateCustomColour = async (payload) => {
        const response = await handlePut('account', payload);

        await handleLoadAccount();

        return response;
    };

    const handleUpdateReportingPeriod = async reportingPeriod => {
        const response = await handlePut('account', reportingPeriod);

        await handleLoadAccount();

        return response;
    };

    const handleDoubleOptInSubmit = async (data) => {
        const response = await handlePut('account', { doubleOptIn: data });

        await handleLoadAccount();

        return response;
    };

    useEffect(() => {
        if (isLoading) {
            return;
        }

        handleLoadUser();
    }, [isLoading]);

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (!isAuthenticated) {
            console.error('User not authenticated! Send them back to login screen.');
            handleSignOut();
        }
    }, [isLoading, isAuthenticated]);

    useEffect(() => {
        if (user?.account) {
            handleLoadAccount();
        }
    }, [user]);

    useEffect(() => {
        if (account) {
            handleFetchTopics();
            handleLoadTags();
            handleLoadCustomFields();
        }
    }, [account]);

    if (isLoading || !user) {
        return (
            <SplashScreen />
        );
    }

    if (user.accounts?.length === 1) {
        // attempt auto sign-in
    }

    return (
        <AccountContext.Provider value={{
            ...user,
            ...account, // todo return account details under user
            topics,
            tags,
            customFields,
            onRefresh: handleLoadAccount,
            handleRefresh: handleLoadUser,
            handleChangeAccount,
            handleSignOut,
            handleUpdateCustomColour,
            handleDoubleOptInSubmit,
            handleUpdateReportingPeriod
        }}>
            <InnerPage
                user={user}
                isAccountLoading={isAccountLoading}
                showSplash={showSplash}
            >
                {children}
            </InnerPage>
        </AccountContext.Provider>
    );
};

export { AccountProvider, AccountContext };