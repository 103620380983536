import React, { useState, useEffect } from 'react';
import useGoogleAnalytics from './hooks/useGoogleAnalytics';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import theme from './theme/theme';
import Header from './header/Header';
import Workarea from './workarea/Workarea';
import CssBaseline from '@mui/material/CssBaseline';
import NotificationsPane from './notifications/NotificationsPane';
import { useLocation } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey('febb1cae698c78eb24770a8389cf04eeTz0xMDMzMzAsRT0xNzY0NzU2NzU0MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=');

const App = () => {
    const [showNotifications, setShowNotifications] = useState(false);

    const location = useLocation();
    useGoogleAnalytics();

    useEffect(() => {
        setShowNotifications(false);
    }, [location]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Header onToggleNotifications={() => setShowNotifications(!showNotifications)} />
                <Workarea />
                <NotificationsPane
                    isOpen={showNotifications}
                    onClose={() => setShowNotifications(false)}
                />
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;